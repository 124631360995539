import React from 'react';
import {Alert, Modal} from "antd";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

interface BarcodeScannerProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleBarcode: (str: string) => void
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({isModalOpen, setIsModalOpen, handleBarcode}) => {

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handelOnUpdate = (err:any, result:any) => {
        if(err){
            return <Alert message={err}/>
        }
        if(result){
            handleBarcode(result.text)
            handleCancel()
        }
        else{
            handleBarcode("Not Found")
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onCancel={handleCancel}
        >
            <BarcodeScannerComponent onUpdate={handelOnUpdate}/>
        </Modal>
    );
};

export default BarcodeScanner;