import React, {useState} from 'react';
import {Avatar, Button, Card} from "antd";
import {EditOutlined, UserOutlined} from "@ant-design/icons";
import {formatDate} from "../../../../utils/globalFunctions";
import {Comment} from "../../../../types/types";
import classes from "../../style.module.css"
import {useAuth} from "../../../../context/AuthProvider";
import CommentModal from "../../../../components/modals/CommentModal/CommentModal";

interface ICommentItemProps {
    comment: Comment
    taskId: string | undefined
}

const CommentItem: React.FC<ICommentItemProps> = ({comment, taskId}) => {

    const {state} = useAuth()
    const userData = state.userData
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

    return (
        <Card className={classes.commentCard}>
            <div className={classes.commentInfo}>
                <span className={classes.avatar}>
                    <div style={{ display: "flex", alignItems:"center", gap:"5px" }}>
                          <Avatar className={classes.avatarIcon} icon={<UserOutlined/>}
                                  size={"small"}/> {comment.whoCommented}
                    </div>
                    <div className={classes.commentTime}>{formatDate(comment.createdDate)}</div></span>
            </div>
            <div className={classes.commentBody}>
                <div style={{whiteSpace:"pre-wrap"}} className={classes.commentText}>{comment.text}</div>
                <div className={classes.editButton}>{userData?.person.name === comment.whoCommented &&
                    <Button shape="circle" onClick={() => setIsCommentModalOpen(true)}
                            icon={<EditOutlined/>}></Button>}</div>
            </div>
            <CommentModal title={"Edit comment"} type={"edit"} commentId={comment.id} text={comment.text}
                          taskId={taskId}
                          isModalOpen={isCommentModalOpen} setIsModalOpen={setIsCommentModalOpen}/>
        </Card>
    );
};

export default CommentItem;