import React from 'react';
import {Button, Dropdown} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import classes from "../styles.module.css"
import InputSelector from "./InputSelector";
import {FormInputTypesWithId, InputTypesEnum} from "../../../types/inputs";
import {SectionEnum} from "../../../types/types";
import {v4 as uuidv4} from 'uuid';
import {useSectionInputContext} from "../contex/sectionInputContext";
import {createInput} from "../contex/sectionUtils";
import {SectionInputAction} from "../contex/SectionInputReducer";
import {ReactSortable} from "react-sortablejs";
import {useTranslation} from "react-i18next";


interface SectionItemProps {
    section: SectionEnum
}


const SectionItem: React.FC<SectionItemProps> = ({section}) => {
    const {state, dispatch} = useSectionInputContext()
    const inputs = state[section].data

    const {t} = useTranslation()



    const handleAddInput = (inputType: InputTypesEnum) => {
        const newInputData = createInput(inputType)
        const newInput = {type: inputType, data: newInputData, id: uuidv4()} as FormInputTypesWithId
        const newData = [...inputs, newInput]
        dispatch({type: SectionInputAction.SET_SECTION, payload: {section, data: newData}})

    }

    const names: InputTypesEnum[] = [InputTypesEnum.Input, InputTypesEnum.Textarea, InputTypesEnum.Select, InputTypesEnum.Date]

    const dropDownItems = names.map((name) => {
        return {
            key: name,
            label: <div onClick={handleAddInput.bind(null, name)} className={classes.inputLink}>{name}</div>,
            value: name,

        }
    })

    const handleOnChange = (id: string, data: any) => {

        const newInputIndex = inputs.findIndex((input) => input.data.id === id)
        const newInput = inputs[newInputIndex]
        const newInputs = [...inputs]
        newInputs[newInputIndex] = {...newInput, data: {...newInput.data, ...data}}

        dispatch({type: SectionInputAction.SET_SECTION, payload: {section, data: newInputs}})

    }
    const handleRemove = (id: string) => {
        const newInputs = inputs.filter((input) => input.data.id !== id)
        dispatch({type: SectionInputAction.SET_SECTION, payload: {section, data: newInputs}})

    }

    const updateInputs = (newInputs: FormInputTypesWithId[]) => {
        const updatedInputs = newInputs.map((input) => ({ ...input, id: input.id }));
        dispatch({ type: SectionInputAction.SET_SECTION, payload: { section, data: updatedInputs } });
    };



    return (
        <div>
            <div className={classes.createTemplateRow}>
                <h2>{t(`section.${section}`)}</h2>
                <Dropdown menu={{items: dropDownItems}} placement="bottom">
                    <Button icon={<PlusOutlined/>}>{t("template_page.add_new_field")}</Button>
                </Dropdown>
            </div>
            <div className={classes.templateColumn}>
                <ReactSortable list={inputs.map((input) => ({ ...input, id: input.id }))} setList={updateInputs}>
                    {inputs?.map((input) => (
                        <div key={input.id}>
                            <InputSelector
                                inputData={input}
                                handleRemove={handleRemove}
                                onChange={handleOnChange}
                            />
                            <br />
                        </div>
                    ))}
                </ReactSortable>
            </div>
        </div>
    );
};

export default SectionItem;

