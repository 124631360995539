import React from 'react';
import {Task} from "../../../../types/types";
import TaskItem from "../TaskItem/TaskItem";
import {Empty} from "antd";

interface Props {
    tasks: Task[]
}

const TaskList: React.FC<Props> = ({tasks}) => {

    const defaultActiveKey = tasks[0]?.parentTaskId === null ? tasks[0]?.id : undefined;

    if(tasks.length === 0) return <Empty />;

    return (<>{tasks?.map((task: Task) => <TaskItem task={task} key={task.id} defaultActiveKey={defaultActiveKey}/>)}</>);
};

export default TaskList;


