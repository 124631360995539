import React from 'react';
import { Collapse } from "antd";
import classes from "../styles.module.css"

interface CollapseProps {
    title: string | React.ReactNode;
    icon?: React.ReactElement | null;
    children: React.ReactNode;
    panelKey?: string;
    className?: any
    panelClassName?: any
    extra?: React.ReactNode
}

const CollapseWrapper: React.FC<CollapseProps> = ({ title, icon, children, panelKey = "1" , className, extra, panelClassName}) => {
    return (
        <Collapse bordered={false} className={className ? className : classes.sectionCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel
                extra={extra}
                className={panelClassName}
                header={
                    <div className="flex items-center gap-2">
                        {icon && <span>{icon}</span>}
                        <h2 style={{}}>{title}</h2>
                    </div>
                }
                key={panelKey}>
                {children}
            </Collapse.Panel>
        </Collapse>
    );
};

export default CollapseWrapper;
