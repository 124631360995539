import {FormInputTypesWithId} from "../../../types/inputs";
import {SectionEnum} from "../../../types/types";


export type SectionInputStateType = {
    [key in SectionEnum]: {
        data: FormInputTypesWithId[]
    };
};

export enum SectionInputAction {
    SET_INIT = "SET_INIT",
    SET_SECTION = "SET_SECTION"
}


 type SET_SECTION  = {
    type: SectionInputAction.SET_SECTION,
    payload:{
        section: SectionEnum,
        data: FormInputTypesWithId[]
    }
}
type SET_INIT  = {
    type: SectionInputAction.SET_INIT,
    payload:{
        data:SectionInputStateType
    }
}


export type  SectionInputActionType = SET_SECTION | SET_INIT

export function sectionInputReducer(state: SectionInputStateType, action: SectionInputActionType): SectionInputStateType {
    switch (action.type) {
        case SectionInputAction.SET_SECTION:
            return {
                ...state,
                [action.payload.section]: {
                    data: action.payload.data
                }
            }
            case SectionInputAction.SET_INIT:
                return {
                    ...state,
                    ...action.payload.data
                }
        default:
            return state
    }
}