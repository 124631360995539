import React from 'react';
import ProductInputItem from "../../pages/work-orders/components/productItem/ProductInputItem";
import classes from "./style.module.css"
import {FormProduct} from "../../types/inputs";
import {useParams} from "react-router-dom";

interface ProductInputListProps {
    productInputs: FormProduct[] | undefined;
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    handleRemoveProductInput: (product: FormProduct) => void
}

const ProductInputList: React.FC<ProductInputListProps> = ({
                                                               handleRemoveProductInput,
                                                               productInputs,
                                                               setProductInputs
                                                           }) => {

    const params = useParams()


    return (
        <div className={classes.column}>
            {productInputs?.filter((pInput) => !pInput.parentItemNumber).reverse().map((pInput) => {

                const productAddons = productInputs?.filter((p) => p.parentItemNumber === pInput.itemNumber)

                return <ProductInputItem workOrderId={params.id ? params.id : ""} setProductInputs={setProductInputs}
                                         initProductAddons={productAddons}
                                         key={pInput.itemNumber} productInput={pInput}
                                         handleRemoveProductInput={handleRemoveProductInput}/>
            })}
        </div>
    );
};

export default ProductInputList;