import React, {useEffect, useState} from 'react';
import {WorkOrder} from "../../../../types/types";
import {Button, Dropdown, Input, MenuProps, Select} from "antd";
import classes from "./style.module.css";
import {
    ClockCircleOutlined,
    EditOutlined,
    FilterOutlined,
    SearchOutlined,
    SortAscendingOutlined, SortDescendingOutlined
} from "@ant-design/icons";
import {useWorkOrderFilters} from "./useWorkOrderFilters";
import {useQuery} from "@tanstack/react-query";
import {getStatusList} from "../../../../services/statuses";
import {useTranslation} from "react-i18next";


interface WorkOrderFiltersProps {
    workOrders: WorkOrder[] | undefined
    setFilteredWorkOrders: (wOrders: WorkOrder[] | undefined) => void
}

function findAllUsersAndGroups(workOrders: WorkOrder[] | undefined): { users: Set<string>, groups: Set<string> } {

    const users = new Set<string>();
    const groups = new Set<string>();

    workOrders?.forEach(workorder => {
        workorder.users.forEach(user => users.add(user.userName));
        workorder.groups.forEach(group => groups.add(group.groupName));
    });
    return {users, groups};
}

const WorkOrderFilters: React.FC<WorkOrderFiltersProps> = ({workOrders, setFilteredWorkOrders}) => {

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<string | undefined>(undefined);
    const [group, setGroup] = useState<string | undefined>(undefined);
    const [sortValue, setSortValue] = useState<number>(0);
    const {t} = useTranslation()

    const {filteredWorkOrders} = useWorkOrderFilters(workOrders, searchTerm, status, user, group, sortValue)

    const {users, groups} = findAllUsersAndGroups(workOrders);

    const userOptions = Array.from(users).map(user => {
        return {value: user, label: user}
    })

    const groupOptions = Array.from(groups).map(group => {
        return {value: group, label: group}
    })

    const handleChangeUser = (value: string) => {
        setUser(value)
    };

    const handleChangeGroup = (value: string) => {
        setGroup(value)
    };

    const handleClearFilters = () => {
        setSearchTerm("")
        setStatus(undefined)
        setUser(undefined)
        setGroup(undefined)
        setSortValue(0)
    }

    const handleChangeStatus = (value: string) => {
        setStatus(value)
    };

    const handleChange = (value: number) => {
        setSortValue(value)
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <span className={classes.item}>{t("filters.asc")} <SortAscendingOutlined/></span>,
            onClick: () => handleChange(1),
        },
        {
            key: '2',
            label: <span className={classes.item}>{t("filters.dsc")} <SortDescendingOutlined/></span>,
            onClick: () => handleChange(2),
        },
        {
            key: '3',
            label: <>{t("filters.cancel")}</>,
            onClick: () => handleChange(0),
        },
    ];



    useEffect(() => {
        setFilteredWorkOrders(filteredWorkOrders);
    }, [searchTerm, status, user, group, sortValue]);

    const {data: statuses} = useQuery({
        queryFn: () => getStatusList("1476489217"), //TODO FIX LATER
        queryKey: ["statusList"],
    })


    const options = statuses?.status
        ? statuses.status.map((status: any) => ({
            value: status.id,
            label: status.name,
        }))
        : [];


    return (
        <div className={classes.row}>
            <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className={classes.search}
                   suffix={<SearchOutlined/>} placeholder={t("filters.search_work_orders")}/>
            <Select
                onChange={handleChangeStatus}
                placeholder={t("filters.status")}
                style={{width: 130}}
                value={status}
                allowClear
                options={options}
            />
            <Select
                onChange={handleChangeUser}
                placeholder={t("filters.user")}
                style={{width: 130}}
                value={user}
                allowClear
                options={userOptions}
            />
            <Select
                onChange={handleChangeGroup}
                placeholder={t("filters.group")}
                style={{width: 130}}
                value={group}
                allowClear
                options={groupOptions}
            />
            <div className={classes.buttonsContainer}>
                <div className={classes.buttons}>
                    <Dropdown menu={{items}} placement="bottom" arrow>
                        <Button><EditOutlined/>{t("filters.created_time")}</Button>
                    </Dropdown>
                    <Dropdown menu={{items}} placement="bottom" arrow>
                        <Button><ClockCircleOutlined/>{t("filters.updated_time")}</Button>
                    </Dropdown>
                </div>
            </div>
            <Button className={classes.clearButton} icon={<FilterOutlined/>} onClick={handleClearFilters}>{t("filters.clear_filters")}</Button>
        </div>
    );
};

export default WorkOrderFilters;