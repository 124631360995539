import React from 'react';
import classes from "./style.module.css";
import {useTranslation} from "react-i18next";
import {Select} from "antd";

const LanguageSwitcher = () => {

    const {i18n} = useTranslation();


    const handleChangeLang = async (value: string) => {
        await i18n.changeLanguage(value)
    }


    return (
        <Select
            value={i18n.language}
            optionFilterProp="label"
            onChange={handleChangeLang}
            options={[
                {
                    value: 'en',
                    label: <div className={classes.languageOption}><img alt={"en"} width={24}
                                                                        src={"https://flagsapi.com/GB/flat/64.png"}/>ENG
                    </div>,
                },
                {
                    value: 'hr',
                    label: <div className={classes.languageOption}><img alt={"hr"} width={24}
                                                                        src={"https://flagsapi.com/HR/flat/64.png"}/>HRV
                    </div>,
                },
                {
                    value: 'rs',
                    label: <div className={classes.languageOption}><img alt={"hr"} width={24}
                                                                        src={"https://flagsapi.com/RS/flat/64.png"}/>SRB
                    </div>,
                },
                {
                    value: 'cp',
                    label: <div className={classes.languageOption}><img alt={"hr"} width={24}
                                                                        src={"https://flagsapi.com/RS/flat/64.png"}/>СРБ
                    </div>,
                },
            ]}
        />
    );
};

export default LanguageSwitcher;