import React, { useState } from 'react';
import { Alert, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addComment, updateComment } from "../../../services/comments";
import classes from "../../../pages/tasks/TaskDetailsPage/components/modals/EditTaskModal/style.module.css";

interface CommentModalProps {
    taskId: string | undefined;
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    commentId?: string;
    text?: string;
    type?: string;
    title: string;
}

interface CommentBody {
    taskId: string | undefined;
    text: string;
}

const CommentModal: React.FC<CommentModalProps> = ({ isModalOpen, setIsModalOpen, taskId, title, type, text, commentId }) => {
    const [comment, setComment] = useState(text || "");
    const [error, setError] = useState("");

    const queryClient = useQueryClient();

    const addCommentMutation = useMutation<any, Error, CommentBody>({
        mutationFn: ({ taskId, text }) => addComment(taskId, text),
        onSettled: () => {
            //@ts-ignore

            queryClient.invalidateQueries(['taskDetails', taskId]).catch(console.error);
            //@ts-ignore

            queryClient.invalidateQueries(['taskComments', taskId]).catch(console.error);
            setError("");
            setIsModalOpen(false);
        },
        onError: (error) => {
            setError(error.message);
        }
    });

    const updateCommentMutation = useMutation<any, Error, { text: string }>({
        mutationFn: ({ text }) => updateComment(commentId!, text),
        onSettled: () => {
            //@ts-ignore
            queryClient.invalidateQueries(['taskDetails', taskId]).catch(console.error);
            //@ts-ignore

            queryClient.invalidateQueries(['taskComments', taskId]).catch(console.error);
            setError("");
            setIsModalOpen(false);
        },
        onError: (error) => {
            setError(error.message);
        }
    });

    const handleOk = () => {
        if (type === "edit") {
            if (!commentId) {
                setError("Comment ID is missing for update operation.");
                return;
            }
            updateCommentMutation.mutate({ text: comment });
        } else {
            if (!taskId) {
                setError("Task ID is missing for add operation.");
                return;
            }
            addCommentMutation.mutate({ taskId, text: comment });
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <TextArea
                placeholder="Add comment here..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                rows={3}
                autoSize={{ minRows: 3, maxRows: 5 }}
            />
            {error && <Alert message={error} type="error" className={classes.alert} />}
        </Modal>
    );
};

export default CommentModal;
