import React from 'react';
import {Skeleton} from "antd";
import {FormOutlined} from "@ant-design/icons";
import classes from "../styles.module.css"

const WorkOrderSkeleton = () => {
    return (
        <div className={classes.container}>
            <Skeleton.Input active={true} block={true} style={{margin: "20px 0"}}/>
            <div className={classes.cardContainer}>
                {Array.from({length: 8}, (_, index) => (
                    <Skeleton.Node key={index} className={classes.skeletonCard} style={{width: 400, height: 400}}
                                   active={true}>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <FormOutlined style={{fontSize: 40, color: '#bfbfbf'}}/>
                    </Skeleton.Node>
                ))}
            </div>
        </div>
    );
};

export default WorkOrderSkeleton;