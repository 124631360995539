import React, {useState} from 'react';
import {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {Button, Card, Collapse, Tag} from "antd";
import classes from "../styles.module.css";
import {formatDate} from "../../../utils/globalFunctions";
import {useNavigate, useParams} from "react-router-dom";
import DetailsSection from "./DetailsSection";
import {WorkOrderDetails} from "../../../types/types";
import FileUpload from "./FileUpload";
import Notes from "./Notes";
import FilesList from "./FilesList";
import StatusList from "./StatusList";
import AssignModal from "../../tasks/TaskDetailsPage/components/modals/AssignModal/AssignModal";
import AssignedGroupsUsers from "./AssignedGroupsUsers";
import ClaimRolesContainer from "./ClaimRolesContainer";


interface IDetailsCardProps {
    item: WorkOrderDetails
}


const DetailsCard: React.FC<IDetailsCardProps> = ({item}) => {

    const navigate = useNavigate();
    const params = useParams();
    const [openModal, setOpenModal] = useState(false)


    return (
        <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel header={<h2>Work order details <InfoCircleOutlined/></h2>} key="1">
                <Card
                    className={classes.titleCard}
                    title={
                        <div className={classes.statusRow}>
                            <h2>
                                {item.title}
                                {" "}<Tag color={item.statusColor}>{item.statusName}</Tag>
                            </h2>
                        </div>
                    }
                    extra={
                        <>
                            <Button
                                className={classes.editButton}
                                type="primary"
                                onClick={() => navigate(`/work_orders/${params.id}/edit`)}
                                icon={<EditOutlined/>}
                            >
                                Edit
                            </Button>
                        </>
                    }
                >
                    <div className={classes.assignContainer}>
                            <AssignedGroupsUsers item={item}/>
                            <Button onClick={setOpenModal.bind(null, true)}>Assign workorder</Button>
                    </div>
                    <ClaimRolesContainer item={item}/>
                    <Notes item={item}/>
                    <FilesList files={item.filesAttached}/>
                    <FileUpload itemId={item.id} fileFolderName={"workorder"}/>
                    <StatusList statusListId={item.status_list_id} currentStatus={item.statusId} workOrderId={item.id}/>
                    <br/>
                    <div className={classes.workOrderContainer}>
                        <div className={classes.formContainer}>
                            {Object.entries(item?.order_template?.schemaContent || {}).map(([key, value]) => (
                                <DetailsSection key={key} data={value}
                                                section={key.charAt(0).toUpperCase() + key.slice(1)}/>
                            ))}
                        </div>
                    </div>
                    <div className={classes.date}>Created at: {formatDate(item?.createdDate)}</div>
                    <div className={classes.date}>Last updated at: {formatDate(item?.updatedDate)}</div>
                </Card>
            </Collapse.Panel>
            <AssignModal isModalOpen={openModal} setIsModalOpen={setOpenModal} type={"work-order"} workOrderId={item.id}
                         title={"Assign users or groups to workorder"} users={item.users} groups={item.groups}/>

        </Collapse>
    );
};

export default DetailsCard;