import React from 'react';
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {FormProduct} from "../../../types/inputs";
import CustomStep from "./CustomStep";
import {isNull} from "lodash";

interface Props {
    currentStep: number;
    dropdownItems: any
    workOrderDetails: any
    productInputs: FormProduct[]
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void

}




const StepChooser: React.FC<Props> = ({currentStep, dropdownItems, workOrderDetails, productInputs, setProductInputs}) => {

    const hasExtraStep = workOrderDetails?.customData != null; // Fix condition

    if (currentStep === 0) {
        return <Step0 workOrderDetails={workOrderDetails} />;
    }

    if (currentStep === 1) {
        return (
            <Step1
                productInputs={productInputs}
                setProductInputs={setProductInputs}
                dropdownItems={dropdownItems}
                workOrderDetails={workOrderDetails}
            />
        );
    }

    if (currentStep === 2) {
        return <Step2 workOrderDetails={workOrderDetails} />;
    }

    if (hasExtraStep && currentStep === 3) {
        return <CustomStep data={workOrderDetails.customData} />;
    }

    const step3Index = hasExtraStep ? 4 : 3;

    if (currentStep === step3Index) {
        return (
            <Step3
                productInputs={productInputs}
                setProductInputs={setProductInputs}
                workOrderDetails={workOrderDetails}
                dropdownItems={dropdownItems}
            />
        );
    }

    return null;
}

    export default StepChooser;


