import React from 'react';
import classes from "../styles.module.css";
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";

interface StepProps {
    workOrderDetails: any
}

const Step0:React.FC<StepProps> = ({workOrderDetails}) => {
    return (
        <div className={classes.column}>
            {workOrderDetails.order_template.schemaContent["heading"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `heading-${index}`}/>
            ))}
        </div>
    );
};

export default Step0;