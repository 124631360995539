import React, {useState} from 'react';
import classes from "../styles.module.css";
import {Alert, Tag} from "antd";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {unAssignWorkOrder} from "../../../services/workOrders";
import {WorkOrderDetails} from "../../../types/types";

interface AssignedGroupsUsersProps {
    item: WorkOrderDetails
}

const AssignedGroupsUsers: React.FC<AssignedGroupsUsersProps> = ({item}) => {

    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState<any>({
        name: "",
        id: "",
        type: ""
    });

    const [error, setError] = useState<string>("");
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({workOrderId, userIds, groupIds}: {
            workOrderId: string;
            userIds: string[];
            groupIds: string[]
        }) => unAssignWorkOrder(workOrderId, userIds, groupIds),
        onSuccess: async () => {
            setIsWarningModalOpen(false);
            setError("");
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', item.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', item.id]}).catch(console.error);
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });


    const handleUnassignUserGroup = (id: string, type: string) => {
        // Optimistically update the UI
        const prevUsers = [...item.users];
        const prevGroups = [...item.groups];

        if (type === "user") {
            item.users = item.users.filter(user => user.userId !== id);
        } else if (type === "group") {
            item.groups = item.groups.filter(group => group.groupId !== id);
        }

        mutation.mutate(
            { workOrderId: item.id, userIds: type === "user" ? [id] : [], groupIds: type === "group" ? [id] : [] },
            {
                onError: () => {
                    // Revert back if mutation fails
                    item.users = prevUsers;
                    item.groups = prevGroups;
                }
            }
        );
    };

    return (
        <div className={classes.columnContainer}>
            {item.users.length > 0 ?
                <div>
                    <h2>Assigned users:</h2>
                    <div className={classes.items} >
                        {item.users.map(user => <Tag
                            closeIcon
                            onClose={(e) => {
                                e.preventDefault();
                                setSelectedItem({name: user.userName, id: user.userId, type: "user"});
                                setIsWarningModalOpen(true);
                            }}
                            key={user.userId}>{user.userName}</Tag>)}
                    </div>
                 </div> :
                <div>Currently no assigned users</div>}
            {item.groups.length > 0 ?
                <div>
                    <h2>Assigned groups:</h2>
                    <div className={classes.items}>
                        {item.groups.map(group => <Tag
                            closeIcon
                            onClose={(e) => {
                                e.preventDefault();
                                setSelectedItem({name: group.groupName, id: group.groupId, type: "group"});
                                setIsWarningModalOpen(true);
                            }}
                            key={group.groupId}>{group.groupName}</Tag>)}
                    </div>
                    </div>
                    :
                    <div>Currently no assigned groups</div>}
                    <WarningModal
                        deleteText={"Unassign"}
                        data={selectedItem.name}
                        title="Are you sure you want to unassign this item?"
                        handler={() => handleUnassignUserGroup(selectedItem.id, selectedItem.type)}
                        isModalOpen={isWarningModalOpen}
                        setIsModalOpen={setIsWarningModalOpen}
                    />
                    {error && <Alert type="error" className={classes.alert} message={error}/>}
                </div>
                );
            };

            export default AssignedGroupsUsers;