import React, {useEffect, useState} from 'react';
import classes from "./styles.module.css";
import {Alert, Button, Card, Divider, Skeleton, Steps} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    assignProductsInstalled,
    getProductInstalledByWorkOrder,
    getWorkOrderDetails,
    updateWorkOrder
} from "../../services/workOrders";
import {Product, WorkOrder} from "../../types/types";
import StepsFooter from "./components/StepsFooter";
import {getProductListById} from "../../services/products";
import StepChooser from "./steps/StepChooser";
import {FormProduct} from "../../types/inputs";
import {v4 as uuidv4} from "uuid";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../context/AuthProvider";

const WorkOrderEditPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState<string[]>([]);
    const {state} = useAuth()
    const [apiError, setApiError] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const {t} = useTranslation()

    const id = params.id ?? ""
    const userRoles = state?.userData?.roles
    const userId = state.userData?.id

    const {data: productsInstalled} = useQuery({
        queryFn: () => getProductInstalledByWorkOrder(id),
        queryKey: ["productsInstalled", params.id]  // Include params.id to make the query key unique per work order
    })

    const {data: workOrderDetails, isLoading} = useQuery({
        queryFn: () => getWorkOrderDetails(id),
        queryKey: ["workOrderDetails", params.id]  // Include params.id to make the query key unique per work order
    });

    const didUserClaim = workOrderDetails?.roles && workOrderDetails.roles.some((role: any) => role.claimId === userId)
    const [productInputs, setProductInputs] = useState<FormProduct[]>([]);

    useEffect(() => {
        if (!didUserClaim && !isLoading) {
            navigate(`/work_orders/${workOrderDetails?.id}`); // Redirect to an unauthorized page
        }
    }, [didUserClaim, isLoading]);

    useEffect(() => {
        setProductInputs(productsInstalled?.products?.map((p: any) => ({...p, productId: p.id})) ?? [])
    }, [productsInstalled?.products]);


    const mutation = useMutation<WorkOrder, Error, { id: string, order_template: any}>({
        mutationFn: ({
                         order_template,
                     }) => updateWorkOrder(workOrderDetails?.id ?? "", order_template),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', params.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', params.id]}).catch(console.error);
            setError([]);
            setApiError("")

        },
        onError: (error) => {
            setApiError(error.message);
        }
    });

    const updateProductsMutation = useMutation<any, Error, { id: string, products: FormProduct[] }>({
        mutationFn: ({}) => assignProductsInstalled(workOrderDetails?.id ?? "", productInputs),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', params.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', params.id]}).catch(console.error);
            setError([]);
            setApiError("")
        },
        onError: (error) => {
            setApiError(error.message);
        }
    });




    useEffect(() => {
        if(currentStep === 0 && mutation.status === "success"){
            setCurrentStep(1)
        }
        else if(currentStep === 1 && mutation.status === "success"  && updateProductsMutation.status === "success"){
            setCurrentStep(2)
        }
        else if(currentStep === 2  && mutation.status === "success"){
            setCurrentStep(3)
        }
        else if(currentStep === 3 && mutation.status === "success"){
            if(workOrderDetails?.customData){
                setCurrentStep(4)
            }
            else{
                if(updateProductsMutation.status === "success"){
                    return navigate(`/work_orders/${params.id}`);
                }
            }
        }
        else if(currentStep === 4 && mutation.status === "success" && updateProductsMutation.status === "success"){
            return navigate(`/work_orders/${params.id}`);
        }

    },  [updateProductsMutation.status, mutation.status]);




    const productType = workOrderDetails?.order_template?.schemaContent?.materials?.find((item: any) => item.inputType === "productList");

    const {data: productList, isLoading: isLoadingProducts} = useQuery({
        queryFn: () => getProductListById(productType?.type),
        queryKey: ["productList", productType?.type],  // Include productType?.type in the queryKey
        enabled: !!productType?.type  // Only fetch when productType?.type is valid
    })

    const handleSelectProduct = (product: Product) => {

        const newInput: FormProduct = {
            productId: product.id,
            quantity: product.serialNumber ? "1" : "",
            itemNumber: uuidv4(),
            productName: product.name,
            description: "",
            units: product.units,
            ...(product.serialNumber ? {serialNumber: ""} : {}),
        };

        setProductInputs(prev => {
            return [...prev, {...newInput}];
        });

    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getDropdownItems = (products: Product[] = []) => {
        return (Array.isArray(products) ? products : [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((product, index) => ({
                key: String(index + 1),
                value: product.name,
                product: product,
                label: (
                    <div
                        className={isMobile ? classes.mobileDropdownItem : classes.dropdownItem}
                        onClick={isMobile ? () => handleSelectProduct(product) : undefined}  // Only bind the function for mobile
                    >
                        {product.name}
                        {isMobile ? <Divider/> : <></>}
                    </div>
                ),
            }));
    };

    const dropdownItems = isLoadingProducts ? [] : getDropdownItems(productList);


    const inputValidation = () => {
        const errors: Set<string> = new Set(); // Initialize errors as a Set

        const schemaContent = Object.values(workOrderDetails?.order_template?.schemaContent || []).flat();
        const customContent = workOrderDetails?.customData

        // Ensure schemaContent exists and is an array
        if (Array.isArray(schemaContent)) {
            // Ensure customData is an array before adding

            // if (customContent) { //TODO ADD LATER
            //     schemaContent.push(...customContent); // Spread customData items into schemaContent
            // }

            // Now validate all the content in schemaContent (including customData if added)
            schemaContent.forEach((item: any) => {
                // Ensure each section is an object with the necessary properties
                if (item) {

                    const isRolesEmpty = !item?.roles || item.roles.length === 0;
                    const hasAccess = isRolesEmpty || userRoles?.some((userRole: any) => item.roles.includes(userRole.id));
                    const workOrderRoles = workOrderDetails?.roles || []; // Ensure roles array exists
                    const hasClaimed = workOrderRoles.some((role: any) => role.claimId === userId);

                    // Check if the field is required and has no value, and if the user has access and claimed the work order
                    if (item.isRequired && (item.value === undefined || item.value === null || item.value === '') && hasAccess && hasClaimed) {
                        console.log("value error")
                        errors.add(`${t("errors.required_value", {name: item.label})}`);
                    }
                }
            });
        } else {
            errors.add("Invalid schema content structure.");
        }

        return Array.from(errors); // Convert Set to an array
    };


    const productValidation = () => {
        const errors: Set<string> = new Set();  // Initialize an array to store errors

        // // Check if the productInputs array is empty
        if (productInputs.length === 0) {
            errors.add(t("errors.add_product"));
        }

        // Iterate over each product input to check individual fields
        productInputs.forEach((product) => {
            // Check if serialNumber exists and is empty
            if ('serialNumber' in product && !product.serialNumber) {
                errors.add(t("errors.serial_number"));
            }
        });

        return Array.from(errors)
    }


    const isValid = () => {

        let productErrors: string[] = [];
        let inputErrors: string[] = [];


        if ((workOrderDetails?.customData && currentStep === 4) || (!workOrderDetails?.customData && currentStep === 3)) {

            inputErrors = inputValidation();
            productErrors = productValidation();
        }

        const combinedErrors = [...productErrors, ...inputErrors];

        if (combinedErrors.length > 0) {
            setError(combinedErrors);
            return false
        }

        return true
    }



    const handleSaveWorkOrder = async () => {

        const valid = isValid()

        if (!valid) {
            return
        }

        if (currentStep === 1 || currentStep === 3) {
            //UPDATE PRODUCTS
            updateProductsMutation.mutate({
                id: workOrderDetails?.id ?? "",
                products: productInputs,
            })
        }

        // Create the base order template update
        let updatedOrderTemplate = {
            ...workOrderDetails?.order_template,
            statusId: workOrderDetails?.statusId?? null,
        };

        if(workOrderDetails?.customData) {
            if(currentStep === 3 || currentStep === 4)
            updatedOrderTemplate = {
                ...updatedOrderTemplate,
                customData: workOrderDetails?.customData || null,
            };
        }

        mutation.mutate({
            id: workOrderDetails?.id ?? "",
            order_template: updatedOrderTemplate,
        });

    };


    if (!workOrderDetails) return <h1>No work details</h1>;
    if (isLoading) return <Skeleton active={true}/>;

    const handleCancel = () => {
        navigate(`/work_orders/${params.id}`)
    }

    const stepChooserProps = {
        currentStep,
        productInputs,
        setProductInputs,
        dropdownItems,
        workOrderDetails,
    }

    // Titles for different steps in a multi-step process
    const getStepTitles = (customData: any, t: Function) => {
        return [
            {
                title: t("section.heading"),
            },
            {
                title: t("section.materials"),
            },
            {
                title: t("section.expenses"),
            },
            // Only render "extra" step if customData exists
            ...(customData != null
                ? [
                    {
                        title: t("section.extra"),
                        customData: customData, // Pass customData to the extra step if it exists
                    },
                ]
                : []),
            {
                title: t("section.preview"),
            },
        ];
    };

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <h2>{t("work_orders_page.edit_work_order")}</h2>
                <Button onClick={handleCancel} danger>{t("template_page.cancel")}</Button>
            </div>
            <Steps
                size="small"
                current={currentStep}
                className={classes.steps}
                items={getStepTitles(workOrderDetails.customData, t)}
            />
            <div className={classes.stepCard}>
                <br/>
                <Card style={{border: "2px solid #E6EFFC"}}>
                    <div className={classes.column}>
                        <StepChooser {...stepChooserProps}/>
                    </div>
                </Card>
                <br/>
                <StepsFooter customData={workOrderDetails.customData} currentStep={currentStep}
                             setCurrentStep={setCurrentStep}
                             handleSaveWorkOrder={handleSaveWorkOrder}/>
                {error && error.map((e, idx) => <Alert key={idx} message={e} type={"error"}
                                                       className={classes.alert}/>)}
                {apiError && <Alert message={apiError} type={"error"} className={classes.alert}/>}
            </div>
        </div>
    );
};

export default WorkOrderEditPage;
