// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_titleContainer__R\\+-bv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.style_icon__6KKJI {
    font-size: 20px;
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/WarningModal/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".titleContainer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.icon {\n    font-size: 20px;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `style_titleContainer__R+-bv`,
	"icon": `style_icon__6KKJI`
};
export default ___CSS_LOADER_EXPORT___;
