import React from 'react';
import {Button, Card, Input} from "antd";
import classes from "../../styles.module.css";
import Checkbox from "antd/es/checkbox/Checkbox";
import {FormDate} from "../../../../types/inputs";
import {useTranslation} from "react-i18next";
import AddInputRoles from "../../../templates/components/AddInputRoles";

interface DateCreatorProps {
    handleRemove: (remove: string) => void
    date: FormDate
    onInputChange: (id: string, field: string, value: string | string[]) => void
    handleCheckboxChange: (id: string, checked: boolean) => void
}


const DateCreator: React.FC<DateCreatorProps> = ({handleRemove, handleCheckboxChange, onInputChange, date}) => {

    const {t} = useTranslation()

    return (
        <Card className={classes.inputCreatorCard}>
            <div className={classes.inputRow}>
                <h3>DatePicker:</h3>
                <div className={classes.inputMiddleRow}>
                    <Input
                        value={date.label}
                        suffix={<span className={classes.red}>*</span>}
                        style={{width: 'auto'}}
                        placeholder={t("template_page.label")}
                        onChange={(e) => onInputChange(date.id, 'label', e.target.value)}
                    />
                    <Input
                        value={date.name}
                        suffix={<span className={classes.red}>*</span>}
                        style={{width: 'auto'}}
                        placeholder={t("template_page.name")}
                        onChange={(e) => onInputChange(date.id, 'name', e.target.value)}
                    />

                </div>
                <div className={classes.row}>
                    <Checkbox
                        checked={date.isRequired}
                        onChange={(e) => handleCheckboxChange(date.id, e.target.checked)}
                    >
                        {t("template_page.required")}
                    </Checkbox>
                    <AddInputRoles defaultValue={date.roles} onInputChange={onInputChange.bind(null, date.id, 'roles')}/>
                    <Button danger onClick={() => handleRemove(date.id)}>
                        {t("work_order_details.remove")}
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default DateCreator;