import getAxiosInstance from "./axiosInstance";
import {AxiosResponse} from "axios";

const client = getAxiosInstance();

async function  genericGet<T>(url: string): Promise<T> {
    try {
        const res: AxiosResponse<T> = await client.get(url)
        return res.data
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

async function genericPost<T,K>(url:string, reqBody: T): Promise<K> {
    try {
        const res: AxiosResponse<K> = await client.post(url,reqBody)
        return res.data
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

async function genericPut<T,K>(url:string, reqBody: T): Promise<K> {
    try {
        const res: AxiosResponse<K> = await client.put(url,reqBody)
        return res.data
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}


const genericService = {genericGet,genericPost,genericPut}

export default genericService