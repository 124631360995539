import React from 'react';
import {Tabs, TabsProps} from "antd";
import classes from "./style.module.css"
import {
    NumberOutlined,
    ShopOutlined,
    TeamOutlined,
    TruckOutlined,
    UserOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import UserTable from "./components/UserTable";
import PersonTable from "./components/PersonTable";
import GroupsTable from "./components/GroupsTable";
import CompaniesTable from "./components/CompaniesTable";
import WorkUnitTable from "./components/WorkUnitTable";
import DocumentNumberingTable from "./components/DocumentNumberingTable";
import {useTranslation} from "react-i18next";

const InfoPage = () => {

    const {t} = useTranslation()

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <> {t("table.users")}</>,
            children: <UserTable/>,
            icon: <UserOutlined/>,
        },
        {
            key: '2',
            label: <> {t("table.persons")}</>,
            children: <PersonTable/>,
            icon: <UserSwitchOutlined/>,
        },
        {
            key: '3',
            label: <> {t("table.groups")}</>,
            children: <GroupsTable/>,
            icon: <TeamOutlined/>,
        },
        {
            key: '4',
            label: <> {t("table.companies")}</>,
            children: <CompaniesTable/>,
            icon: <ShopOutlined/>,
        },
        {
            key: '5',
            label: <> {t("table.work_units")}</>,
            children: <WorkUnitTable/>,
            icon: <TruckOutlined />,
        },
        {
            key: '6',
            label: <> {t("table.numbering_systems")}</>,
            children: <DocumentNumberingTable/>,
            icon: <NumberOutlined/>,
        },
    ];

    return (
        <div className={classes.tableContainer}>
            <Tabs defaultActiveKey="1" items={items}/>
        </div>
    );
};

export default InfoPage;