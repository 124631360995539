import React, {useState} from 'react';
import {Alert, Button, Card, Input} from "antd";
import classes from "./style.module.css";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthProvider";
import logo from "../../assets/logo.png";
import {login} from "../../services/login";
import {jwtDecode} from "jwt-decode";
import {useMutation} from "@tanstack/react-query";
import {UserDataType} from "../../types/types";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../../components/Header/LanguageSwitcher";


export default function LoginPage() {

    const {dispatch} = useAuth()


    const navigate = useNavigate();
    const [username, setUsername] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState("")
    const {t} = useTranslation()


    const mutation = useMutation<{ token: string }, Error, { username: string; password: string }>({
        mutationFn: ({username, password}) => login(username, password),
        onSuccess: (data) => {
            setError("");
            dispatch({type: 'SET_TOKEN_ACTION', payload: data.token})
            const userData: UserDataType = jwtDecode(data.token);
            dispatch({type: 'SET_USER_DATA', payload: userData})
            navigate("/dashboard", {replace: true});
        },
        onError: (error) => {
            setError(error.message);
        }
    });

    const handleLogin = (e: any) => {

        e.preventDefault();

        if (!username || !password) {
            return
        }

        mutation.mutate({username: username, password: password})
    }

    return (
        <div className={classes.loginContainer}>
            <Card title={t("login.title")} extra={<LanguageSwitcher/>} className={classes.card}>
                <img alt={"logo"} src={logo} className={classes.logo}/>
                <form onSubmit={handleLogin}>
                    <div className={classes.container}>
                        <Input placeholder={t("login.username")} id="username" type={"text"} value={username}
                               onChange={(e) => setUsername(e.target.value)}/>
                        <Input.Password
                            placeholder={t("login.password")} id="password" value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                        />
                        {error && <Alert type="error" message={error} className={classes.errorText}/>}
                        <Button className={classes.submitButton} type="primary" htmlType="submit">{t("login.title")}</Button>
                    </div>
                </form>
            </Card>
        </div>
    );
}

