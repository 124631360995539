import React from 'react';
import {FormInputTypes, FormOption, InputTypesEnum} from "../../../types/inputs";
import InputCreator from "../../work-orders/components/inputCreators/InputCreator";
import TextareaCreator from "../../work-orders/components/inputCreators/TextareaCreator";
import DateCreator from "../../work-orders/components/inputCreators/DateCreator";
import ProductListCreator from "../../work-orders/components/inputCreators/ProductListCreator";
import SelectCreator from "../../work-orders/components/inputCreators/SelectCreator";
import {isEmpty} from "lodash";

interface InputSelectorProps {
    onChange: (id: string, data: any) => void
    handleRemove:(id:string)=> void,
    inputData: FormInputTypes
}

const InputSelector: React.FC<InputSelectorProps> = ({onChange,handleRemove, inputData}) => {


    const handleCheckboxChange = (id: string, checked: boolean) => {
        onChange(id, {isRequired: checked})
    };

    const handleOnChange = (id: string, field: string, value: string | string[]) => {
        onChange(id, {[field]: value})
    }

    if (inputData.type === InputTypesEnum.Input) {
        return <InputCreator
            input={inputData.data}
            handleRemove={handleRemove}
            onInputChange={handleOnChange}
            handleCheckboxChange={handleCheckboxChange}/>
    }
    if(inputData.type === InputTypesEnum.Textarea){
        return <TextareaCreator
            textarea={inputData.data}
            handleRemove={handleRemove}
            onInputChange={handleOnChange}
            handleCheckboxChange={handleCheckboxChange}/>
    }
    if(inputData.type === InputTypesEnum.Select){
        const handleAddOption = (inputValue: string, id: string) => {
            if(isEmpty(inputValue.trim())){
                return;
            }
            const newOption: FormOption = {
                value: inputValue,
                id: inputValue,
                label: inputValue
            }

            const newOptions = [...inputData.data.options,newOption];
            onChange(id, {options: newOptions})
        }

        const handleRemoveOption = (e: any, id: string, selectId: string)=>{
            e.preventDefault()
            const newOptions = inputData.data.options.filter((option: FormOption) => option.id !== id)
            onChange(selectId, {options: newOptions})
        }


        return <SelectCreator
            select={inputData.data}
            handleRemove={handleRemove}
            onInputChange={handleOnChange}
            handleAddOption={handleAddOption}
            handleRemoveOption={handleRemoveOption}
            handleCheckboxChange={handleCheckboxChange}/>
    }

    if(inputData.type === InputTypesEnum.Date){
        return <DateCreator
                    date={inputData.data}
                    handleRemove={handleRemove}
                    onInputChange={handleOnChange}
                    handleCheckboxChange={handleCheckboxChange}/>
    }

    if(inputData.type === InputTypesEnum.ProductList){
        return <ProductListCreator productList={inputData.data} onInputChange={handleOnChange}/>
    }



};

export default InputSelector;