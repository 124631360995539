import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, Tabs, TabsProps} from "antd";
import classes from "./styles.module.css";
import {AppstoreOutlined, PlusOutlined, TableOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import WorkOrderSkeleton from "./components/WorkOrderSkeleton";
import {getMyWorkOrders} from "../../services/workOrders";
import CardView from "./Views/CardView";
import TableView from "./Views/TableView";
import TemplateModal from "./modals/TemplateModal";
import WorkOrderFilters from "./components/workOrderFilters/WorkOrderFilters";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import {WorkOrder} from "../../types/types";

interface ButtonsProps {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ButtonActions: React.FC<ButtonsProps> = ({setIsModalOpen}) => {

    const navigate = useNavigate()
    const {t} = useTranslation();

    return <>
        <Button onClick={() => {
            navigate(`/work_orders/template`)
        }} icon={<PlusOutlined/>}>{t("work_orders_page.create_new_template")}</Button>
        <Button type={"primary"} onClick={() => {
            setIsModalOpen(true)
        }} icon={<PlusOutlined/>}>{t("work_orders_page.create_new_work_order")}</Button></>
}

const WorkOrdersPage = () => {

        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isMobile, setIsMobile] = useState(false);
        const [activeKey, setActiveKey] = useState<string>(localStorage.getItem('activeTab') || "1");

        const {t} = useTranslation()


        const {data: workOrders, isLoading} = useQuery({
            queryFn: () => getMyWorkOrders(),
            queryKey: ["workOrders"]
        })

        const [filteredWorkOrders, setFilteredWorkOrders] = useState<WorkOrder[] | undefined>(workOrders);

        useEffect(() => {
            if (isMobile) {
                setActiveKey('1')
            } else {
                setActiveKey(localStorage.getItem('activeTab') || "1")
            }
        }, [isMobile]);


        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768); // Adjust width as per your mobile breakpoint
            };

            handleResize(); // Check on initial render
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        const handleTabChange = (key: string) => {
            localStorage.setItem('activeTab', key);
            setActiveKey(key);
        };

        const getItems = (t: TFunction<"translation", undefined>) => {
            return [
                {
                    key: '1',
                    label: <> {t("work_orders_page.card_view")} </>,
                    children: <CardView workOrders={filteredWorkOrders}/>,
                    icon: <AppstoreOutlined/>
                },
                {
                    key: '2',
                    label: <> {t("work_orders_page.table_view")} </>,
                    children: <TableView data={filteredWorkOrders}/>,
                    icon: <TableOutlined/>,
                    disabled: isMobile
                },
            ] as TabsProps['items']
        }

        const items = getItems(t)

        if (isLoading) return <WorkOrderSkeleton/>

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <h2>{t("work_orders_page.my_work_orders")}</h2>
                    <div className={classes.row}>
                        <ButtonActions setIsModalOpen={setIsModalOpen}/>
                    </div>
                </div>
                <WorkOrderFilters workOrders={workOrders} setFilteredWorkOrders={setFilteredWorkOrders}/>
                <Tabs items={items} onChange={handleTabChange} activeKey={activeKey}/>
                <TemplateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
            </div>
        );
    }
;

export default WorkOrdersPage;