import React from 'react';
import {FormProduct} from "../../../../../types/inputs";
import {isUndefined} from "lodash";
import {PlusOutlined} from "@ant-design/icons";
import AddonProduct from "../AddonProduct";

interface ProductAddonListProps {
    addons?: FormProduct[];
    handleUpdateInput: (id: string, field: string, value: any) => void
    handleRemoveAddon: (addon: FormProduct) => void
}

const ProductAddonList: React.FC<ProductAddonListProps> = ({addons, handleRemoveAddon, handleUpdateInput}) => {

    if (isUndefined(addons) || addons?.length === 0) {
        return null
    }

    return (
        <div>
            <h3 style={{color: "#4096FF"}}><PlusOutlined/>Addons</h3>
            {addons?.map((addon) => {
                return <AddonProduct handleUpdateInput={handleUpdateInput} key={addon.itemNumber}
                                     addon={addon}
                                     handleRemoveAddon={handleRemoveAddon}/>
            })}
        </div>
    );
}

export default ProductAddonList;