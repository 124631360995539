import React, {useState} from 'react';
import classes from "../../style.module.css";

import {Alert, Image} from "antd";
import {File, TaskDetails} from "../../../../types/types";
import TableRow from "./TableRow";
import ListTaskItem from "./ListTaskItem";
import {PaperClipOutlined} from "@ant-design/icons";
import FileUpload from "../../../work-orders/components/FileUpload";
import useUtils from "../../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";


interface ITaskColumnsProps {
    task: TaskDetails
    setIsModalOpen: (open: boolean) => void
}

interface ListProps {
    files: File[];
}

const List: React.FC<ListProps> = ({files}) => {

    const {isImageFile, truncateText} = useUtils()
    const {t} = useTranslation()

    // Separate image and non-image files
    const imageFiles = files.filter(file => isImageFile(file.name));
    const nonImageFiles = files.filter(file => !isImageFile(file.name));

    if (files.length > 0) {
        return (
            <div>
                {/* Images Section */}
                {imageFiles.length > 0 && (
                    <div className={classes.imagesContainer}>
                        <Image.PreviewGroup>
                            {imageFiles.map((file: File, index) => (
                                <span key={file.url + index}>
                                <Image
                                    style={{
                                        width: "250px",
                                        height: "250px",
                                        backgroundSize: "cover",
                                        borderRadius: "5%"
                                    }}
                                    src={file.url}
                                    alt={file.name}
                                />
                            </span>
                            ))}
                        </Image.PreviewGroup>
                    </div>
                )}
                <br/>
                {/* Non-Image Files Section */}
                {nonImageFiles.length > 0 && (
                    <div className={classes.filesContainer}>
                        {nonImageFiles.map((file: File, index) => (
                            <span key={file.url + index}>
                                {" "}
                                <PaperClipOutlined/>
                                <a
                                    href={file.url}
                                    key={file.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {window.innerWidth <= 480
                                        ? truncateText(file.name, 20, false)
                                        : file.name}
                                </a>
                            </span>
                        ))}
                    </div>
                )}
            </div>
        );
    }
    return <span>{t("work_order_details.no_files")}</span>;
};


const TableData: React.FC<ITaskColumnsProps> = ({task, setIsModalOpen}) => {
    const [error, setError] = useState("");

    const {getStatus, getPriority, getType} = useUtils()

    const {t} = useTranslation()

    return (
        <div className={classes.columnContainer}>
            <TableRow title={t("task_details.title")} data={task.title}/>
            <TableRow title={t("task_details.description")} data={task.text} className={classes.description}/>
            <TableRow title={t("task_details.status")} className={classes.tag} data={getStatus(task.status)}/>
            <TableRow title={t("task_details.priority")} data={getPriority(task.priority)} extraData={`(${task.priority})`}/>
            <TableRow title={t("task_details.type")} data={getType(task.taskKind)} extraData={`(${task.taskKind})`}/>
            <TableRow title={t("task_details.assigned_users")}
                      data={<ListTaskItem setError={setError}
                                          setIsModalOpen={setIsModalOpen}
                                          taskId={task.id}
                                          data={task.users}
                                          type={"user"}/>}/>
            <TableRow title={t("task_details.assigned_groups")}
                      data={<ListTaskItem setIsModalOpen={setIsModalOpen}
                                          setError={setError} taskId={task.id}
                                          data={task.groups}
                                          type={"group"}/>}/>
            <TableRow className={classes.company} title={t("task_details.company")}
                      data={!task.companyName ? t("task_details.no_company") : task.companyName}/>
            <TableRow className={classes.company} title={t("task_details.files")}
                      data={<List files={task.filesAttached}/>}
            />
            <div className={classes.fileContainer}><FileUpload itemId={task.id} fileFolderName={"task"}/></div>
            {error && <Alert type="error" className={classes.alert} message={error}/>}
        </div>
    );
};

export default TableData