import React from 'react';
import classes from "../../style.module.css";

interface ITableRowProps {
    title: string
    data: any
    extraData?: any
    className?: any
}

const TableRow: React.FC<ITableRowProps> = ({title, data, extraData, className}) => {
    return (
        <div className={classes.rowContainer}>
            <b>{title}:</b>
            <div className={className ?? className}>{data} {extraData ?? extraData}</div>
        </div>
    );
};

export default TableRow;