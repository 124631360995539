import React, {useState} from 'react';
import {Button, Divider, Input, Radio, RadioChangeEvent} from "antd";
import classes from "./style.module.css";
import TextArea from "antd/es/input/TextArea";
import {optionsWithDisabled} from "../../../../utils/globalFunctions";
import {FormProduct} from "../../../../types/inputs";

interface AddonProductProps {
    addon:  FormProduct ;
    handleRemoveAddon: (addon: FormProduct) => void;
    handleUpdateInput: (id: string, field: string, value: any) => void
}

const AddonProduct:React.FC<AddonProductProps> = ({addon,  handleRemoveAddon, handleUpdateInput}) => {

    const [value, setValue] = useState(addon.quantity ? addon.quantity.toString() : "1");


    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
        handleUpdateInput(addon.itemNumber, "quantity", value)
    };

    if(!addon) return null


    return (
        <div><Divider/>
            <h4 style={{width: "200px"}}>{addon.productName}</h4>
            <div className={classes.addonContainer}>
                {addon.serialNumber != null ? (  // Check if serialNumber exists and is not null
                    <>
                        <Input
                            style={{ minWidth: "200px" }}
                            defaultValue={addon.serialNumber}
                            type={"text"}
                            placeholder={"serial number"}
                            onChange={(e) => handleUpdateInput(addon.itemNumber, "serialNumber", e.target.value)}
                            required
                        />
                        <Radio.Group
                            options={optionsWithDisabled}
                            onChange={onChange}
                            value={addon.quantity.toString() ?? "1"}
                            optionType="button"
                            buttonStyle="solid"
                            className={classes.radioRow}
                        />
                    </>
                ) : (
                    <Input
                        style={{ minWidth: "200px" }}
                        type={"number"}
                        defaultValue={addon.quantity}
                        placeholder={"quantity"}
                        onChange={(e) => handleUpdateInput(addon.itemNumber, "quantity", e.target.value)}
                        suffix={addon.units}
                        required
                    />
                )}
                <Button onClick={() => handleRemoveAddon(addon)} danger>Remove</Button>
            </div>
            <br/>
            <div className={classes.container}>
                <TextArea defaultValue={addon.description}
                          onChange={(e) => handleUpdateInput(addon.itemNumber, "description", e.target.value)}
                          placeholder={"description"} autoSize={true}/>
            </div>
        </div>
    );
};

export default AddonProduct;