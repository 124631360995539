import React, {useState} from 'react';
import {Button, Card} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import classes from "../styles.module.css";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteTemplate} from "../../../services/template";
import {createWorkOrder} from "../../../services/workOrders";
import {FormLocation} from "../../../types/inputs";

interface TemplateItemProps {
    item: any
    setIsModalOpen: (open:boolean) => void
    setError: (err:string) => void

}

const TemplateItem:React.FC<TemplateItemProps> = ({item, setIsModalOpen, setError}) => {

    const navigate = useNavigate()
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const queryClient = useQueryClient()

    const createWorkOrderMutation = useMutation<any, Error, { template: any }>({
        mutationFn: ({template}) => createWorkOrder(template),
        onSettled: (data) => {



            navigate(`/work_orders/${data.id}/edit`);

            queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error);
            setError("");

            //navigate("/work_orders");
        },
        onError: (error) => {
            //@ts-ignore
            setError(error.message);
        }
    });

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteTemplate(id),
        onSuccess: async () => {
            setError("");
            await queryClient.invalidateQueries({queryKey: ['templates']}).catch(console.error)
            setIsWarningModalOpen(false);
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });


    const [inputLocation, setInputLocation] = useState<FormLocation>({
        label: "Address",
        name: "location",
        value: "",
        inputType: "location",
        lat: 0,
        long: 0
    });

    const handleDeleteTemplate = (id:string) => {
        mutation.mutate({id});
    }

    const handleCreateWorkOrder = () => {

        // Clone the original schemaContent to avoid directly mutating the template
        const updatedSchemaContent = {
            heading: [...(item.schemaContent?.heading || [])],
            materials: [...(item.schemaContent?.materials || [])],
            expenses: [...(item.schemaContent?.expenses || [])],
        };

        // Assuming `inputLocation` is defined and should be part of the heading section
        updatedSchemaContent.heading.push(inputLocation);

        // Prepare the updated template object with the same structure as the original template
        const updatedTemplate = {
            ...item,
            schemaContent: updatedSchemaContent,
        };

        // Perform the mutation with the updated template
        createWorkOrderMutation.mutate({ template: updatedTemplate });

        //navigate(`/work_orders/${item.id}/create`)}
    }


    return (
        <>
            <Card key={item.id} actions={[
                <Button type={"primary"} onClick={handleCreateWorkOrder}>Select</Button>,
                <Button type={"text"} onClick={() => navigate(`/work_orders/template/${item.id}`)}
                        icon={<EditOutlined/>}>Edit</Button>,
                <Button type={"text"} style={{color: "red"}} onClick={() => setIsWarningModalOpen(true)}
                        icon={<DeleteOutlined/>}>Delete</Button>
            ]} className={classes.templateCard}><h3>{item.title}</h3></Card>

            <WarningModal title={"Are you sure you want to delete this template?"}
                          handler={handleDeleteTemplate.bind(this, item.id)} isModalOpen={isWarningModalOpen}
                          setIsModalOpen={setIsWarningModalOpen} data={item.title} deleteText={"Delete"}/>
        </>
    );
};

export default TemplateItem;