import React, {useState} from 'react';
import {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {Button, Card, Collapse, Divider, Tag} from "antd";
import classes from "../styles.module.css";

import {useNavigate, useParams} from "react-router-dom";
import DetailsSection from "./DetailsSection";
import {WorkOrderDetails} from "../../../types/types";
import FileUpload from "./FileUpload";
import Notes from "./Notes";
import FilesList from "./FilesList";
import StatusList from "./StatusList";
import AssignModal from "../../tasks/TaskDetailsPage/components/modals/AssignModal/AssignModal";
import AssignedGroupsUsers from "./AssignedGroupsUsers";
import ClaimRolesContainer from "./ClaimRolesContainer";
import useUtils from "../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../../context/AuthProvider";
import workOrderEditPage from "../WorkOrderEditPage";
import workOrderDetailsPage from "../WorkOrderDetailsPage";


interface IDetailsCardProps {
    item: WorkOrderDetails
}


const DetailsCard: React.FC<IDetailsCardProps> = ({item}) => {

    const navigate = useNavigate();
    const params = useParams();
    const [openModal, setOpenModal] = useState(false)

    const {t} = useTranslation()

    const {state} = useAuth()

    const user = state.userData

    const {formatDate} = useUtils()

    //Fix for different data format from BE
    const normalizedUsers = item.users?.map((user: any) => ({
        ...user,
        id: user.userId ?? user.id, // Normalize to use 'id'
    })) || [];

    const normalizedGroups = item.groups?.map((group: any) => ({
        ...group,
        id: group.groupId ?? group.id, // Normalize to use 'id'
    })) || [];

    const didUserClaim = item.roles.some((role: any) => role.claimId === user?.id)

    return (
        <>
            <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
                <Collapse.Panel header={<h2>{t("work_order_details.work_order_details")} <InfoCircleOutlined/></h2>}
                                key="1">
                    <Card
                        className={classes.titleCard}
                        title={
                            <div className={classes.statusRow}>
                                <h2>
                                    {item.title}
                                    {" "}<Tag color={item.statusColor}>{item.statusName}</Tag>
                                    <div
                                        className={classes.date}>{t("tasks_page.updated_at", {date: formatDate(item?.updatedDate)})}</div>
                                </h2>
                            </div>
                        }
                        extra={
                            <>
                                <Button
                                    className={classes.editButton}
                                    type="primary"
                                    disabled={!didUserClaim}
                                    onClick={() => navigate(`/work_orders/${params.id}/edit`)}
                                    icon={<EditOutlined/>}
                                >
                                    {t("work_order_details.edit")}
                                </Button>
                            </>
                        }
                    >
                        <div className={classes.assignContainer}>
                            <AssignedGroupsUsers item={item}/>
                            <Button
                                onClick={setOpenModal.bind(null, true)}>{t("work_order_details.assign_work_order")}</Button>
                        </div>
                        <Divider/>
                        <ClaimRolesContainer item={item}/>
                        <Divider/>
                        <Notes item={item}/>
                        <Divider/>
                        <FilesList files={item.filesAttached}/>
                        <FileUpload itemId={item.id} fileFolderName={"workorder"}/>
                        <Divider/>
                        <StatusList statusListId={item.status_list_id} currentStatus={item.statusId}
                                    workOrderId={item.id}/>
                        <Divider/>
                        <div className={classes.workOrderContainer}>
                            <div className={classes.formContainer}>
                                {Object.entries(item?.order_template?.schemaContent || {}).map(([key, value]) => (
                                    <div key={key} className={classes.detailsSectionWrapper}>
                                        <DetailsSection
                                            data={value}
                                            section={key.charAt(0).toUpperCase() + key.slice(1)}
                                        />
                                    </div>
                                ))}
                                {item.customData &&
                                    <div className={classes.detailsSectionWrapper}>
                                        <DetailsSection
                                            data={item.customData}
                                            section={t("section.extra")}
                                            // section={key.charAt(0).toUpperCase() + key.slice(1)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div
                            className={classes.date}>{t("tasks_page.created_at", {date: formatDate(item?.createdDate)})}</div>
                        <div
                            className={classes.date}>{t("tasks_page.updated_at", {date: formatDate(item?.updatedDate)})}</div>
                    </Card>
                </Collapse.Panel>
            </Collapse>
            <AssignModal isModalOpen={openModal} setIsModalOpen={setOpenModal} type={"work-order"} workOrderId={item.id}
                         title={t("assign_modal.title2")} users={normalizedUsers} groups={normalizedGroups}/>
        </>
    );
};

export default DetailsCard;