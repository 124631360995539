import React from 'react';
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getTaskHistory} from "../../../services/tasks";
import {Card, Collapse, Skeleton, Tabs, TabsProps, Timeline} from "antd";
import TaskHistoryItem from "./TaskHistoryItem";
import classes from "../style.module.css"
import {HistoryOutlined} from "@ant-design/icons";
import {getTaskComments} from "../../../services/comments";
import {Comment} from "../../../types/types";
import CommentItem from "./components/CommentItem";

const TaskHistorySection = () => {

    const params = useParams()

    const {data: taskLog, isLoading} = useQuery({
        queryFn: () => getTaskHistory(params?.id),
        queryKey: ["taskHistory", params.id],
    })

    const {data: comments} = useQuery({
        queryFn: () => getTaskComments(params?.id),
        queryKey: ["taskComments", params.id],
    })


    if (isLoading) {
        return <Card style={{width: "98%", margin: "0 auto"}}>
            <Skeleton active={isLoading}/>
        </Card>
    }

    const taskLogItems = taskLog && taskLog.length > 0 ?
        taskLog.map((log, index) => ({
            children: <TaskHistoryItem key={index} log={log}/>
        })) :
        [{
            children: 'Currently no logs for this task',
            color: "gray",
        }];


    const taskCommentItems = comments && comments.length > 0 ?
        comments.map((comment: Comment, index) => ({
            color: 'red',
            children: <CommentItem key={comment.id} taskId={params.id} comment={comment}/>
        })) :
        [{
            children: 'Currently no comments for this task',
            color: 'gray'
        }];

    const onChange = (key: string) => {
        //console.log(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Logs',
            children:
                <div className={classes.scrollerDiv}>
                    <Timeline
                        style={{padding: "10px"}}
                        items={[...taskLogItems]}
                    />
                </div>

        },
        {
            key: '2',
            label: 'Comments',
            children:
                <div className={classes.scrollerDiv}>
                    <Timeline
                        style={{padding: "10px"}}
                        items={[...taskCommentItems]}
                    />
                </div>

        },
        {
            key: '3',
            label: 'Logs / Comments',
            children:
                <div className={classes.scrollerDiv}>
                    <Timeline
                        style={{padding: "10px"}}
                        items={[...taskLogItems, ...taskCommentItems]}
                    />
                </div>

        }
    ];

    return (
        <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel header={<h2>Task history <HistoryOutlined/></h2>} key="1">
                <Card className={classes.taskHistoryBody}>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
                </Card>
            </Collapse.Panel>
        </Collapse>
    )
};

export default TaskHistorySection;