import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getStatusListTemplate} from "../../../services/statuses";
import {Select} from "antd";

interface StatusListDropdownProps {
    statusListId: string | undefined
    setStatusListId: (id: string) => void
}

const StatusListDropdown:React.FC<StatusListDropdownProps> = ({statusListId,setStatusListId}) => {

    const {data: statusList} = useQuery({
        queryFn: () => getStatusListTemplate(),
        queryKey: ["statusList"]
    })

    const onChange = (value: string) => {
        setStatusListId(value)
    };

    const options = Array.isArray(statusList)
        ? statusList.map((p: any) => ({ value: p.id, label: p.name }))
        : [];

    return (
        <Select
            value={statusListId}
            style={{ minWidth: "200px", width:"20%" }}
            onChange={onChange}
            placeholder={"Select status list"}
            options={options}
        />
    );
};

export default StatusListDropdown;