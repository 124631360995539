import React, {useState} from 'react';
import {Button, Card} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import classes from "../styles.module.css";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteTemplate} from "../../../services/template";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useTranslation} from "react-i18next";

interface TemplateItemProps {
    item: any
    setIsModalOpen: (open: boolean) => void
    setError: (err: string) => void
    templateId: string
    setTemplateId: (templateId: string) => void

}

const TemplateItem: React.FC<TemplateItemProps> = ({item, setError, templateId, setTemplateId}) => {

    const navigate = useNavigate()
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const queryClient = useQueryClient()
    const {t} = useTranslation()


    const handleDeleteTemplate = (id: string) => {
        mutation.mutate({id});
    }

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteTemplate(id),
        onSuccess: async () => {
            setError("");
            await queryClient.invalidateQueries({queryKey: ['templates']}).catch(console.error)
            setIsWarningModalOpen(false);
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    return (
        <>
            <Card
                onClick={setTemplateId.bind(null, item.id)}
                style={templateId === item.id ? {border: "2px solid #1890ff"} : {}}
                key={item.id}
                actions={[
                    <Button type="text" onClick={() => navigate(`/work_orders/template/${item.id}`)}
                            icon={<EditOutlined/>}>
                        {t("work_orders_page.edit")}
                    </Button>,
                    <Button type="text" style={{color: "red"}} onClick={() => setIsWarningModalOpen(true)}
                            icon={<DeleteOutlined/>}>
                        {t("work_orders_page.delete")}
                    </Button>
                ]}
                className={classes.templateCard}
            >
                <h3>{item.title}</h3>
            </Card>
            <WarningModal title={t("warning_modal.title2")}
                          handler={handleDeleteTemplate.bind(this, item.id)} isModalOpen={isWarningModalOpen}
                          setIsModalOpen={setIsWarningModalOpen} data={item.title}
                          deleteText={t("warning_modal.delete")}/>
        </>
    );
};

export default TemplateItem;
