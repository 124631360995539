import React from 'react';
import classes from "../styles.module.css";
import {Button} from "antd";

interface StepsFooterProps {
    currentStep: number;
    setCurrentStep: (step: number) => void;
    handleSaveWorkOrder: () => void
}

const StepsFooter:React.FC<StepsFooterProps> = ({currentStep,setCurrentStep, handleSaveWorkOrder}) => {

    return (
        <div className={classes.stepButtonContainer}>
            {currentStep !== 0 && <Button onClick={() => setCurrentStep(currentStep - 1)}>Back</Button>}
            {currentStep < 2 &&
                <Button type="primary" onClick={handleSaveWorkOrder}>Next</Button>}
            {currentStep === 2 &&
                <Button type="primary" onClick={handleSaveWorkOrder}>Next</Button>}
            {currentStep === 3 &&
                <Button htmlType="submit" type="primary" onClick={handleSaveWorkOrder}>Save</Button>}
        </div>
    );
};

export default StepsFooter;