import React, {useState} from 'react';
import {Button, Dropdown, Input, Menu, MenuProps} from "antd";
import {PlusOutlined} from "@ant-design/icons";

interface FullScreenDropdownProps {
    dropdownItems: {
        value: string; key: string, label: React.ReactNode
    }[] | undefined;
    title: string
}


const FullScreenDropdown:React.FC<FullScreenDropdownProps> = ({dropdownItems, title}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Filter dropdown items based on the search term
    const filteredItems = dropdownItems?.filter(item =>
        item.value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Define menu items based on filtered data
    const menuItems: MenuProps['items'] = filteredItems?.map(item => ({
        key: item.key,
        label: item.label,
    }));

    // Full-screen dropdown content with search and list
    const FullScreenMenu = (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',          // Full viewport height
            width: '100vw',           // Full viewport width
            padding: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.95)', // Slight transparency
            boxSizing: 'border-box',
            position: 'fixed',        // Fixed to cover entire viewport
            top: 0,
            left: 0,
            zIndex: 1000,
        }}>
            {/* Search Input */}
            <Input
                placeholder="Search addons..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                    margin: '20px 0',
                    width: '100%',
                }}
            />

            {/* Render the filtered items as a Menu */}
            <Menu
                style={{
                    flex: 1,
                    overflowY: 'auto',   // Only the Menu scrolls
                    width: '100%',
                }}
                items={menuItems}
            />
        </div>
    );

    return (
        <Dropdown
            menu={{items: menuItems}}
            disabled={dropdownItems?.length === 0}
            placement="top"  // Set the dropdown placement to appear from the top
            dropdownRender={() => FullScreenMenu} // Use dropdownRender to customize menu appearance
            getPopupContainer={() => document.body} // Append dropdown to body for full-screen effect
        >
            <Button style={{width:"100%"}} icon={<PlusOutlined/>}>{title}</Button>
        </Dropdown>
    );
};

export default FullScreenDropdown;