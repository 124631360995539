import React from 'react';
import {StatusList} from "../../../types/types";
import CustomSectionItem from "./CustomSectionItem";

interface ISectionsListProps {
    statuses: StatusList | undefined
}



const CustomSectionList:React.FC<ISectionsListProps> = ({statuses}) => {

    return (
        <div>
            {statuses?.status?.map((section) => (
                <CustomSectionItem key={section.id} section={section}/>
            ))}
        </div>
    );
};

export default CustomSectionList;