import React from 'react';
import classes from "../styles.module.css";
import {SectionInputStateType} from "../../templates/contex/SectionInputReducer";
import {Product, SectionEnum} from "../../../types/types";
import useUtils from "../../../utils/globalFunctions";
import {useSectionInputContext} from "../../templates/contex/sectionInputContext";
import {FormProductList, FormSectionType} from "../../../types/inputs";
import {useQuery} from "@tanstack/react-query";
import {getProductListById} from "../../../services/products";
import {Spin} from "antd";
import {useCustomSectionInputContext} from "../../templates/contex/customSectionInputContext";

interface SectionStepsProps {
    currentStep: number;
}

const SectionSteps:React.FC<SectionStepsProps> = ({currentStep}) => {

    const {state} = useSectionInputContext()
    const {state: state2} = useCustomSectionInputContext()
    const heading = state[SectionEnum.Heading].data.map(item => item.data)
    const expenses = state[SectionEnum.Expenses].data.map(item => item.data)

    const allInputsBySection = Object.values(state2).flatMap(
        section => section.data.map(item => item.data)
    );

    if(currentStep === 0) {
        return <SectionPreview sectionData={heading} />
    }

    if (currentStep === 1) {
            return <MaterialsPreview materials={state[SectionEnum.Materials].data}/>
    }

    if (currentStep === 2) {
        return <SectionPreview sectionData={expenses} />
    }

    if (currentStep === 3) {
        return <SectionPreview sectionData={allInputsBySection} />
    }

   return  null;
};

export default SectionSteps;
interface SectionPreviewProps {
    sectionData: FormSectionType
}


const SectionPreview: React.FC<SectionPreviewProps> = ({sectionData}) => {

    const {handleInputRender} = useUtils()

    return <div className={classes.column}>
        {sectionData.map((item: any) => (
            <div key={item.id}>
                {handleInputRender(item)}
            </div>
        ))}
    </div>
}

const getDropdownItems = (products: Product[] | undefined) => {
    return products?.map((product, index) => ({
        key: String(index + 1),
        label: (
            <a style={{textDecoration: 'none'}}>
                {product.name}
            </a>
        ),
    }));
};



interface MaterialsPreviewProps {
    materials: SectionInputStateType["materials"]["data"]
}

const MaterialsPreview: React.FC<MaterialsPreviewProps> = ({materials}) => {


    const materialsData = materials.map(el=>el.data as FormProductList )

    const productType= materialsData?.find((item: any) => item.inputType === "productList")

    const {data: productList, isLoading} = useQuery({
        queryFn: () => getProductListById(productType?.type ?? ""),
        queryKey: ["productList", productType?.type],
        enabled: !!productType, // Only fetch if productType exists
    });

    const {handleInputRender} = useUtils()


    if (isLoading) {
        return <Spin/>
    }

    const dropdownItems = getDropdownItems(productList);


    return <div className={classes.column}>
        {materialsData.map((item: any) => (
            <div key={item.id}>
                {handleInputRender(item, dropdownItems)}
            </div>
        ))}
    </div>
}