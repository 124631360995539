import React from 'react';
import SectionItem from "./SectionItem";
import {SectionEnum} from "../../../types/types";




const  SectionsList = () => {

    const sections = [
        SectionEnum.Heading,
        SectionEnum.Materials,
        SectionEnum.Expenses,
    ]

    return(
        <div>
            {sections.map((section) => (
                <SectionItem key={section} section={section}/>
            ))}
        </div>

    )
};

export default SectionsList;