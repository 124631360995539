import axios from "axios";


const axiosInstance = axios.create({
    baseURL:  process.env.REACT_APP_ROOT_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
})

export default function getAxiosInstance  () {
    return axiosInstance
}
