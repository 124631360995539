import getAxiosInstance from "./axiosInstance";


const client = getAxiosInstance();

export const getAutoCompleteGroups = async (groupName: string= " ") => {
    try {
       const res = await client.get(`/groups/autocomplete/${groupName}`)
        return res.data;
    }catch (e:any){
         throw Error(e.message);
    }


};

export const getAutoCompleteUsers = async (userName: string= " ") => {
    try {
        const res = await client.get(`/users/autocomplete/${userName}`)
        return res.data;
    }catch (e:any){
        throw Error(e.message);
    }
};

export const getAutoCompleteCompanies = async (companyName: string= " ") => {
    try {
        const res = await client.get(`/companies/autocomplete/${companyName}`)
        return res.data;
    }catch (e:any){
        throw Error(e.message);
    }
};

export const getAutoComplete = async (term: string = " ") => {
    try {
        const userList = await getAutoCompleteUsers(term)
        const groupList = await getAutoCompleteGroups(term)

        return [ ...userList.map((user:any) => ({...user,type:'user'})),...groupList.map((user:any) => ({...user,type:'group'}))]

    }catch (e:any){
        throw Error(e.message);
    }
}

