import React from "react";
import {Input, Select, DatePicker, Button} from "antd";
import TextArea from "antd/es/input/TextArea";
import classes from "../../styles.module.css"
import dayjs from "dayjs";
import {GlobalOutlined} from "@ant-design/icons";
import {useAuth} from "../../../../context/AuthProvider";

interface DynamicInputProps {
    item: {
        inputType: string;
        label: string;
        name: string;
        value: any;
        suffix?: React.ReactNode;
        maxLength?: number;
        isRequired?: boolean;
        roles: string[]
        type?: string;
        options?: Array<{ label: string; value: string | number }>;
    };
    disabled?: boolean
}


const DynamicInputRenderer: React.FC<DynamicInputProps> = ({item, disabled}) => {

    const {state} = useAuth()

    const userRoles = state?.userData?.roles || [];
    const isRolesEmpty = item?.roles === undefined || item.roles.length === 0
    const hasAccess = isRolesEmpty || userRoles.some((userRole: any) => item.roles.includes(userRole.id))

    const renderInput = () => {
        switch (item.inputType) {
            case "input":
                return (
                    <>
                        <label className={classes.labelStyle}>{item.label} {item.isRequired &&
                            <span style={{color: "red"}}>*</span>}</label>
                        <Input
                            suffix={item.suffix}
                            disabled={!hasAccess || disabled}
                            maxLength={item.maxLength}
                            defaultValue={item.value}
                            type={item.type}
                            name={item.name}
                            onChange={(e) => {
                                item.value = e.target.value; // if you need to update the underlying item too
                            }}
                        />
                    </>
                );
            case "textarea":
                return (
                    <>
                        <label className={classes.labelStyle}>{item.label} {item.isRequired &&
                            <span style={{color: "red"}}>*</span>}</label>
                        <TextArea
                            disabled={!hasAccess || disabled}
                            name={item.name}
                            defaultValue={item.value}
                            onChange={(e) => {
                                item.value = e.target.value;
                            }}
                            autoSize
                        />
                    </>
                );
            case "select":
                return (
                    <>
                        <label className={classes.labelStyle}>{item.label} {item.isRequired &&
                            <span style={{color: "red"}}>*</span>}</label>
                        <Select
                            disabled={!hasAccess || disabled}
                            style={{width: "100%"}}
                            placeholder="Select option"
                            defaultValue={item.value}
                            options={item.options}
                            onChange={(value) => {
                                item.value = value;
                            }}
                        />
                    </>
                );
            case "date":
                return (
                    <>
                        <label className={classes.labelStyle}>{item.label} {item.isRequired &&
                            <span style={{color: "red"}}>*</span>}</label>
                        <DatePicker
                            disabled={!hasAccess || disabled}
                            style={{width: "100%"}}
                            defaultValue={item.value ? dayjs(item.value) : dayjs()}
                            name={item.name}
                            onChange={(date, dateString) => {
                                item.value = dateString;
                            }}
                        />
                    </>
                );
            case "location":
                return <>
                    <label className={classes.labelStyle}>{item.label} {item.isRequired &&
                        <span style={{color: "red"}}>*</span>}</label>
                    <Input defaultValue={item.value} name={item.name} onChange={(e) => {
                        item.value = e.target.value
                    }}/>
                    <Button className={classes.locationButton} icon={<GlobalOutlined/>}>Get
                        my location</Button>
                </>
            default:
                return null;
        }
    };

    return (
        <div style={{marginBottom: 16}}>
            {renderInput()}
        </div>
    );
};

export default DynamicInputRenderer;
