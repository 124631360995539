import React from 'react';
import classes from "./style.module.css"
import {useQuery} from "@tanstack/react-query";
import {Skeleton} from "antd";
import {getMyTasks} from "../../services/tasks";
import TasksMain from "./TasksMain";
import PageNotFound from "../not_found/PageNotFound";


const TasksPage = () => {

    const {data: tasks, isLoading} = useQuery({
        queryFn: () => getMyTasks(),
        queryKey: ["tasks"]
    })

    if (isLoading) {
        return <div className={classes.taskDashboard}>
            <Skeleton active className={classes.skeleton}/>
        </div>
    }

    if (!tasks) return <PageNotFound/>

    return <TasksMain tasks={tasks}/>
};

export default TasksPage;