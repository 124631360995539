import React from 'react';
import {Tag} from "antd";
import classes from "../styles.module.css";
import {Role} from "../../../types/types";

interface RolesListProps {
    roles: Role[]
}

const RolesList: React.FC<RolesListProps> = ({roles}) => {
    return <div className={classes.tagContainer}>
        {roles.map((role, index) => {
            if (index < 4) {
                return (
                    <Tag key={index} color={role.claimId ? "green" : "default"}>
                        {role.roleName}
                    </Tag>
                );
            } else if (index === 4) {
                return (
                    <Tag key="more" color="blue">
                        + {Math.abs(roles.length - 4)} more
                    </Tag>
                );
            }
            return null;
        })}
    </div>
};

export default RolesList;