import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import FullScreenDropdown from "../../pages/work-orders/components/FullScreenDopdown";
import {FormProduct} from "../../types/inputs";
import {v4 as uuidv4} from "uuid";
import {Addon, Product} from "../../types/types";

interface ChooseProductInputProps {
    dropdownItems: { value: string, key: string, label: React.ReactNode, product: Product | Addon }[] | undefined
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    setProductInputAddons?:  (productInputAddons: (prev: FormProduct[]) => FormProduct[]) => void
    productInput?: FormProduct
    isAddon?: boolean
    placeholder: string
}



const ChooseProductInput: React.FC<ChooseProductInputProps> = ({dropdownItems, setProductInputs, setProductInputAddons, isAddon, productInput, placeholder}) => {

    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleSelectChange = (value: string) => {

        if (!dropdownItems) return; // Ensure dropdownItems is not undefined

        const product = dropdownItems.find((item) => item.value === value);

        if (!product) return;

        const newInput: FormProduct = {
            productId: product.product?.id ?? product.key, // Prefer real ID if available
            quantity: product.product.serialNumber ? "1" : "1",
            itemNumber: uuidv4(),
            productName: value, // Use value instead of label
            description: "",
            ...(isAddon ? { parentItemNumber: productInput?.itemNumber } : {}),
            units: product.product.units ?? "", // Ensure `units` exists
            ...(product.product?.serialNumber ? { serialNumber: "" } : {}),
        };

        setProductInputs((prevInputs) => [...prevInputs, { ...newInput }]);

        if(isAddon) {
            setProductInputAddons!((prevInputs) => [...prevInputs, { ...newInput }]);
        }

        setSelectedValue(null);
    };


    return (
        <>
            {/*<h3 style={{ margin: "0" }}>Choose a product</h3>*/}
            {isMobile ? (
                <FullScreenDropdown dropdownItems={dropdownItems} title={"Add product"} />
            ) : (
                <Select
                    showSearch
                    style={{ width: "100%", maxWidth: "100%" }}
                    placeholder={placeholder}
                    filterSort={(optionA, optionB) =>
                        //@ts-ignore
                        (optionA?.value ?? "").toLowerCase().localeCompare((optionB?.value ?? "").toLowerCase())
                    }
                    options={dropdownItems}
                    value={selectedValue}
                    onChange={(value) => handleSelectChange(value)} // Only on regular selection (no keypress)
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            handleSelectChange(selectedValue || ""); // Trigger only on Enter key
                        }
                    }}
                />
            )}
        </>
    );
};

export default ChooseProductInput;