// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_row__kAfOG {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 10px; /* Adjust spacing between items */
    align-items: center; /* Keeps items aligned */
}

.style_search__1DV5L {
    width: 20%; /* 50% on larger screens */
    min-width: 200px; /* Prevents shrinking too much */
}


.style_buttonsContainer__rZ2za {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.style_buttons__sW\\+5b {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.style_assignButton__vosRS {
    justify-content: end;
    display: flex;
}

/*.buttonsContainer {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap:10px;*/
/*    width: 50%;*/
/*}*/

/*.buttons {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap:10px;*/
/*    width: 100%;*/
/*}*/

.style_clearButton__C6snh {
    max-width: 50%;
}

/* Media query for mobile devices with maximum width of 600px */
@media only screen and (max-width: 600px) {
    .style_search__1DV5L {
        width: 100%; /* Full width on mobile */
    }
    .style_row__kAfOG {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/work-orders/components/workOrderFilters/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe,EAAE,0CAA0C;IAC3D,SAAS,EAAE,iCAAiC;IAC5C,mBAAmB,EAAE,wBAAwB;AACjD;;AAEA;IACI,UAAU,EAAE,0BAA0B;IACtC,gBAAgB,EAAE,gCAAgC;AACtD;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,aAAa;AACjB;;AAEA,sBAAsB;AACtB,qBAAqB;AACrB,2BAA2B;AAC3B,gBAAgB;AAChB,kBAAkB;AAClB,IAAI;;AAEJ,aAAa;AACb,qBAAqB;AACrB,2BAA2B;AAC3B,gBAAgB;AAChB,mBAAmB;AACnB,IAAI;;AAEJ;IACI,cAAc;AAClB;;AAEA,+DAA+D;AAC/D;IACI;QACI,WAAW,EAAE,yBAAyB;IAC1C;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,eAAe;IACnB;AACJ","sourcesContent":[".row {\n    display: flex;\n    flex-wrap: wrap; /* Allows items to wrap to the next line */\n    gap: 10px; /* Adjust spacing between items */\n    align-items: center; /* Keeps items aligned */\n}\n\n.search {\n    width: 20%; /* 50% on larger screens */\n    min-width: 200px; /* Prevents shrinking too much */\n}\n\n\n.buttonsContainer {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.buttons {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.assignButton {\n    justify-content: end;\n    display: flex;\n}\n\n/*.buttonsContainer {*/\n/*    display: flex;*/\n/*    flex-direction: row;*/\n/*    gap:10px;*/\n/*    width: 50%;*/\n/*}*/\n\n/*.buttons {*/\n/*    display: flex;*/\n/*    flex-direction: row;*/\n/*    gap:10px;*/\n/*    width: 100%;*/\n/*}*/\n\n.clearButton {\n    max-width: 50%;\n}\n\n/* Media query for mobile devices with maximum width of 600px */\n@media only screen and (max-width: 600px) {\n    .search {\n        width: 100%; /* Full width on mobile */\n    }\n    .row {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `style_row__kAfOG`,
	"search": `style_search__1DV5L`,
	"buttonsContainer": `style_buttonsContainer__rZ2za`,
	"buttons": `style_buttons__sW+5b`,
	"assignButton": `style_assignButton__vosRS`,
	"clearButton": `style_clearButton__C6snh`
};
export default ___CSS_LOADER_EXPORT___;
