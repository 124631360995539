import React from 'react';
import classes from "../styles.module.css"
import Clipboard from "../UI/Clipboard";
import {useTranslation} from "react-i18next";

interface IDetailsRowProps {
    label: string
    data: string | number | undefined | React.ReactElement
    section: string
    products: any
}

const DetailsRow: React.FC<IDetailsRowProps> = ({label, data, products}) => {

    const {t} = useTranslation()

    if (label === "Choose a product type") {
        return (
            <div className={classes.productContainer}>
                <div className={classes.productColumn}>
                    {products && products.length > 0 ? (
                        <>
                            <label style={{fontWeight: "bold", textTransform: "capitalize"}}>{t("work_order_details.products_installed")} </label>
                            <ul>
                                {products?.reverse().map((i: any) => (
                                    <div key={i.itemNumber}>
                                        <li style={{fontWeight: "bold"}}>{i.productName}</li>
                                        {i.description && <div style={{ whiteSpace: "pre-wrap" }}>{t("work_order_details.description")}: {i.description}</div>}
                                        { i.serialNumber || i.serialNumber === "" ? (
                                            <>
                                                {i.quantity === 0 && <div style={{color: "#4096FF", textTransform:"capitalize"}}>{t("product_actions.servis")}</div>}
                                                {i.quantity === 1 && <div  style={{color: "#4096FF",textTransform:"capitalize"}}>{t("product_actions.install")}</div>}
                                                {i.quantity === -1 && <div  style={{color: "#4096FF",textTransform:"capitalize"}}>{t("product_actions.uninstall")}</div>}
                                            </>
                                        ) : (
                                            i.quantity || i.quantity === 0 && <div>{t("product_actions.quantity")}: {i.quantity}</div>
                                        )
                                        }
                                        {i.serialNumber && <div>{t("work_order_details.serial_number")}: {i.serialNumber} <Clipboard text={i.serialNumber}/></div>}
                                    </div>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <span>{t("work_order_details.no_products")}</span>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <label style={{fontWeight: "bold", textTransform: "capitalize"}}>{label}: </label>
            <div style={{whiteSpace:"pre-wrap"}}>{data ? data : "Currently no data"}</div>
        </>
    );
};

export default DetailsRow;