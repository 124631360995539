import React, {useState} from 'react';
import classes from "../../style.module.css";
import {AutoComplete} from "antd";
import {SearchOutlined, ShopOutlined} from "@ant-design/icons";
import {getAutoCompleteCompanies} from "../../../../services/autocomplete";
import {useQuery} from "@tanstack/react-query";

interface ICompanyAutocompleteProps {
    setAssignedCompany: (companies: any) => void
}

const CompanyAutocomplete: React.FC<ICompanyAutocompleteProps> = ({setAssignedCompany}) => {

    const [term, setTerm] = useState("");

    const handleListQuery = ({queryKey}: { queryKey: string[] }) => {
        const [, newTerm] = queryKey;
        if (newTerm === "") return []
        return getAutoCompleteCompanies(newTerm);
    }

    const autoCompleteQuery = useQuery({queryKey: ['autoCompleteList', term], queryFn: handleListQuery})

    const handleSelect = (value: string, option: any) => {
        setAssignedCompany({id: value, name: option.companyName})
        setTerm("")
    }

    const autoCompleteList = autoCompleteQuery.data?.map((option: any) => {
            return {
                value: option.id,
                companyName: option.name,
                label: <div className={classes.row}> {option.name} <ShopOutlined/></div>
            }
        }
    )

    return (
        <div className={classes.assignContainer}>
            <AutoComplete
                suffixIcon={<SearchOutlined/>}
                options={autoCompleteList ?? []}
                onChange={(newValue: string) => {
                    setTerm(newValue)
                }}
                style={{width: "100%"}}
                value={term}
                onSelect={handleSelect}
                aria-errormessage={autoCompleteQuery.error?.message}
                placeholder="assign user or group"
            />
        </div>
    );
};

export default CompanyAutocomplete;