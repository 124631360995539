import React, {createContext, useContext, useEffect, useReducer} from "react";
import {UserDataType} from "../types/types";
import getAxiosInstance from "../services/axiosInstance";
import {jwtDecode} from "jwt-decode";


interface AuthState {
    userData: UserDataType | undefined,
    token: string | undefined
}

type SET_TOKEN_ACTION = {
    type: 'SET_TOKEN_ACTION',
    payload: string | undefined
}

type SET_USERDATA_ACTION = {
    type: 'SET_USER_DATA',
    payload: UserDataType | undefined
}

type AuthAction = SET_TOKEN_ACTION | SET_USERDATA_ACTION;


const authReducer = (state: AuthState, action: AuthAction) => {
    const handlers: Record<string, (state: AuthState, action: AuthAction) => AuthState> = {
        SET_USER_DATA: (prevState, {payload}) => ({...prevState, userData: payload as UserDataType}),
        SET_TOKEN_ACTION: (prevState, {payload}) => ({...prevState, token: payload as string})
    };

    const handler = handlers[action.type];
    return handler ? handler(state, action) : state;
}


const AuthContext = createContext<{ state: AuthState, dispatch: React.Dispatch<AuthAction> } | undefined>(undefined);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {

    const storageToken = localStorage.getItem("token");
    let initTokenValue;
    let initUserDataValue;

    try {
        const decodedToken: any = jwtDecode(storageToken ?? "");
        initTokenValue = Date.now() >= decodedToken?.exp * 1000 ? undefined : storageToken
        initUserDataValue = Date.now() >= decodedToken?.exp * 1000 ? undefined : decodedToken;
    } catch (e) {
        console.log("Wrong token")
    }

    const initAuthState: AuthState = {
        userData: initUserDataValue,
        token: initTokenValue as string,
    }

    const [state, dispatch] = useReducer(authReducer, initAuthState);

    return (
        <AuthContext.Provider value={{state, dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}

const client = getAxiosInstance();
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within the AuthProvider');
    }
    const token = context.state.token


    useEffect(() => {

        if (token) {
            client.defaults.headers['Authorization'] = "Bearer " + token;
            localStorage.setItem("token", token);
        } else {

            delete client.defaults.headers['Authorization']
            localStorage.removeItem("token");
        }


    }, [token]);


    return context
}

export default AuthProvider;


