import React from "react";
import { Button, Dropdown, Input, Select, Tag } from "antd";
import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    ExclamationOutlined,
    PlusOutlined,
    ToolOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { DatePicker } from "antd/lib";
import { useTranslation } from "react-i18next";
import { ITaskPriority, ITaskStatus, ITaskType, taskTypes } from "../types/types";

export const useUtils = () => {
    const { t } = useTranslation();

    // Create a set of exclamation icons based on a given color and number of times
    const colorMarks = (color: string, times: number) => {
        const elements: React.ReactElement[] = [];
        for (let i = 0; i < times; i++) {
            elements.push(
                <ExclamationOutlined key={i} style={{ color: color }} />
            );
        }
        return elements;
    };

    // Truncates text based on line and character limits, with different behavior for mobile
    const truncateText = (
        text: string,
        length: number,
        isMobile: boolean
    ) => {
        const lines = text.split("\n");
        const limitedLines = lines.slice(0, isMobile ? 1 : 2);

        if (
            limitedLines.length > 0 &&
            limitedLines[limitedLines.length - 1].length > length
        ) {
            limitedLines[limitedLines.length - 1] =
                limitedLines[limitedLines.length - 1].substring(0, length) + "...";
        }

        return limitedLines.join("\n");
    };

    // Define task statuses with corresponding Ant Design Tags and translations
    const TASK_STATUS: ITaskStatus = {
        inProgress: (
            <Tag style={{ textTransform: "uppercase" }} color="orange">
                {t("task_status.in_progress")}
            </Tag>
        ),
        beingDelivered: (
            <Tag style={{ textTransform: "uppercase" }} color="blue">
                {t("task_status.being_delivered")}
            </Tag>
        ),
        done: (
            <Tag style={{ textTransform: "uppercase" }} color="green">
                {t("task_status.done")}
            </Tag>
        ),
    };

    // Define task priorities with a visual representation (exclamation marks)
    const TASK_PRIORITY: ITaskPriority = {
        low: <>{colorMarks("#389E0D", 1)}</>,
        medium: <>{colorMarks("#ffcc00", 2)}</>,
        high: <>{colorMarks("red", 3)}</>,
    };

    // Define task types with corresponding icons
    const TASK_TYPE: ITaskType = {
        installation: <DoubleLeftOutlined />,
        uninstallation: <DoubleRightOutlined />,
        maintenance: <ToolOutlined />,
    };

    // Utility function to check if a file is an image based on its extension
    const isImageFile = (fileName: string): boolean => {
        const validImageExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "bmp",
            "webp",
        ];
        const fileExtension = fileName.split(".").pop()?.toLowerCase();
        return validImageExtensions.includes(fileExtension || "");
    };

    // Getters to return a React Element based on a key for status, priority, or type
    const getStatus = (status: keyof ITaskStatus): React.ReactElement | undefined => {
        return TASK_STATUS[status];
    };

    const getPriority = (priority: keyof ITaskPriority): React.ReactElement | undefined => {
        return TASK_PRIORITY[priority];
    };

    const getType = (type: keyof ITaskType): React.ReactElement | undefined => {
        return TASK_TYPE[type];
    };

    // Format a given date string into a specific locale format
    const formatDate = (date: string | undefined) => {
        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
        };

        //@ts-ignore
        return new Intl.DateTimeFormat("hr", options).format(new Date(date || ""));
    };

    // This is a placeholder for a highlighter component (to be implemented as needed)
    const Highlighter = (props: {
        searchWords: string[];
        autoEscape: boolean;
        textToHighlight: string;
        highlightStyle: { padding: number; backgroundColor: string };
    }) => {
        return null;
    };

    // Returns data for selects based on the task type (status, priority, or task type)
    const getTypeData = (type: taskTypes) => {
        switch (type) {
            case 0:
                return [
                    { value: "inProgress", label: t("task_status.in_progress") },
                    { value: "beingDelivered", label: t("task_status.being_delivered") },
                    { value: "done", label: t("task_status.done") },
                ];
            case 1:
                return [
                    { value: "low", label: t("priority.low") },
                    { value: "medium", label: t("priority.medium") },
                    { value: "high", label: t("priority.high") },
                ];
            case 2:
                return [
                    { value: "installation", label: t("task_type.installation") },
                    { value: "uninstallation", label: t("task_type.uninstallation") },
                    { value: "maintenance", label: t("task_type.maintenance") },
                ];
            default:
                console.log("No correct type given");
                return [];
        }
    };

    // Render various input types based on the configuration passed in the "item" parameter
    const handleInputRender = (item: any, dropdownItems?: any) => {
        switch (item.inputType) {
            case "input":
                return (
                    <>
                        <br />
                        <label
                            style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                            }}
                        >
                            {item.label}
                        </label>
                        <Input
                            suffix={item.suffix}
                            maxLength={item.maxLength}
                            type={item.type}
                            name={item.name}
                        />
                    </>
                );
            case "textarea":
                return (
                    <>
                        <br />
                        <label
                            style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                            }}
                        >
                            {item.label}
                        </label>
                        <TextArea name={item.name} autoSize />
                    </>
                );
            case "select":
                return (
                    <>
                        <br />
                        <label
                            style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                            }}
                        >
                            {item.label}
                        </label>
                        <br />
                        <Select
                            style={{ width: "100%" }}
                            defaultValue={item.options[0]}
                            options={item.options}
                        />
                    </>
                );
            case "date":
                return (
                    <>
                        <br />
                        <label
                            style={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                            }}
                        >
                            {item.label}
                        </label>
                        <br />
                        <DatePicker style={{ width: "100%" }} name={item.name} />
                    </>
                );
            case "productList":
                return (
                    <>
                        <h3>{t("inputs.choose_product")}</h3>
                        <Dropdown menu={{ items: dropdownItems }} placement="bottom">
                            <Button style={{ width: "100%" }} icon={<PlusOutlined />}>
                                {t("inputs.choose_product")}
                            </Button>
                        </Dropdown>
                    </>
                );
            default:
                return null;
        }
    };


    // Options for product actions with disabled states (if needed)
    const optionsWithDisabled = [
        { label: t("product_actions.install"), value: "1" },
        { label: t("product_actions.servis"), value: "0" },
        { label: t("product_actions.uninstall"), value: "-1" },
    ];

    return {
        colorMarks,
        truncateText,
        isImageFile,
        getStatus,
        getPriority,
        getType,
        formatDate,
        Highlighter,
        getTypeData,
        handleInputRender,
        optionsWithDisabled,
    };
};

export default useUtils;
