import {createContext, Dispatch, ReactNode, useContext, useMemo, useReducer} from "react";
import {
    SectionInputActionType,
    sectionInputReducer,
    SectionInputStateType
} from "./SectionInputReducer";
import {SectionEnum} from "../../../types/types";
import {
    FormInputTypesWithId,
    InputTypesEnum
} from "../../../types/inputs";
import {v4 as uuidv4} from "uuid";
import {createInput} from "./sectionUtils";


interface SectionInputProviderProps {
    children: ReactNode;
}

interface SectionInputContextType {
    state: SectionInputStateType,
    dispatch: Dispatch<SectionInputActionType>
}


const SectionInputContext = createContext<SectionInputContextType | undefined>(undefined)


const initProductList = {
    type: InputTypesEnum.ProductList,
    data: createInput(InputTypesEnum.ProductList),
    id: uuidv4()
} as FormInputTypesWithId

export const initSectionContextState: SectionInputStateType = {
    [SectionEnum.Heading]: {
        data: []
    },
    [SectionEnum.Materials]: {
        data: [initProductList]
    },
    [SectionEnum.Expenses]: {
        data: []
    },
}



export const SectionInputProvider = ({children}: SectionInputProviderProps) => {
    const [state, dispatch] = useReducer(sectionInputReducer, initSectionContextState)
    const contextValue = useMemo(()=>({state,dispatch}), [state, dispatch])


    return (
        <SectionInputContext.Provider value={contextValue}>
            {children}
        </SectionInputContext.Provider>
    )
}

export const useSectionInputContext = () => {
    const context = useContext(SectionInputContext)

    if (!context) {
        throw new Error('useSectionInputContext must be used within a SectionInputProvider')
    }
    return context
}