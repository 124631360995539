import getAxiosInstance from "./axiosInstance";
import genericService from "./genericService";
import {Task, TaskDetails, TaskLog} from "../types/types";


const client = getAxiosInstance();

interface IAssignTaskRequestBody {
    taskId: string | undefined,
    userIds: string[],
    groupsId: string[]
}

interface IUpdateTaskRequestBody {
    title: string
    text: string
    status: string
    priority: string
    taskKind: string
}


interface ICreateTaskRequestBody {
    title: string
    text: string
    status: string
    priority: string
    taskKind: string
}

export const getMyTasks = async () => {
    return await genericService.genericGet<Task[]>("/task/list-my-tasks")
};

export const getTaskDetails = async (id: string | undefined) => {
    return await genericService.genericGet<TaskDetails>(`/task/${id}`);
};

export const getTaskHistory = async (id: string | undefined) => {
    return await genericService.genericGet<TaskLog[]>(`/task/task-log/${id}`);
};


export const assignTask = async (taskId: string | undefined, userIds: string[], groupsId: string[]) => {

    const reqBody: any = {}

    if (userIds.length > 0) {
        reqBody['userIds'] = userIds;
    }
    if (groupsId.length > 0) {
        reqBody['groupsId'] = groupsId;
    }

    return await genericService.genericPut<IAssignTaskRequestBody, TaskDetails>(
        `/task/assign-users-groups/${taskId}`,
        reqBody
    )
};

export const createTask = async (task: ICreateTaskRequestBody) => {

    return await genericService.genericPost<ICreateTaskRequestBody, Task>(
        `/task`,
        task
    )
};


export const updateTask = async (taskId: string | undefined, task: IUpdateTaskRequestBody) => {

    return await genericService.genericPut<IUpdateTaskRequestBody, TaskDetails>(
        `/task/update/${taskId}`,
        task
    )
};

export const unAssignTask = async (taskId: string | undefined, userIds: string[], groupsId: string[]) => {

    const reqBody: any = {}

    if (userIds.length > 0) {
        reqBody['userIds'] = userIds;
    }
    if (groupsId.length > 0) {
        reqBody['groupsId'] = groupsId;
    }

    try {
        const res = await client.delete(`/task/unassign-users-groups/${taskId}`, {data: {...reqBody}});
        return res.data

    } catch (error: string | any) {
        throw new Error(error.response.data.message);
    }
};