import React from 'react';
import {Control, Controller, FieldErrors} from "react-hook-form";
import {Alert, Input, InputProps} from "antd";
import classes from "./style.module.css";
import {isEmpty} from "lodash";


interface ICustomFormInputProps  {
    control: Control | any;
    label: string;
    name: string
    errors: FieldErrors | any
    inputProps?: InputProps
    labelClassName?: string

}

const CustomFormInput: React.FC<ICustomFormInputProps> = ({control,labelClassName, label, name, errors,inputProps}) => {

    return (
        <>
            <label className={labelClassName? labelClassName: ""}>{label}: </label>
            <Controller
                name={name}
                control={control}
                rules={{required: true}}
                render={({field}) => <Input   {...field} {...inputProps}  />
                }
            />
            {!isEmpty(errors) && <Alert className={classes.fieldAlert} type={"error"} message={errors?.message}/>}
        </>
    );
};

export default CustomFormInput;