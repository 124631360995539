import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getTaskDetails} from "../../../services/tasks";
import {Skeleton} from "antd";
import {useParams} from "react-router-dom";
import classes from "../style.module.css";
import PageNotFound from "../../not_found/PageNotFound";
import TaskSectionMain from "./TaskSectionMain";


const TaskDetailsPage = () => {

    const params = useParams()

    const {data: task, isLoading} = useQuery({
        queryFn: () => getTaskDetails(params?.id),
        queryKey: ["taskDetails", params.id],
    })

    if (isLoading) return <div className={classes.taskDashboard}>
        <Skeleton active className={classes.skeleton}/>
    </div>

    if (!task) return <PageNotFound/>

    return <TaskSectionMain task={task}/>
};

export default TaskDetailsPage;