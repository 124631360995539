import React from 'react';
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

const PageNotFound = () => {

    const navigate = useNavigate()

    const handleGoHome = () => {
        navigate("/dashboard")
    }

    return (
        <Result
            status="404"
            title="Page not found"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" onClick={handleGoHome}>Back Home</Button>}
        />
    );
};

export default PageNotFound;