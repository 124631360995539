import React, {useEffect, useState} from 'react';
import classes from "./styles.module.css";
import {Alert, Button, Card, Divider, Skeleton, Steps} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    assignProductsInstalled,
    getProductInstalledByWorkOrder,
    getWorkOrderDetails,
    updateWorkOrder
} from "../../services/workOrders";
import {Product, WorkOrder} from "../../types/types";
import StepsFooter from "./components/StepsFooter";
import {getProductListById} from "../../services/products";
import StepChooser from "./steps/StepChooser";
import {stepTitles} from "../../utils/globalFunctions";
import {FormProduct} from "../../types/inputs";
import {v4 as uuidv4} from "uuid";

const WorkOrderEditPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState<string[]>([]);
    const [apiError, setApiError] = useState("");
    const [currentStep, setCurrentStep] = useState(0);

    const id = params.id ?? ""

    const {data: productsInstalled} = useQuery({
        queryFn: () => getProductInstalledByWorkOrder(id),
        queryKey: ["productsInstalled", params.id]  // Include params.id to make the query key unique per work order
    })

    const [productInputs, setProductInputs] = useState<FormProduct[]>([]);

    useEffect(() => {
        setProductInputs(productsInstalled?.products?.map((p:any)=>({...p,productId: p.id})) ?? [])
    }, [productsInstalled?.products]);


    const {data: workOrderDetails, isLoading} = useQuery({
        queryFn: () => getWorkOrderDetails(id),
        queryKey: ["workOrderDetails", params.id]  // Include params.id to make the query key unique per work order
    });


    const mutation = useMutation<WorkOrder, Error, { id: string, order_template: any }>({
        mutationFn: ({order_template}) => updateWorkOrder(workOrderDetails?.id ?? "", order_template),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', params.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', params.id]}).catch(console.error);
            setError([]);
            setApiError("")
        },
        onError: (error) => {
            setApiError(error.message);
        }
    });

    const updateProductsMutation = useMutation<any, Error, { id: string, products: FormProduct[] }>({
        mutationFn: ({}) => assignProductsInstalled(workOrderDetails?.id ?? "", productInputs),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['workOrderDetails', params.id]}).catch(console.error);
            queryClient.invalidateQueries({queryKey: ['workOrderHistory', params.id]}).catch(console.error);
            setError([]);
            setApiError("")
        },
        onError: (error) => {
            setApiError(error.message);
        }
    });


    useEffect(() => {
        if (mutation.status === "success" && (currentStep === 0 || currentStep === 2 || updateProductsMutation.status === "success")) {
            if (currentStep === 3) {
                return navigate(`/work_orders/${params.id}`);
            }
            setCurrentStep(currentStep + 1);
        }
    }, [updateProductsMutation.status, mutation.status]);


    const productType = workOrderDetails?.order_template?.schemaContent?.materials?.find((item: any) => item.inputType === "productList");

    const {data: productList, isLoading: isLoadingProducts} = useQuery({
        queryFn: () => getProductListById(productType?.type),
        queryKey: ["productList", productType?.type],  // Include productType?.type in the queryKey
        enabled: !!productType?.type  // Only fetch when productType?.type is valid
    });

    const handleSelectProduct = (product: Product) => {

        const newInput: FormProduct = {
            productId: product.id,
            quantity: product.serialNumber ? "1" : "",
            itemNumber: uuidv4(),
            productName: product.name,
            description: "",
            units: product.units,
            ...(product.serialNumber ? { serialNumber: "" } : {}),
        };

        setProductInputs(prev => {
            return [...prev, {...newInput}];
        });

    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getDropdownItems = (products: Product[] = []) => {
        return (Array.isArray(products) ? products : [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((product, index) => ({
                key: String(index + 1),
                value: product.name,
                product: product,
                label: (
                    <div
                        className={isMobile ? classes.mobileDropdownItem : classes.dropdownItem}
                        onClick={isMobile ? () => handleSelectProduct(product) : undefined}  // Only bind the function for mobile
                    >
                        {product.name}
                        {isMobile ? <Divider/> : <></>}
                    </div>
                ),
            }));
    };

    const dropdownItems = isLoadingProducts ? [] : getDropdownItems(productList);

    const inputValidation = () => {
        const errors: Set<string> = new Set(); // Initialize errors as a Set

        const schemaContent = workOrderDetails?.order_template?.schemaContent;

        // Ensure schemaContent exists and is an object
        if (schemaContent && typeof schemaContent === 'object') {
            // Iterate over each section in schemaContent
            Object.keys(schemaContent).forEach((sectionKey) => {
                const sectionItems = schemaContent[sectionKey];

                // Ensure each section is an array before iterating
                if (Array.isArray(sectionItems)) {
                    sectionItems.forEach((item: any) => {
                        if (item.isRequired && !item.value) {
                            const errorMessage = `${item.label} in ${sectionKey} is required but has no value.`;
                            errors.add(errorMessage); // Add error message to Set
                        }
                    });
                }
            });
        } else {
            // If schemaContent is not valid, push an appropriate error message
            errors.add("Invalid schema content structure.");
        }

        return Array.from(errors); // Convert Set back to an array
    };


    const productValidation = () => {
        const errors: Set<string> = new Set();  // Initialize an array to store errors

        // // Check if the productInputs array is empty
        if (productInputs.length === 0) {
            errors.add("Please add a product");
        }

        // Iterate over each product input to check individual fields
        productInputs.forEach((product) => {
            // Check if serialNumber exists and is empty
            if ('serialNumber' in product && !product.serialNumber) {
                errors.add(`Serial number is missing`);
            }
        });

        return Array.from(errors)
    }


    const isValid = () => {

        let productErrors: string[] = [];
        let inputErrors: string[] = [];

        if (currentStep === 3) {
            inputErrors = inputValidation()
            productErrors = productValidation()
        }

        const combinedErrors = [...productErrors, ...inputErrors];

        if (combinedErrors.length > 0) {
            setError(combinedErrors);
            return false
        }

        return true
    }

    const handleSaveWorkOrder = async () => {

        const valid = isValid()

        if (!valid) {
            return
        }

        if (currentStep === 1 || currentStep === 3) {

            //UPDATE PRODUCTS
            updateProductsMutation.mutate({
                id: workOrderDetails?.id ?? "",
                products: productInputs,
            })
        }

        mutation.mutate({
            id: workOrderDetails?.id ?? "",
            order_template: workOrderDetails?.order_template
        });

    };

    if (!workOrderDetails) return <h1>No work details</h1>;
    if (isLoading) return <Skeleton active={true}/>;

    const handleCancel = () => {
        navigate(`/work_orders/${params.id}`)
    }

    const stepChooserProps = {
        currentStep,
        productInputs,
        setProductInputs,
        dropdownItems,
        workOrderDetails,
    }

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <h2>Edit Work Order</h2>
                <Button onClick={handleCancel} danger>Cancel</Button>
            </div>
            <Steps
                size="small"
                current={currentStep}
                className={classes.steps}
                items={stepTitles}
            />
            <div className={classes.stepCard}>
                <br/>
                <Card style={{border: "2px solid #E6EFFC"}}>
                    <div className={classes.column}>
                        <StepChooser {...stepChooserProps}/>
                    </div>
                </Card>
                <br/>
                <StepsFooter currentStep={currentStep} setCurrentStep={setCurrentStep}
                             handleSaveWorkOrder={handleSaveWorkOrder}/>
                {error && error.map((e, idx) => <Alert key={idx} message={e} type={"error"}
                                                       className={classes.alert}/>)}
                {apiError && <Alert message={apiError} type={"error"} className={classes.alert}/>}
            </div>
        </div>
    );
};

export default WorkOrderEditPage;
