import React, {useState} from 'react';
import {Alert, Button, Modal} from "antd";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import classes from "./style.module.css";
import AssignedModalRow from "./AssignedModalRow";
import {Group, TaskDetails, User, WorkOrder} from "../../../../../../types/types";
import {assignTask} from "../../../../../../services/tasks";
import UsersGroupsAutocomplete from "../../UsersGroupsAutocomplete";
import {assignUsersGroupsWorkOrder} from "../../../../../../services/workOrders";


interface FooterProps {
    handleCancel: () => void,
    handleOk: () => void,
}

const ModalFooter: React.FC<FooterProps> = ({handleOk, handleCancel}) => {
    return <>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button key="submit" type="primary" onClick={handleOk}>
            Assign
        </Button>
    </>
}


interface Props {
    isModalOpen: boolean,
    setIsModalOpen: (newIsModalOpen: boolean) => void,
    taskId?: string
    workOrderId?: string
    users: User[]
    groups: Group[]
    type?: string
}

const AssignModal: React.FC<Props> = ({isModalOpen, setIsModalOpen, taskId, users, workOrderId, groups, type}) => {

    const [assignedUsers, setAssignedUsers] = useState<User[]>([]);
    const [assignedGroups, setAssignedGroups] = useState<Group[]>([]);
    const [error, setError] = useState("");
    const queryClient = useQueryClient()

    const handleRemoveUser = (userId: string) => {
        const newUsers = assignedUsers.filter((item: User) => item.id !== userId)
        setAssignedUsers(newUsers)
    }

    const handleRemoveGroup = (groupId: string) => {
        const newGroups = assignedGroups.filter((item: Group) => item.id !== groupId)
        setAssignedGroups(newGroups)
    }


    const mutation = useMutation<TaskDetails, Error, {
        id: string | undefined;
        userIds: string[],
        groupIds: string[]
    }>({

        mutationFn: ({id, userIds, groupIds}) => assignTask(id, userIds, groupIds),
        onSuccess: async () => {
            setIsModalOpen(false)
            setError("")
            queryClient.invalidateQueries({queryKey: ['taskDetails']}).catch(console.error)
            queryClient.invalidateQueries({queryKey: ['taskHistory']}).catch(console.error)
        },
        onError: (error) => {
            setError(error.message)
        },
    })

    const workOrderMutation = useMutation<WorkOrder, Error, {
        id: string | undefined;
        userIds: string[],
        groupIds: string[]
    }>({

        mutationFn: ({id, userIds, groupIds}) => assignUsersGroupsWorkOrder(id, userIds, groupIds),
        onSuccess: async () => {
            setIsModalOpen(false)
            setError("")
            queryClient.invalidateQueries({queryKey: ['workOrderDetails']}).catch(console.error)
            queryClient.invalidateQueries({queryKey: ['workOrderHistory']}).catch(console.error)
        },
        onError: (error) => {
            setError(error.message)
        },
    })


    const handleOk = () => {

        const userIds = assignedUsers.map((user: User) => user.id)
        const groupIds = assignedGroups.map((group: Group) => group.id)

        if (type === "work-order") {
            workOrderMutation.mutate({
                id: workOrderId,
                userIds,
                groupIds
            })
        } else {
            mutation.mutate({
                id: taskId,
                userIds,
                groupIds
            })
        }
    };

    const handleCancel = () => {
        setAssignedUsers([])
        setAssignedGroups([])
        setError("")
        setIsModalOpen(false);
    };

    return (
        <Modal maskClosable={false} onCancel={handleCancel} title="Assign users or groups to task"
               footer={<ModalFooter handleCancel={handleCancel} handleOk={handleOk}/>} open={isModalOpen}>
            <div className={classes.modalContainer}>
                <AssignedModalRow previousData={users} assignedData={assignedUsers} type={"user"}
                                  handler={handleRemoveUser}/>
                <AssignedModalRow previousData={groups} assignedData={assignedGroups} type={"group"}
                                  handler={handleRemoveGroup}/>
            </div>
            <UsersGroupsAutocomplete users={users} groups={groups} assignedUsers={assignedUsers}
                                     assignedGroups={assignedGroups}
                                     setAssignedUsers={setAssignedUsers} setAssignedGroups={setAssignedGroups}/>
            {error && <Alert type="error" className={classes.alert} message={error}/>}
        </Modal>
    );
};

export default AssignModal;