import genericService from "./genericService";
import {TaskLog, WorkOrder, WorkOrderDetails} from "../types/types";
import getAxiosInstance from "./axiosInstance";
import {FormProduct} from "../types/inputs";

interface IAssignRequestBody {
    groupsId: string,
    userIds: string
}


interface ProductsSchema {
    products: FormProduct[
        ],
    workOrderId: string
}

const client = getAxiosInstance();


export const getAllWorkOrders = async () => {
    return await genericService.genericGet<WorkOrder[]>("/workorder")
};

export const getMyWorkOrders = async () => {
    return await genericService.genericGet<WorkOrder[]>("/workorder/my-work-orders")
};

export const getWorkOrderDetails = async (id: string) => {
    return await genericService.genericGet<WorkOrderDetails>(`/workorder/${id}`)
};

export const getWorkOrderLogs = async (id: string) => {
    return await genericService.genericGet<TaskLog[]>(`/workorder/logs/${id}`)
};

export const getProductInstalledByWorkOrder = async (id: string) => {
    return await genericService.genericGet<any>(`/workorder/product_installed/${id}`)
};


export const deleteProductsInstalled = async (workOrderId: string, itemNumber: string) => {
    try {
        const res = await client.delete(`/workorder/product_installed`, {
            data: { workOrderId, itemNumber }  // Adding request body to the DELETE request
        });
        return res.data;
    } catch (error: string | any) {
        throw new Error(error.response?.data?.message || "An error occurred");
    }
};

export const assignProductsInstalled = async (id:string, products: FormProduct[]) => {

    const formattedData = products.map((i) => ({...i, quantity: Number(i.quantity) ?? null}))

    const updatedProducts = formattedData.map(({ productName, units, createdDate, primary, updatedDate, id, ...rest }) => rest);

    return await genericService.genericPut<ProductsSchema,any>(
        `/workorder/product_installed`,
        {
            products: updatedProducts as any,
            workOrderId: id
        }
    )
};

export const updateWorkOrderNotes = async (id: string, notes: any) => {
    return await genericService.genericPut<any, any>(
        `/workorder/${id}/notes`,
        {
            notes: notes
        }
    )
};


export const updateWorkOrder = async (id: string, template: any) => {

    return await genericService.genericPut<any, any>(
        `/workorder/${id}`,
        {
            order_template: template,
        }
    )
};

export const assignUsersGroupsWorkOrder = async (id: string, userIds: string[], groupIds: string[]) => {

    const reqBody: any = {
        workOrderId: id
    }

    if (userIds.length > 0) {
        reqBody['userIds'] = userIds;
    }
    if (groupIds.length > 0) {
        reqBody['groupIds'] = groupIds;
    }


    return await genericService.genericPut<IAssignRequestBody, WorkOrder>(
        `/workorder/assign-users-groups`,
        reqBody,
    )
};

export const unAssignWorkOrder = async (workOrderId: string, userIds: string[], groupIds: string[]) => {

    const reqBody: any = {
        workOrderId: workOrderId
    }

    if (userIds.length > 0) {
        reqBody['userIds'] = userIds;
    }
    if (groupIds.length > 0) {
        reqBody['groupIds'] = groupIds;
    }

    try {
        const res = await client.delete(`/workorder/unassign-users`, {data: {...reqBody}});
        return res.data

    } catch (error: string | any) {
        throw new Error(error.response.data.message);
    }
};

export const deleteWorkOrder = async (id:string) => {

    try {
        const res = await client.delete(`/workorder/${id}`);
        return res.data

    } catch (error: string | any) {
        throw new Error(error.response.data.message);
    }
};

export const createWorkOrder = async (template: any, templateId: string, workUnitId: string, notes: string) => {

    return await genericService.genericPost<any, any>(
        `/workorder`,
        {
            order_template: template,
            documentNumberingId: "69b55cd5-b1d5-4370-bee1-1d7b08e43c11",
            workUnitId: workUnitId,
            notes:notes
        }
    )
};


export const assignWorkOrderStatus = async (newStatusId: string, workOrderId: any) => {
    return await genericService.genericPut<any, any>(
        `/workorder/assign-status`,
        {
            newStatusId: newStatusId,
            workOrderId: workOrderId
        }
    )
};

export const claimWorkOrder = async (workOrderId: string, roleId: string, claimed: boolean) => {
    return await genericService.genericPut<any, any>(
        `/workorder/claim`,
        {
            workOrderId: workOrderId,
            roleId: roleId,
            claimed: claimed
        }
    )
};




