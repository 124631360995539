import React, {useEffect, useState} from 'react';
import {Alert, Badge, Button, Card, Popover, Tag} from "antd";
import classes from "../styles.module.css";
import {
    ArrowRightOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FormOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {WorkOrder} from "../../../types/types";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteWorkOrder} from "../../../services/workOrders";
import {useUtils} from "../../../utils/globalFunctions";
import RolesList from "./RolesList";
import {useTranslation} from "react-i18next";
import DynamicInputRenderer from "./inputCreators/DynamicInputRenderer";

interface IWorkItemProps {
    item: WorkOrder
}

const WorkOrderItem: React.FC<IWorkItemProps> = ({item}) => {

    const navigate = useNavigate();
    const queryClient = useQueryClient()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState("");
    const [dimension, setDimension] = useState(window.innerWidth);
    const {t} = useTranslation()

    const {formatDate} = useUtils()

    useEffect(() => {
        const handleResize = () => {
            setDimension(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteWorkOrder(id),
        onSuccess: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error)
        },
        onSettled: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error)
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    const actionButtons = [
        <Button type={"text"} onClick={() => navigate(`/work_orders/${item.id}/edit`)}
                icon={<EditOutlined/>}>{t("work_orders_page.edit")}</Button>,
        <Button type={"text"} onClick={() => {
            navigate(`/work_orders/${item.id}`)
        }}
                style={{color: "#4A9BFF"}} icon={<ArrowRightOutlined/>}>{t("work_orders_page.details")}</Button>,
        <Button type={"text"} style={{color: "red"}} onClick={() => setIsModalOpen(true)}
                icon={<DeleteOutlined/>}>{t("work_orders_page.delete")}</Button>
    ]

    const handleDeleteWorkOrder = (id: string) => {
        mutation.mutate({id});
    }

    // Define the truncation length based on screen size
    const getTruncateLength = () => {
        if (dimension <= 480) {
            return 25; // mobile
        } else if (dimension <= 768) {
            return 25; // medium screen
        } else {
            return 45; // large screen
        }
    };
    const hasEmptyValue = item.customData && item.customData.some((item: any) => !item.value);

    return (
        <Card style={{borderColor: item.statusColor}} className={classes.wordOrderCard} actions={actionButtons}>
            <div className={classes.wordOrderTitle}>
                {item.title}
                {item.statusName && <Tag className={classes.tag} color={item.statusColor}>{item.statusName}</Tag>}
            </div>
            <div>
                {formatDate(item.createdDate)}
            </div>
            {item.notes && <span><FormOutlined/> <span
                style={{
                    fontSize: "18px",
                    whiteSpace: "pre-wrap",
                    margin: "10px 0"
                }}>{truncateText(item.notes, getTruncateLength())}</span></span>}
            <RolesList roles={item.roles}/>
            {item.customData && <div>
                <Popover content={<>{item.customData.map((item: any) => (
                    <DynamicInputRenderer disabled={true} item={item} key={item.id}/>
                ))}</>}>
                    <div style={{display: "flex", alignItems: "center", marginLeft:"-13px"}}>
                        <Button type="text">{t("work_order_details.input_extra")} ({item.customData.length})</Button>
                        {hasEmptyValue && (
                            <span style={{alignItems: "center", display: "flex"}}><ExclamationCircleOutlined
                                style={{fontSize: '20px', color: "red"}} size={30}/></span>
                        )}
                    </div>
                </Popover>
            </div>}
            <WarningModal deleteText={t("warning_modal.delete")} title={t("warning_modal.title")}
                          handler={handleDeleteWorkOrder.bind(this, item.id)} isModalOpen={isModalOpen}
                          setIsModalOpen={setIsModalOpen} data={item.title}/>
            {error && <Alert message={error} type={"error"} className={classes.alert}/>}
        </Card>
    );
};

export default WorkOrderItem;


function truncateText(text: string, length: number) {
    // Take only the first line
    const firstLine = text.split('\n')[0];

    // Truncate if needed
    return firstLine.length > length ? firstLine.substring(0, length) + '...' : firstLine;
}

