import React from 'react';
import {Collapse, Spin} from "antd";
import DetailsRow from "./DetailsRow";
import classes from "../styles.module.css"
import {isEmpty} from "lodash";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getProductInstalledByWorkOrder} from "../../../services/workOrders";

interface DetailsSectionProps {
    data: any
    section: string
}

const DetailsSection: React.FC<DetailsSectionProps> = ({data, section}) => {

    const params = useParams();

    const {data: productsInstalled, isLoading} = useQuery({
        queryFn: () => getProductInstalledByWorkOrder(params.id),
        queryKey: ["productsInstalled"]
    })

    if (isEmpty(data)) {
        return <div>
            <h3 style={{color: "#4096FF"}}>{section}</h3>
            <p>Currently no data in this section</p>
        </div>
    }

    if (isLoading) return <Spin/>

    return (
        <Collapse bordered={false} className={classes.sectionCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel header={<h2 className={classes.sectionTitle} style={{color: "#4096FF"}}>{section}</h2>}
                            key="1">
                <div className={classes.sectionContainer}>
                    {data
                        .map((headingItem: any, index: number) => (
                            <DetailsRow
                                products={productsInstalled?.products}
                                section={section}
                                key={headingItem.id + index}
                                label={headingItem.label}
                                data={headingItem.value}
                            />
                        ))
                    }
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};

export default DetailsSection;