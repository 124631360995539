import React from 'react';
import classes from "./style.module.css"
import Markdown from "./components/Markdown";
import SourceDirectory from "./components/SourceDirectory";
import {useTranslation} from "react-i18next";


const WikiPage = () => {

    const {t} = useTranslation()

    return (
        <>
            <h2 className={classes.title}>{t("wiki_page.title")}</h2>
            <div className={classes.container}>
                <div className={classes.directoryContainer}>
                    <SourceDirectory/>
                </div>
                <div className={classes.markDownContainer}>
                    <Markdown/>
                </div>
            </div>
        </>)
};

export default WikiPage;