import React from 'react';
import {Divider, Input} from "antd";
import classes from "../../work-orders/styles.module.css";
import StatusListDropdown from "../../work-orders/components/StatusListDropdown";
import RoleChooser from "../../work-orders/components/RoleChooser";
import {useTranslation} from "react-i18next";
import {Role} from "../../../types/types";

interface MetaCardProps {
    title: string
    statusListId: string | undefined
    roles: Role[]
    setRoles: (roles: Role[]) => void
    setTitle: (title: string) => void
    setStatusListId: (statusListId: string) => void
}

const MetaCard: React.FC<MetaCardProps> = (props) => {

    const {
        title,
        statusListId,
        roles,
        setRoles,
        setTitle,
        setStatusListId
    } = props
    const {t} = useTranslation()


    return (
        <div>
        <h2 style={{color: "#1677FF"}}>{t("template_page.meta_info")}</h2>
            <Divider/>
            <h3>{t("template_page.new_title")}: <span className={classes.red}> *</span></h3>
            <Input style={{minWidth: "200px"}} type={"text"} onChange={(e) => setTitle(e.target.value)}
                   className={classes.templateTitle} placeholder={t("template_page.placeholder")} value={title}/>
            <h3>{t("template_page.status_list")}: <span className={classes.red}> *</span></h3>
            <StatusListDropdown statusListId={statusListId} setStatusListId={setStatusListId}/>
            <h3>{t("template_page.roles")}: <span className={classes.red}> *</span></h3>
            <RoleChooser roles={roles} setRoles={setRoles}/>
        </div>
    );
};

export default MetaCard;