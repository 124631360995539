import React, {useState} from 'react';
import {Task} from "../../../../types/types";
import classes from "./style.module.css";
import TaskList from "../TaskList/TaskList";
import CreateTaskModal from "../../TaskDetailsPage/components/modals/CreateTaskModal/CreateTaskModal";
import CommentModal from "../../../../components/modals/CommentModal/CommentModal";
import TaskCard from "../TaskCard";
import {Badge, Collapse} from "antd";
import useUtils from "../../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";

interface Props {
    task: Task;
    defaultActiveKey?: string;
}

const TaskItemChildren = ({kids}: { kids: Task[] }) => {
    return kids.length > 0 ? <TaskList tasks={kids}/> : null;
}

interface BorderProps {
    task: Task;
    children: React.ReactNode;
}

const Border: React.FC<BorderProps> = ({task, children}) => {
    if (task.isGrandchild) {
        return (
            <>
                <div className={classes.borderOutline}>
                    <div className={classes.borderOutline}>
                        {children}
                    </div>
                </div>
            </>
        );
    }

    if (task.parentTaskId) {
        return (
            <div className={classes.borderOutline}>
                {children}
            </div>
        );
    }

    return (
        <>
            {children}
        </>
    );
};

const TaskItem = ({task, defaultActiveKey}: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

    const renderTitle = () => (
        <b>
            {task.title}
            {task.children.length > 0 &&
                <Badge className={classes.badge} color="#4096FF" count={task.children.length}/>}
        </b>
    );

    const {getStatus, getType} = useUtils()

    const {t} = useTranslation()

    return (
        <Collapse bordered={false} defaultActiveKey={defaultActiveKey} className={classes.task}>
            <Collapse.Panel
                extra={<div className={classes.row}>{getStatus(task.status)}{getType(task.taskKind)}</div>}
                header={renderTitle()}
                key={task.id}
                className={classes.taskArea}
            >
                <Border task={task}>
                    <TaskCard
                        setIsModalOpen={setIsModalOpen}
                        setIsCommentModalOpen={setIsCommentModalOpen}
                        task={task}
                    />
                </Border>
                <TaskItemChildren kids={task.children}/>
            </Collapse.Panel>
            <CreateTaskModal
                taskId={task.id}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <CommentModal
                title={t("comment_modal.add_new_comment")}
                taskId={task.id}
                isModalOpen={isCommentModalOpen}
                setIsModalOpen={setIsCommentModalOpen}
            />
        </Collapse>
    );
};

export default TaskItem;
