// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_loginContainer__MZFqT {
    background-color: #e6effc;
    height: 100vh !important;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.style_logo__i-ILG {
    width: 100px;
    display: flex;
    margin: 0 auto 20px;
}

.style_card__NKxJ\\+ {
    width: 500px;
    margin: 150px auto;
}

/* Media query for mobile devices with maximum width of 600px */
@media only screen and (max-width: 600px) {
    .style_card__NKxJ\\+ {
        width: 90%; /* Adjust the width for smaller screens */
        margin: 50px auto; /* Adjust margin as needed */
    }
}

.style_container__q72Hh {
    gap:10px;
    display: flex;
    flex-direction: column;
}

.style_submitButton__1a\\+CP {
    width: 50%;
    align-self: center;
    height: 40px;
    margin-top: 10px;
}

.style_errorText__kijsy {
    color: red;
}

.style_alert__7udl9 {
    margin-top: 10px;
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA,+DAA+D;AAC/D;IACI;QACI,UAAU,EAAE,yCAAyC;QACrD,iBAAiB,EAAE,4BAA4B;IACnD;AACJ;;AAEA;IACI,QAAQ;IACR,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd","sourcesContent":[".loginContainer {\n    background-color: #e6effc;\n    height: 100vh !important;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.logo {\n    width: 100px;\n    display: flex;\n    margin: 0 auto 20px;\n}\n\n.card {\n    width: 500px;\n    margin: 150px auto;\n}\n\n/* Media query for mobile devices with maximum width of 600px */\n@media only screen and (max-width: 600px) {\n    .card {\n        width: 90%; /* Adjust the width for smaller screens */\n        margin: 50px auto; /* Adjust margin as needed */\n    }\n}\n\n.container {\n    gap:10px;\n    display: flex;\n    flex-direction: column;\n}\n\n.submitButton {\n    width: 50%;\n    align-self: center;\n    height: 40px;\n    margin-top: 10px;\n}\n\n.errorText {\n    color: red;\n}\n\n.alert {\n    margin-top: 10px;\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": `style_loginContainer__MZFqT`,
	"logo": `style_logo__i-ILG`,
	"card": `style_card__NKxJ+`,
	"container": `style_container__q72Hh`,
	"submitButton": `style_submitButton__1a+CP`,
	"errorText": `style_errorText__kijsy`,
	"alert": `style_alert__7udl9`
};
export default ___CSS_LOADER_EXPORT___;
