import genericService from "./genericService";

interface ILoginRequestBody  {
    userName: string;
    password: string;
}

interface ILoginResponseBody  {
    token: string;
}

export const login = async (username: string, password: string) => {
    return   await  genericService.genericPost<ILoginRequestBody,ILoginResponseBody>(
        '/auth',
        {userName: username, password: password}
    )
};

