import React, {useState} from 'react';
import classes from "../styles.module.css";
import {Alert, Button, Tag} from "antd";
import {Role, WorkOrderDetails} from "../../../types/types";
import {useAuth} from "../../../context/AuthProvider";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {claimWorkOrder} from "../../../services/workOrders";

interface ClaimRolesContainerProps {
    item: WorkOrderDetails

}

const ClaimRolesContainer:React.FC<ClaimRolesContainerProps> = ({item}) => {

    const { state } = useAuth();
    const userId = state.userData?.id;
    const userRoles = state.userData?.roles || [];
    const [error, setError] = useState("")
    const queryClient = useQueryClient();

    const userHasRole = (roleId: string | undefined) => userRoles.some((userRole : any) => userRole.id === roleId);

    const canClaim = (role: Role) => {
        if (!role.claimId) return userHasRole(role.roleId); // If claimId is null, check if user has the role
        return role.claimId === userId; // Allow unclaiming if already claimed by the user
    };

    const mutation = useMutation({
        mutationFn: ({ workOrderId, roleId, claim }: {
            workOrderId: string;
            roleId: string;
            claim: boolean;
        }) => claimWorkOrder(workOrderId, roleId, claim),
        onSuccess: async () => {
            setError("");
            queryClient.invalidateQueries({ queryKey: ['workOrderDetails', item.id] }).catch(console.error);
            queryClient.invalidateQueries({ queryKey: ['workOrderHistory', item.id] }).catch(console.error);

            window.location.reload();
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    const handleClaimRole = (roleId: string, isUnclaim: boolean) => {
        mutation.mutate({ workOrderId: item.id, roleId, claim: !isUnclaim });
    };

    const isClaimedByAnother = (role: Role) => role.claimId && role.claimId !== userId;

    return (
        <div className={classes.claimContainer}>
            <h2 className={classes.rolesTitle}>Roles:</h2>
            <div className={classes.tagColumn}>
                {item.roles.map((role) => (
                    <div  key={role.roleId} className={classes.roleItem}>
                        <Tag
                            style={{ width: "fit-content" }}
                            color={role.claimId ? "green" : "default"}
                            className={classes.roleTag}
                        >
                            {role.roleName}
                        </Tag>
                        <Button
                            type="primary"
                            disabled={isClaimedByAnother(role) || !canClaim(role)}
                            onClick={() => handleClaimRole(role.roleId!, role.claimId === userId)}
                            className={`${classes.claimButton} ${role.claimId === userId ? classes.unclaimButton : ""}`}
                        >
                            {role.claimId === userId ? "Unclaim" : "Claim"}
                        </Button>
                    </div>
                ))}
            </div>
            <br />
            {error && <Alert message={error} type={"error"} className={classes.errorText} />}
        </div>
    );
};



export default ClaimRolesContainer;