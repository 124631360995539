import genericService from "./genericService";
import {Comment} from "../types/types";


interface ITaskComment {
    taskId: string | undefined;
    text: string
}

export const getTaskComments = async (id: string | undefined) => {
    return await genericService.genericGet<Comment[]>(`/task/comments/${id}`);
};

export const addComment = async (taskId: string | undefined, text: string) => {
    return await genericService.genericPost<ITaskComment, Comment>(
        `/comment`,
        { taskId, text },
    )
};

export const updateComment = async (id: string, text: string) => {
    return await genericService.genericPut<{ text: string }, Comment>(
        `/comment/${id}`,
        { text }
    );
};

