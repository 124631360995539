import React from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import router from "./routes/Routes";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";


function App() {

    const queryClient = new QueryClient();

    return <QueryClientProvider client={queryClient}><AuthProvider><RouterProvider
        router={router}/></AuthProvider></QueryClientProvider>}


export default App;
