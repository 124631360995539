import React from 'react';
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getTaskHistory} from "../../../services/tasks";
import {Card, Collapse, Skeleton, Tabs, TabsProps, Timeline} from "antd";
import TaskHistoryItem from "./TaskHistoryItem";
import classes from "../style.module.css"
import {HistoryOutlined} from "@ant-design/icons";
import {getTaskComments} from "../../../services/comments";
import {Comment} from "../../../types/types";
import CommentItem from "./components/CommentItem";
import {useTranslation} from "react-i18next";

const TaskHistorySection = () => {

    const params = useParams()
    const {t} = useTranslation()

    const {data: taskLog, isLoading} = useQuery({
        queryFn: () => getTaskHistory(params?.id),
        queryKey: ["taskHistory", params.id],
    })

    const {data: comments} = useQuery({
        queryFn: () => getTaskComments(params?.id),
        queryKey: ["taskComments", params.id],
    })


    if (isLoading) {
        return <Card style={{width: "98%", margin: "0 auto"}}>
            <Skeleton active={isLoading}/>
        </Card>
    }

    const taskLogItems = taskLog && taskLog.length > 0 ?
        taskLog.map((log, index) => ({
            children: <TaskHistoryItem key={index} log={log}/>
        })) :
        [{
            children: <>{t("task_history.no_logs")}</>,
            color: "gray",
        }];


    const taskCommentItems = comments && comments.length > 0 ?
        comments.map((comment: Comment) => ({
            color: 'red',
            children: <CommentItem key={comment.id} taskId={params.id} comment={comment}/>
        })) :
        [{
            children: <>{t("task_history.no_comments")}</>,
            color: 'gray'
        }];


    const getItems = (t: any ) => {

        return [
            {
                key: '1',
                label: <>{t("task_details.logs")}</>,
                children:
                    <div className={classes.scrollerDiv}>
                        <Timeline
                            style={{padding: "10px"}}
                            items={[...taskLogItems]}
                        />
                    </div>

            },
            {
                key: '2',
                label: <>{t("task_details.comments")}</>,
                children:
                    <div className={classes.scrollerDiv}>
                        <Timeline
                            style={{padding: "10px"}}
                            items={[...taskCommentItems]}
                        />
                    </div>

            },
            {
                key: '3',
                label: <>{t("task_details.logs")} / <>{t("task_details.comments")}</></>,
                children:
                    <div className={classes.scrollerDiv}>
                        <Timeline
                            style={{padding: "10px"}}
                            items={[...taskLogItems, ...taskCommentItems]}
                        />
                    </div>

            }
        ] as TabsProps['items'];
    }


    const items =  getItems(t)

    return (
        <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel header={<h2>{t("task_details.task_history")} <HistoryOutlined/></h2>} key="1">
                <Card className={classes.taskHistoryBody}>
                    <Tabs defaultActiveKey="1" items={items} />
                </Card>
            </Collapse.Panel>
        </Collapse>
    )
};

export default TaskHistorySection;