import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./translations/en.json"
import hr from "./translations/hr.json"
import rs from "./translations/rs.json"
import cp from "./translations/cp.json"

const LANGUAGE_KEY = "i18nextLng"

// setting croatian as default language selected
if(localStorage.getItem(LANGUAGE_KEY) === null) {
    localStorage.setItem(LANGUAGE_KEY, "hr")
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'hr',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            hr:hr,
            en: en,
            rs: rs,
            cp: cp
        }
    });

export default i18n;