import React from 'react';
import CollapseWrapper from "./CollapseWrapper";
import classes from "../../work-orders/styles.module.css";
import SectionsList from "../templateSections/SectionsList";
import {Button, Card, Divider, Spin} from "antd";
import CustomSectionList from "../templateSections/CustomSectionList";
import {StatusList} from "../../../types/types";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

interface TemplateCardProps {
    statuses: StatusList | undefined
    statusListId: string | undefined
    setIsModalOpen: (value: boolean) => void
    isLoading: boolean
}

const TemplateCard: React.FC<TemplateCardProps> = ({statuses, statusListId, setIsModalOpen, isLoading}) => {

    const {t} = useTranslation()
    const params = useParams()



    return (
        <div>
            <h2 style={{color: "#1677FF"}}>{t("template_page.template")}</h2>
            <Divider/>
            <CollapseWrapper panelKey={"1"} className={classes.sectionCollapse} title={t("template_page.headings")}>
                <Card>
                    <SectionsList />
                </Card>
            </CollapseWrapper>
            {statusListId &&
                <CollapseWrapper panelKey={"2"} className={classes.sectionCollapse}
                                 title={t("template_page.status_fields")}>
                    <Card>
                        {isLoading ? <Spin style={{width: "100%", height: "100%"}}/> :
                            <CustomSectionList statuses={statuses}/>}
                    </Card>
                </CollapseWrapper>}
            <div className={classes.end}>
                <Button
                    className={classes.end}
                    type={"primary"}
                    onClick={setIsModalOpen.bind(null, true)}>{params.id ? t("template_page.update_template") : t("template_page.create_new_template")}</Button>
            </div>
            <Divider/>
        </div>
    );
};

export default TemplateCard;