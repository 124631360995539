import React from 'react';
import WorkOrderItem from "../components/WorkOrderItem";
import {WorkOrder} from "../../../types/types";
import classes from "../styles.module.css";
import {useTranslation} from "react-i18next";


interface CardViewProps {
    workOrders: WorkOrder[] | undefined
}

const CardView: React.FC<CardViewProps> = ({workOrders}) => {

    const {t} = useTranslation();

    return (
        <div className={classes.cardContainer}>
            {
                workOrders && workOrders.length > 0 ?
                    workOrders
                        .sort((a, b) => new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf())  // Sorting by createdDate
                        .map((workOrder) => (
                            <WorkOrderItem key={workOrder.id} item={workOrder}/>
                        ))
                    : <div>{t("work_orders_page.no_work_orders")}</div>
            }
        </div>)
};

export default CardView;