import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getStatusListTemplate} from "../../../services/statuses";
import {Select} from "antd";
import {useTranslation} from "react-i18next";

interface StatusListDropdownProps {
    statusListId: string | undefined
    setStatusListId: (id: string) => void
}

const StatusListDropdown:React.FC<StatusListDropdownProps> = ({statusListId,setStatusListId}) => {

    const {data: statusList} = useQuery({
        queryFn: () => getStatusListTemplate(),
        queryKey: ["statusListTemplate"]
    })
    
    const {t} = useTranslation()

    const onChange = (value: string) => {
        setStatusListId(value)
    };

    const options = Array.isArray(statusList)
        ? statusList.map((p: any) => ({ value: p.id, label: p.name }))
        : [];

    return (
        <Select
            value={statusListId}
            style={{ minWidth: "300px", width:"20%" }}
            onChange={onChange}
            placeholder={t("template_page.select_status_list")}
            options={options}
        />
    );
};

export default StatusListDropdown;