import React from 'react';
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";

interface CustomStepProps {
    data: any
}

const CustomStep:React.FC<CustomStepProps> = ({data}) => {
    return (
        <div>
            {data.map((item: any) => (
                <DynamicInputRenderer item={item} key={item.id}/>
            ))}
        </div>
    );
};

export default CustomStep;