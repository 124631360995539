import React, {useState} from 'react';
import classes from "../style.module.css";
import {Button, Card, Collapse} from "antd";
import {TaskDetails} from "../../../types/types";
import TableData from "./components/TableData";
import {CommentOutlined, InfoCircleOutlined} from "@ant-design/icons";
import AssignModal from "./components/modals/AssignModal/AssignModal";
import EditTaskModal from "./components/modals/EditTaskModal/EditTaskModal";
import CommentModal from "../../../components/modals/CommentModal/CommentModal";
import AssignCompanyModal from "./components/modals/AssignModal/AssignCompanyModal";


interface IAboutTaskSectionProps {
    task: TaskDetails
}


const AboutTaskSection: React.FC<IAboutTaskSectionProps> = (props) => {

    const {task} = props

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

    const showModal = (type: string) => {
        switch (type) {
            case "assign":
                setIsModalOpen(true);
                break;
            case "edit":
                setIsEditModalOpen(true);
                break;
            case "comment":
                setIsCommentModalOpen(true);
                break;
            case "company":
                setIsAssignModalOpen(true);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
                <Collapse.Panel header={<h2>Task details <InfoCircleOutlined/></h2>} key="1">
                    <Card>
                        <div className={classes.taskAboutContainer}>
                            <div className={classes.container}>
                                <TableData setIsModalOpen={setIsModalOpen} task={task}/>
                            </div>
                            <div className={classes.buttonsContainer}>
                                <Button onClick={showModal.bind(this, "edit")}>Edit task</Button>
                                <Button onClick={showModal.bind(this, "assign")}>Assign task</Button>
                                <Button onClick={showModal.bind(this, "company")}>Assign company</Button>
                                <Button icon={<CommentOutlined/>} onClick={showModal.bind(this, "comment")}>Add
                                    comment</Button>
                                <Button danger>Remove task</Button>
                            </div>
                        </div>
                    </Card>
                </Collapse.Panel>
            </Collapse>
            <AssignModal users={task.users} groups={task.groups} isModalOpen={isModalOpen} title={"Assign users or groups to task"}
                         setIsModalOpen={setIsModalOpen} taskId={task.id}/>
            <AssignCompanyModal value={{id: task.companyId, name: task.companyName}} taskId={task.id}
                                isModalOpen={isAssignModalOpen}
                                setIsModalOpen={setIsAssignModalOpen}/>
            <EditTaskModal task={task} isModalOpen={isEditModalOpen}
                           setIsModalOpen={setIsEditModalOpen}/>
            <CommentModal title={"Add new comment"} taskId={task.id} isModalOpen={isCommentModalOpen} setIsModalOpen={setIsCommentModalOpen} />

        </>
    );
};

export default AboutTaskSection;