import React, {useState} from 'react';
import {Alert, Button, Modal} from "antd";
import classes from "./style.module.css";
import AssignedModalRow from "./AssignedModalRow";
import CompanyAutocomplete from "../../CompanyAutocomplete";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {TaskDetails} from "../../../../../../types/types";
import {updateTask} from "../../../../../../services/tasks";

interface FooterProps {
    handleCancel: () => void,
    handleOk: () => void,
}

const ModalFooter: React.FC<FooterProps> = ({handleOk, handleCancel}) => {
    return <>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button key="submit" type="primary" onClick={handleOk}>
            Assign
        </Button>
    </>
}


interface IAssignCompanyModalProps {
    isModalOpen: boolean,
    setIsModalOpen: (newIsModalOpen: boolean) => void,
    taskId: string
    value: { id: string, name: string }
}


const AssignCompanyModal: React.FC<IAssignCompanyModalProps> = ({isModalOpen, setIsModalOpen, taskId, value}) => {

    const [error, setError] = useState("");
    const [assignedCompany, setAssignedCompany] = useState<{ id: string, name: string }>(value);
    const queryClient = useQueryClient()

    const mutation = useMutation<TaskDetails, Error, { id: string, body: any }>({
        mutationFn: ({id, body}) => updateTask(id, body),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['taskDetails', taskId]}).catch(console.error)
            queryClient.invalidateQueries({queryKey: ['taskHistory', taskId]}).catch(console.error)
            setError("")
            setIsModalOpen(false)
        },
        onError: (error) => {
            setError(error.message)
        }
    })

    const handleOk = () => {
        mutation.mutate({
            id: taskId,
            body: {companyId: assignedCompany.id}
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false)
        setAssignedCompany(value)
    };

    return (
        <Modal maskClosable={false} onCancel={handleCancel} title="Assign company to task"
               footer={<ModalFooter handleCancel={handleCancel} handleOk={handleOk}/>} open={isModalOpen}>
            <div className={classes.modalContainer}>
                <AssignedModalRow assignedData={assignedCompany} type={"company"}/>
                <CompanyAutocomplete setAssignedCompany={setAssignedCompany}/>
            </div>
            {error && <Alert type="error" className={classes.alert} message={error}/>}
        </Modal>
    );
};

export default AssignCompanyModal;