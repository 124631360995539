// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_pageContainer__VwFd8 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.style_container__0wgEe {
    flex-grow: 1;
}

`, "",{"version":3,"sources":["webpack://./src/routes/style.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".pageContainer {\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.container {\n    flex-grow: 1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `style_pageContainer__VwFd8`,
	"container": `style_container__0wgEe`
};
export default ___CSS_LOADER_EXPORT___;
