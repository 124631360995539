import React, {useEffect, useState} from 'react';
import classes from "../work-orders/styles.module.css";
import {EditOutlined, FormOutlined} from "@ant-design/icons";
import {Button, Spin} from "antd";
import {Role} from "../../types/types";
import {useNavigate, useParams} from "react-router-dom";
import PreviewModal from "../work-orders/modals/PreviewModal";
import {SectionInputProvider, useSectionInputContext} from "./contex/sectionInputContext";
import {useQuery} from "@tanstack/react-query";
import {getTemplate} from "../../services/template";
import {transformTemplateStatusesToWorkOrder, transformTemplateToWorkOrder} from "./contex/sectionUtils";
import {SectionInputAction} from "./contex/SectionInputReducer";
import {useTranslation} from "react-i18next";
import {getStatusList} from "../../services/statuses";
import {CustomSectionInputProvider, useCustomSectionInputContext} from "./contex/customSectionInputContext";
import {CustomSectionInputAction} from "./contex/customSectionInputReducer";
import MetaCard from "./components/MetaCard";
import TemplateCard from "./components/TemplateCard";
import useUtils from "../../utils/globalFunctions";

const CreateNewTemplatePage = () => {

    const navigate = useNavigate();
    const params = useParams()
    const {t} = useTranslation()
    const {formatDate} = useUtils()

    const {data: template, isLoading} = useQuery({
        queryFn: () => getTemplate(params.id ?? ""),
        queryKey: ["template", params.id],
        enabled: !!params.id
    });

    if (isLoading && !!params.id) {
        return <Spin/>
    }



    return (
        <SectionInputProvider>
            <CustomSectionInputProvider>
                <div className={classes.templateContainer}>
                    <div className={classes.createTemplateRow}>
                        <h2>{params.id ? t("template_page.edit_template") : t("template_page.create_new_template")}
                            <span style={{marginLeft: '8px'}}>
                            <FormOutlined/>
                            </span>
                        </h2>
                        <Button danger onClick={() => navigate("/work_orders")}>Cancel</Button>
                    </div>
                    <div>
                        {params.id &&
                            <span><EditOutlined/> {t("tasks_page.created_at", {date: formatDate(template?.createdDate)})} | {t("tasks_page.updated_at", {date: formatDate(template?.updatedDate)})}</span>}
                    </div>
                    <CreateTemplate roles={template?.roles} title={template?.title}
                                    statusListId={template?.statusList?.id}
                                    template={template}/>
                </div>
            </CustomSectionInputProvider>
        </SectionInputProvider>
    );
};

export default CreateNewTemplatePage;

interface CreateTemplateProps {
    title: string
    statusListId: string
    roles: Role[]
    template: any
}

const CreateTemplate: React.FC<CreateTemplateProps> = ({
                                                           title: initTitle = "",
                                                           statusListId: initStatus,
                                                           template,
                                                           roles: initRoles
                                                       }) => {
    const [title, setTitle] = React.useState<string>(initTitle);
    const [statusListId, setStatusListId] = React.useState<string | undefined>(initStatus);
    const [roles, setRoles] = useState<Role[]>(initRoles ?? [])
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {dispatch} = useSectionInputContext()
    const {dispatch: dispatch2} = useCustomSectionInputContext()

    const {data: statuses, isLoading} = useQuery({
        queryFn: () => statusListId ? getStatusList(statusListId) : undefined,
        queryKey: ["statusList"],
        enabled: !!statusListId
    })

    useEffect(() => {
        setRoles(initRoles ?? [])
    }, [initRoles]);


    useEffect(() => {
        if (template) {
            dispatch({
                type: SectionInputAction.SET_INIT,
                payload: {data: transformTemplateToWorkOrder(template.schemaContent)}
            })
            dispatch2({
                type: CustomSectionInputAction.SET_INIT,
                payload: {data: transformTemplateStatusesToWorkOrder(template.status_fields ?? [])} //TODO TEST
            })
        }
    }, [template]);

    return (
        <div className={classes.templateContainerWrapper}>
            <MetaCard title={title} statusListId={statusListId} roles={roles} setRoles={setRoles}
                      setTitle={setTitle} setStatusListId={setStatusListId}/>
            <TemplateCard  statuses={statuses} statusListId={statusListId} setIsModalOpen={setIsModalOpen} isLoading={isLoading}/>
            <PreviewModal roles={roles} statusListId={statusListId} title={title} isModalOpen={isModalOpen}
                          setIsModalOpen={setIsModalOpen}/>
        </div>
    );
};

