
export enum InputTypesEnum {
    Input = "input",
    Textarea = "textarea",
    Select = "select",
    Date = "date",
    ProductList = "productList",
    Location = "location"
}

export type FormInputTypesWithId = FormInputTypes & {id:string}

export type FormSectionType = (FormInput | FormTextarea | FormSelect | FormDate | FormProductList | FormLocation)[]



export type FormInputTypes =
    { type: InputTypesEnum.Input, data: FormInput } |
    { type: InputTypesEnum.Textarea, data: FormTextarea } |
    { type: InputTypesEnum.Select, data:FormSelect} |
    { type: InputTypesEnum.Date, data: FormDate } |
    { type: InputTypesEnum.ProductList, data: FormProductList } |
    { type: InputTypesEnum.Location, data: FormLocation }

export type FormInput = {
    id: string;
    inputType: "input"
    type: string;
    name: string;
    label: string;
    isRequired: boolean;
    maxLength?: number,
    roles: string[]
}

export type FormTextarea = {
    id: string;
    inputType: "textarea"
    name: string;
    label: string;
    isRequired: boolean;
    roles: string[]
}


export type FormDate = {
    id: string;
    inputType: "date"
    name: string;
    label: string;
    isRequired: boolean;
    roles: string[]
}


export type FormOption = {
    value: string;
    id: string;
    label: string;
};

export type FormSelect = {
    id: string;
    inputType: "select"
    name: string;
    options: FormOption[]
    label: string;
    isRequired: boolean;
    roles: string[]
}

export type FormProductList = {
    id: string;
    inputType: "productList"
    name: string;
    type: string;
    label: string;
    isRequired: boolean;
}

export type FormProduct = {
    productId: string;
    serialNumber?: string;
    quantity: string,
    itemNumber: string,
    parentItemNumber?: string
    productName?: string;
    description?: string;
    units?: string;
    createdDate?: string;
    primary?: number
    updatedDate?: string;
    id?: string;
}

export type FormLocation = {
    id:string;
    label: string;
    name: string;
    value: string;
    inputType: "location",
    lat: number;
    long: number;
}