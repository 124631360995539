import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Modal, Select, Spin} from "antd";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getTemplatesByUser} from "../../../services/template";
import classes from "../styles.module.css"
import {PlusOutlined} from "@ant-design/icons";
import TemplateItem from "../components/TemplateItem";
import {useNavigate} from "react-router-dom";
import {getWorkUnits} from "../../../services/companies";
import {createWorkOrder} from "../../../services/workOrders";
import {FormLocation} from "../../../types/inputs";
import TextArea from "antd/es/input/TextArea";
import {useTranslation} from "react-i18next";

interface TemplateModalProps {
    isModalOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void
}

const TemplateModal: React.FC<TemplateModalProps> = ({isModalOpen, setIsModalOpen}) => {

    const [error, setError] = useState("");
    const navigate = useNavigate()


    const {data: templates, isLoading} = useQuery({
        queryFn: () => getTemplatesByUser(),
        queryKey: ["templates"]
    })

    const {data: workUnits} = useQuery({
        queryFn: () => getWorkUnits(),
        queryKey: ["workUnits"]
    })


    const [workUnit, setWorkUnit] = useState(workUnits ? workUnits[0].id : "")
    const [notes, setNotes] = useState("")
    const [templateId, setTemplateId] = useState("")
    const queryClient = useQueryClient()
    const {t} = useTranslation()


    useEffect(() => {
        setWorkUnit(workUnits ? workUnits[0].id : "")
    }, [workUnits]);


    const createWorkOrderMutation = useMutation<any, Error, { template: any, templateId:string, workUnitId: string, notes:string }>({
        mutationFn: ({template, templateId, workUnitId, notes}) => createWorkOrder(template, templateId, workUnitId,notes),
        onSettled: async (data) => {
            navigate(`/work_orders/${data.id}/edit`);
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error);
            setError("");
        },
        onError: (error) => {
            //@ts-ignore
            setError(error.message);
        }
    });

    const inputLocation: FormLocation = {
        id: "location",
        label: "Address",
        name: "location",
        value: "",
        inputType: "location",
        lat: 0,
        long: 0
    }

    const handleOk = () => {

        if(!templateId) {
            setError("Please select a template")
            return
        }

        const item = templates.find((item: any) => item.id === templateId)

        const updatedSchemaContent = {
            heading: [...(item.schemaContent?.heading || [])],
            materials: [...(item.schemaContent?.materials || [])],
            expenses: [...(item.schemaContent?.expenses || [])],
        };

        // Assuming `inputLocation` is defined and should be part of the heading section
        updatedSchemaContent.heading.push(inputLocation);

        // Prepare the updated template object with the same structure as the original template
        const updatedTemplate = {
            ...item,
            schemaContent: updatedSchemaContent,
        };

        // Perform the mutation with the updated template
        createWorkOrderMutation.mutate({ template: updatedTemplate, templateId: templateId, workUnitId: workUnit, notes:notes });

    };

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    if (isLoading) {
        return <Modal width={"50vw"} title="Choose template" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Spin/>
        </Modal>
    }

    const handleChange = (value: string) => {
        setWorkUnit(value)
        localStorage.setItem("workUnit", value)
    };

    const defaultValue = localStorage.getItem("workUnit") ? localStorage.getItem("workUnit") : workUnits ? workUnits[0].id : "";

    if(templates) {
        return (
            <Modal width={window.innerWidth <= 768 ? "90vw" : "60vw"} title={t("template_modal.title")} open={isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel} footer={[
                <Button key="back" onClick={handleCancel}>
                    {t("template_modal.close")}
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("template_modal.create_work_order")}
                </Button>,
            ]}>
                <div className={classes.templateCardContainer}>
                    {templates.map((item: any) => {
                        return <TemplateItem templateId={templateId} setTemplateId={setTemplateId} setError={setError}
                                             setIsModalOpen={setIsModalOpen} item={item} key={item.id}/>
                    })}
                    <Card className={classes.createCard}
                          onClick={() => navigate("/work_orders/template")}><PlusOutlined/><h3>{t("template_modal.create_new_template")}</h3>
                    </Card>
                </div>
                <div>
                    <h3 style={{width:"100%"}} className={classes.title}>{t("template_modal.choose_work_unit")}</h3>
                    <Select
                        className={classes.unitSelect}
                        defaultValue={defaultValue}
                        onChange={handleChange}
                        options={workUnits?.map(item => {
                            return {label: item.name, value: item.id}
                        })}
                    />
                </div>
                <div>
                    <h3 style={{width:"100%"}} className={classes.title}>{t("template_modal.add_notes")}</h3>
                    <TextArea onChange={(e) => setNotes(e.target.value)} autoSize={true} value={notes}/>
                </div>
                {error && <Alert type="error" message={error} className={classes.alert}/>}
            </Modal>
        );
    }
};

export default TemplateModal;