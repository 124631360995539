import genericService from "./genericService";
import {StatusList} from "../types/types";

export const getStatusList = async (id: string) => {
    return await genericService.genericGet<StatusList>(`/status-list/${id}/related`)
};


export const getStatusListTemplate = async () => {
    return await genericService.genericGet<any>("/status-list")
};
