import React, {useState} from 'react';
import {Button, Divider, Input, Radio, RadioChangeEvent} from "antd";
import classes from "./style.module.css";
import TextArea from "antd/es/input/TextArea";
import {FormProduct} from "../../../../types/inputs";
import useUtils from "../../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";
import {BarcodeOutlined} from "@ant-design/icons";
import BarcodeScanner from "../../modals/BarcodeScanner";

interface AddonProductProps {
    addon: FormProduct;
    handleRemoveAddon: (addon: FormProduct) => void;
    handleUpdateInput: (id: string, field: string, value: any) => void
}


const AddonProduct: React.FC<AddonProductProps> = ({addon, handleRemoveAddon, handleUpdateInput}) => {

    const [value, setValue] = useState(addon.quantity ? addon.quantity.toString() : "1");
    const {t} = useTranslation()
    const {optionsWithDisabled} = useUtils()
    const isMobile = window.innerWidth <= 768
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
        handleUpdateInput(addon.itemNumber, "quantity", value)
    };

    const handleOnBarcode = (barcode: string) =>{
        handleUpdateInput(addon.itemNumber, "serialNumber", barcode)
    }

    if (!addon) return null



    return (
        <div><Divider/>
            <h4 style={{width: "200px"}}>{addon.productName}</h4>
            <div className={classes.addonContainer}>
                {addon.serialNumber != null ? (  // Check if serialNumber exists and is not null
                    <>
                        <div className={classes.clipboardContainer}>
                            <Input
                                style={{minWidth: "200px"}}
                                value={addon.serialNumber}
                                type={"text"}
                                placeholder={"serial number"}
                                onChange={(e) => handleUpdateInput(addon.itemNumber, "serialNumber", e.target.value)}
                                required
                            />
                            {isMobile && <Button onClick={() => setIsModalOpen(true)}><BarcodeOutlined/></Button>}
                            <BarcodeScanner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleBarcode={handleOnBarcode}/>
                        </div>

                        <Radio.Group
                            options={optionsWithDisabled}
                            onChange={onChange}
                            value={value}
                            optionType="button"
                            buttonStyle="solid"
                            className={classes.radioRow}
                        />
                    </>
                ) : (
                    <Input
                        style={{minWidth: "200px"}}
                        type={"number"}
                        defaultValue={addon.quantity}
                        placeholder={"quantity"}
                        onChange={(e) => handleUpdateInput(addon.itemNumber, "quantity", e.target.value)}
                        suffix={addon.units}
                        required
                    />
                )}
                <Button onClick={() => handleRemoveAddon(addon)} danger>Remove</Button>
            </div>
            <br/>
            <div className={classes.container}>
                <TextArea defaultValue={addon.description}
                          onChange={(e) => handleUpdateInput(addon.itemNumber, "description", e.target.value)}
                          placeholder={t("work_order_details.description")} autoSize={true}/>
            </div>
        </div>
    );
};

export default AddonProduct;