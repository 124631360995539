import React, {useRef, useState} from 'react';
import {Button, Input, InputRef, Skeleton, Space, Table, TableColumnType, TableProps} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getUsers} from "../../../services/users";
import {FilterDropdownProps} from "antd/es/table/interface";
import {SearchOutlined} from "@ant-design/icons";
import {Highlighter} from "../../../utils/globalFunctions";

interface DataType {
    key: number;
    userName: string;
    personName: string;
}

type DataIndex = keyof DataType;


const UserTable: React.FC = () => {

    const {data: users, isLoading} = useQuery({
        queryFn: getUsers,
        queryKey: ["users"]
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const data: DataType[] = users?.map((user, index) => ({
        key: index + 1,
        userName: user.userName,
        personName: user.personName
    })) || [];

    const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search...`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'username',
            dataIndex: 'userName',
            key: 'userName',
            width: '30%',
            ...getColumnSearchProps('userName'),
        },
        {
            title: 'name',
            dataIndex: 'personName',
            key: 'personName',
            width: '30%',
            ...getColumnSearchProps('personName'),
        },
    ];

    if (isLoading) return <Skeleton active={true}/>

    return (
        <Table columns={columns} dataSource={data}/>
    );
};

export default UserTable;
