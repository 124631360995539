import React from 'react';
import classes from "./style.module.css";
import {ShopOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
import {Tag} from "antd";

interface DataItem {
    id: string;
    userName?: string;
    groupName?: string;
    name?: string;
}

interface Props {
    previousData?: DataItem[] | any;
    assignedData?: DataItem[] | any;
    type: 'company' | 'user' | 'group';
    handler?: (id: string) => void;
}

const AssignedModalRow: React.FC<Props> = ({previousData = [], assignedData = [], handler, type}) => {
    const renderIcon = () => {
        switch (type) {
            case 'company':
                return <ShopOutlined className={classes.icon}/>;
            case 'user':
                return <UserOutlined className={classes.icon}/>;
            case 'group':
                return <TeamOutlined className={classes.icon}/>;
            default:
                return null;
        }
    };

    const renderTags = (data: DataItem[], isClosable: boolean) => {
        return data.map((item) => (
            <Tag
                className={classes.tag}
                key={item.id}
                color={isClosable ? undefined : "cyan"}
                closable={isClosable}
                onClose={isClosable ? () => handler?.(item.id) : undefined}
            >
                {type === 'user' ? item.userName : item.groupName || item.name}
            </Tag>
        ));
    };


    return (
        <div className={classes.row}>
            <span>
                {renderIcon()}
            </span>
            <span>
                {type === 'company' ? (
                    assignedData.name ?
                        <Tag className={classes.tag} key={assignedData.id}>
                            {assignedData.name}
                        </Tag> : <></>
                ) : (
                    <>
                        {renderTags(previousData, false)}
                        {renderTags(assignedData, true)}
                    </>
                )}
            </span>
        </div>
    );
};

export default AssignedModalRow;
