import React from 'react';
import classes from "./TaskItem/style.module.css";
import {Button, Card} from "antd";
import {CommentOutlined, PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {Task} from "../../../types/types";
import TaskComments from "./TaskItem/TaskComments";
import useUtils from "../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";

interface ITaskCardProps {
    task: Task
    setIsModalOpen: (open: boolean) => void
    setIsCommentModalOpen: (open: boolean) => void
}

const TaskCard: React.FC<ITaskCardProps> = ({task, setIsCommentModalOpen, setIsModalOpen}) => {

    const navigate = useNavigate();

    const {getStatus, getPriority, formatDate} = useUtils()
    const {t} = useTranslation()

    return (
        <Card className={classes.taskBody}>
            <div className={classes.row}>
                <p>{getStatus(task.status)}</p>
                <p>{t("tasks_page.priority")} {getPriority(task.priority)}</p>
            </div>
            <div className={classes.taskContent}>
                <p className={classes.text}>{task.text}</p>
                <div className={classes.taskFooter}>
                    <div className={classes.taskActions}>
                        <Button type={"primary"} onClick={() => navigate(`/tasks/${task.id}`)}>
                            {t("tasks_page.go_to_details")}
                        </Button>
                        {!task.isGrandchild && (
                            <Button icon={<PlusOutlined/>} onClick={() => setIsModalOpen(true)}>
                                {t("tasks_page.create_new_subtask")}
                            </Button>
                        )}
                        <Button icon={<CommentOutlined/>} onClick={() => setIsCommentModalOpen(true)}>
                            {t("tasks_page.add_new_comment")}
                        </Button>
                    </div>
                </div>
            </div>
            <TaskComments taskId={task.id} comments={task.comment}/>
            <span>
                {t("tasks_page.created_at", {date: formatDate(task.createdDate)})}</span> |
            <span>  {t("tasks_page.updated_at", {date: formatDate(task.updatedDate)})}</span>
        </Card>
    );
};

export default TaskCard;