import React, {useEffect} from 'react';
import classes from "../styles.module.css";
import {Button, Dropdown} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {FormInputTypesWithId, InputTypesEnum} from "../../../types/inputs";
import {createInput} from "../contex/sectionUtils";
import {v4 as uuidv4} from "uuid";
import {CustomSectionInputAction} from "../contex/customSectionInputReducer";
import {useCustomSectionInputContext} from "../contex/customSectionInputContext";
import {ReactSortable} from "react-sortablejs";
import InputSelector from "./InputSelector";
import {Status} from "../../../types/types";

interface SectionItemProps {
    section: Status
}

const CustomSectionItem:React.FC<SectionItemProps> = ({section}) => {

    const {t} = useTranslation()
    const {dispatch, state} = useCustomSectionInputContext()

    const handleAddInput = (inputType: InputTypesEnum) => {
        const newInputData = createInput(inputType)
        const newInput = {type: inputType, data: newInputData, id: uuidv4()} as FormInputTypesWithId
        const newData = [...inputs, newInput]
        dispatch({type:CustomSectionInputAction.SET_SECTION, payload: {section: section.id, data: newData}})
    }

    const inputs = state[section.id]?.data ?? []

    const names: InputTypesEnum[] = [InputTypesEnum.Input, InputTypesEnum.Textarea, InputTypesEnum.Select, InputTypesEnum.Date]
    const dropDownItems = names.map((name) => {
        return {
            key: name,
            label: <div onClick={handleAddInput.bind(null, name)} className={classes.inputLink}>{name}</div>,
            value: name,
        }
    })

    const handleOnChange = (id: string, data: any) => {

        const newInputIndex = inputs.findIndex((input) => input.data.id === id)
        const newInput = inputs[newInputIndex]
        const newInputs = [...inputs]
        newInputs[newInputIndex] = {...newInput, data: {...newInput.data, ...data}}
        dispatch({type: CustomSectionInputAction.SET_SECTION, payload: {section: section.id, data: newInputs}})

    }
    const handleRemove = (id: string) => {
        const newInputs = inputs.filter((input) => input.data.id !== id)
        dispatch({type: CustomSectionInputAction.SET_SECTION, payload: {section: section.id, data: newInputs}})

    }

    const updateInputs = (newInputs: FormInputTypesWithId[]) => {
        const updatedInputs = newInputs.map((input) => ({ ...input, id: input.id }));
        dispatch({ type: CustomSectionInputAction.SET_SECTION, payload: { section: section.id, data: updatedInputs } });
    };


    return (
        <div>
            <div className={classes.createTemplateRow}>
                <h2 style={section.color ? {borderBottom: `2px solid ${section.color}`} : {}}>{section.name}</h2>
                <Dropdown menu={{items: dropDownItems}} placement="bottom">
                    <Button icon={<PlusOutlined/>}>{t("template_page.add_new_field")}</Button>
                </Dropdown>
            </div>
            <div className={classes.templateColumn}>
                <ReactSortable list={inputs.map((input) => ({ ...input, id: input.id }))} setList={updateInputs}>
                    {inputs?.map((input) => (
                        <div key={input.id}>
                            <InputSelector
                                inputData={input}
                                handleRemove={handleRemove}
                                onChange={handleOnChange}
                            />
                            <br />
                        </div>
                    ))}
                </ReactSortable>
            </div>
        </div>
    );
};

export default CustomSectionItem;