import {useMutation, useQueryClient} from "@tanstack/react-query";
import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {TaskSchema} from "../../../../../../utils/shemas";
import {TaskDetails} from "../../../../../../types/types";
import {updateTask} from "../../../../../../services/tasks";
import CustomFormInput from "../../../../../../components/inputs/CustomFormInput";
import CustomFormTextArea from "../../../../../../components/inputs/CustomFormTextArea";
import CustomFormSelect from "../../../../../../components/inputs/CustomFormSelect";
import {Alert, Button, Modal} from "antd";
import classes from "./style.module.css";


interface Props {
    isModalOpen: boolean,
    setIsModalOpen: (newIsModalOpen: boolean) => void,
    task: TaskDetails
}

interface IFormInput {
    title: string
    text: string
    status: string
    priority: string
    taskKind: string

}


const EditTaskModal: React.FC<Props> = ({isModalOpen, setIsModalOpen, task}) => {

    const queryClient = useQueryClient()

    const initialValues = {
        title: task.title,
        text: task.text,
        status: task.status,
        priority: task.priority,
        taskKind: task.taskKind
    }

    const [error, setError] = useState("");


    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(TaskSchema),
    })

    const mutation = useMutation<TaskDetails, Error, { id: string, body: IFormInput }>({
        mutationFn: ({id, body}) => updateTask(id, body),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['taskDetails', task.id]}).catch(console.error)
            queryClient.invalidateQueries({queryKey: ['taskHistory', task.id]}).catch(console.error)
            setError("")
            setIsModalOpen(false)
        },
        onError: (error) => {
            setError(error.message)
        }
    })

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        mutation.mutate({
            id: task ? task.id : "",
            body: data
        })
    }

    return (
        <Modal maskClosable={false} onCancel={handleCancel} footer={false} title="Edit task details" open={isModalOpen}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.formContainer}>
                    <CustomFormInput control={control} label={"Title"} name={"title"} errors={errors.title}/>
                    <CustomFormTextArea control={control} label={"Description: "} name={"text"} errors={errors.text}/>
                    <CustomFormSelect control={control} label={"Status"} name={"status"} errors={errors.status} type={0}/>
                    <CustomFormSelect control={control} label={"Priority"} name={"priority"} errors={errors.priority} type={1}/>
                    <CustomFormSelect control={control} label={"Type"} name={"taskKind"} errors={errors.taskKind} type={2}/>
                    {error && <Alert message={error} type={"error"} className={classes.alert}/>}
                    <div className={classes.modalFooter}>
                        <Button type="primary" htmlType="submit">Save changes</Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default EditTaskModal;