import React, {useEffect, useState} from 'react';
import classes from "./style.module.css";
import TaskList from "./components/TaskList/TaskList";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import CreateTaskModal from "./TaskDetailsPage/components/modals/CreateTaskModal/CreateTaskModal";
import {Task} from "../../types/types";
import Filters from "./components/filters/Filters";

interface ITaskMainProps {
    tasks: Task []
}

const TasksMain: React.FC<ITaskMainProps> = ({tasks}) => {

    const [filteredTasks, setFilteredTasks] = useState(tasks);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setFilteredTasks(tasks);
    }, [tasks]);

    return (
        <div className={classes.taskDashboard}>
            <h2 className={classes.title}>My tasks</h2>
            <Filters tasks={tasks} setFilteredTasks={setFilteredTasks}/>
            <TaskList tasks={filteredTasks ?? []}/>
            <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => setIsModalOpen(true)} className={classes.createButton}>Create
                new
                task</Button>
            <CreateTaskModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </div>
    );
};

export default TasksMain;