import {FormInputTypesWithId} from "../../../types/inputs";


export type CustomSectionInputStateType = {
        [key: string]: {
            data: FormInputTypesWithId[]
        };
    };


export enum CustomSectionInputAction {
    SET_INIT = "SET_INIT",
    SET_SECTION = "SET_SECTION"
}


type SET_SECTION = {
    type: CustomSectionInputAction.SET_SECTION,
    payload: {
        section: string,
        data: FormInputTypesWithId[]
    }
}
type SET_INIT = {
    type: CustomSectionInputAction.SET_INIT,
    payload: {
        data: CustomSectionInputStateType
    }
}


export type  CustomSectionInputActionType = SET_SECTION | SET_INIT

export function customSectionInputReducer(state: CustomSectionInputStateType, action: CustomSectionInputActionType): CustomSectionInputStateType {
    switch (action.type) {
        case CustomSectionInputAction.SET_SECTION:
            return {
                ...state,
                [action.payload.section]: {
                    data: action.payload.data
                }
            }
        case CustomSectionInputAction.SET_INIT:
            return {
                ...state,
                ...action.payload.data
            }
        default:
            return state
    }
}