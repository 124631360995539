import {Task} from "../../../../types/types";
import {isNull} from "lodash";

export function flattenTasks(tasks: Task[]): Task[] {
    const result: Task[] = [];

    function flatten(task: Task) {
        result.push(task);
        if (task.children && task.children.length > 0) {
            task.children.forEach(flatten);
        }
    }

    tasks.forEach(flatten);

    return result;
}

const searchTaskByTitle = (searchTerm: string, task: Task): boolean => {

    if (isNull(task.title)) return false;

    if (searchTerm.length === 0) return true;

    const searchTermLower = searchTerm.toLowerCase();

    const matchInTitleOrText = (str: string) =>
        searchTermLower.split(' ').every(term =>
            str.toLowerCase().includes(term)
        );

    const isMatchInTitle = matchInTitleOrText(task.title || '');
    const isMatchInText = matchInTitleOrText(task.text);

    const isMatchInComments = task.comment.some(comment =>
        matchInTitleOrText(comment.text)
    );

    return isMatchInTitle || isMatchInText || isMatchInComments;
};

const searchTaskByStatus = (status: string | undefined, task: Task): boolean => {

    if (status === undefined) return true

    return task.status === status
}

const searchTaskByPriority = (priority: string | undefined, task: Task): boolean => {

    if (priority === undefined) return true

    return task.priority === priority
}

const searchTaskByType = (type: string | undefined, task: Task): boolean => {

    if (type === undefined) return true

    return task.taskKind === type
}

const searchTaskByUser = (user: string | undefined, task: Task): boolean => {

    if (user === undefined) return true

    return task.users.some(taskUser => taskUser.userName === user);
}


const searchTaskByGroup = (group: string | undefined, task: Task): boolean => {

    if (group === undefined) return true

    return task.groups.some(taskGroup => taskGroup.groupName === group);
}

const sortByCreatedTime = (sortValue: number | undefined, a: Task, b: Task) => {

    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);

    switch (sortValue) {
        case 0:
            return 0;
        case 1:
            return dateA.getTime() - dateB.getTime();
        case 2:
            return dateB.getTime() - dateA.getTime();
        default:
            return 0;
    }

}


const sortByUpdatedTime = (sortValue: number, a: Task, b: Task) => {

    const dateA = new Date(a.updatedDate);
    const dateB = new Date(b.updatedDate);

    switch (sortValue) {
        case 0:
            return 0;
        case 1:
            return dateA.getTime() - dateB.getTime();
        case 2:
            return dateB.getTime() - dateA.getTime();
        default:
            return 0;
    }

}


export const useFilters = (tasks: Task[], searchTerm: string, status: string | undefined, priority: string | undefined, type: string | undefined, user: string | undefined, group: string | undefined, sortValue: number) => {

    // If all filter values are null or undefined, return the initial array of tasks
    if (searchTerm.length === 0 && !status && !priority && !type && !user && !group && sortValue === 0) {
        return {filteredTasks: tasks};
    }

    const flattenedTasks = flattenTasks(tasks)

    const filteredTasks = flattenedTasks.filter(searchTaskByTitle.bind(null, searchTerm))
        .filter(searchTaskByStatus.bind(null, status))
        .filter(searchTaskByPriority.bind(null, priority))
        .filter(searchTaskByType.bind(null, type))
        .filter(searchTaskByUser.bind(null, user))
        .filter(searchTaskByGroup.bind(null, group))
        .toSorted(sortByCreatedTime.bind(null, sortValue))
        .toSorted(sortByUpdatedTime.bind(null, sortValue))


    return {filteredTasks: filteredTasks}
}