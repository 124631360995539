import {isNull} from "lodash";
import {WorkOrder} from "../../../../types/types";


const searchWorkOrderByTitle = (searchTerm: string, workOrder: WorkOrder): boolean => {

    if (isNull(workOrder.title)) return false;

    if (searchTerm.length === 0) return true;

    const searchTermLower = searchTerm.toLowerCase();

    const matchInTitleOrText = (str: string) =>
        searchTermLower.split(' ').every(term =>
            str.toLowerCase().includes(term)
        );

    const isMatchInTitle = matchInTitleOrText(workOrder.title || '');
    const isMatchInNotes = matchInTitleOrText(workOrder.notes || '');

    return isMatchInTitle || isMatchInNotes
};

const searchWorkOrderByStatus = (status: string | undefined, workOrder: WorkOrder): boolean => {

    if (status === undefined) return true

    return workOrder.statusId === status
}

const searchWorkOrderByUser = (user: string | undefined, workOrder: WorkOrder): boolean => {

    if (user === undefined) return true

    return workOrder.users.some(workOrderUser => workOrderUser.userName === user);
}


const searchWorkOrderByGroup = (group: string | undefined, workOrder: WorkOrder): boolean => {

    if (group === undefined) return true

    return workOrder.groups.some(workOrderGroup => workOrderGroup.groupName === group);
}

const sortByCreatedTime = (sortValue: number | undefined, a: WorkOrder, b: WorkOrder) => {

    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);

    switch (sortValue) {
        case 0:
            return 0;
        case 1:
            return dateA.getTime() - dateB.getTime();
        case 2:
            return dateB.getTime() - dateA.getTime();
        default:
            return 0;
    }

}


const sortByUpdatedTime = (sortValue: number, a: WorkOrder, b: WorkOrder) => {

    const dateA = new Date(a.updatedDate);
    const dateB = new Date(b.updatedDate);

    switch (sortValue) {
        case 0:
            return 0;
        case 1:
            return dateA.getTime() - dateB.getTime();
        case 2:
            return dateB.getTime() - dateA.getTime();
        default:
            return 0;
    }

}


export const useWorkOrderFilters = (workOrders: WorkOrder[] | undefined, searchTerm: string, status: string | undefined, user: string | undefined, group: string | undefined, sortValue: number) => {
    // If all filter values are null or undefined, return the initial array of tasks
    if (searchTerm.length === 0 && !status && !user && !group && sortValue === 0) {
        return {filteredWorkOrders: workOrders};
    }

    const filteredWorkOrders = workOrders?.filter(searchWorkOrderByTitle.bind(null, searchTerm))
        .filter(searchWorkOrderByStatus.bind(null, status))
        .filter(searchWorkOrderByUser.bind(null, user))
        .filter(searchWorkOrderByGroup.bind(null, group))
        .toSorted(sortByCreatedTime.bind(null, sortValue))
        .toSorted(sortByUpdatedTime.bind(null, sortValue))


    return {filteredWorkOrders: filteredWorkOrders}
}