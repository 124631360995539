import * as yup from "yup";

export const TaskSchema = yup
    .object({
        title: yup
            .string().required("Title is required"),
        text: yup
            .string().required("Description is required"),
        status: yup
            .string().required(),
        priority: yup.string().required(),
        taskKind: yup.string().required(),
    })
    .required()

export const WorkOrderSchema = yup
    .object({
        title: yup
            .string().required("Please insert work order title"),
        notes: yup
            .string(),
        status: yup
            .string(),
        vinNumber: yup.string().length(17, "Vin number must be of length 17"),
        odometer: yup.number(),
        priority: yup.string(),
        workOrderKind: yup.string().required(),
        carName: yup.string().required("Please insert car name"),
        carModel: yup.string(),
        carManufacturer: yup.string(),
        lat: yup.number(),
        lon: yup.number()
    })

