import React from 'react';
import classes from "../styles.module.css";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

interface StepsFooterProps {
    currentStep: number;
    setCurrentStep: (step: number) => void;
    handleSaveWorkOrder: () => void;
    customData: any;
}

const StepsFooter: React.FC<StepsFooterProps> = ({ currentStep, setCurrentStep, handleSaveWorkOrder, customData }) => {
    const { t } = useTranslation();
    const hasExtraStep = !!customData; // Ensures proper boolean check
    const finalStep = hasExtraStep ? 4 : 3; // Last step index

    return (
        <div className={classes.stepButtonContainer}>
            {/* Back button - visible on all steps except first */}
            {currentStep > 0 && (
                <Button onClick={() => setCurrentStep(currentStep - 1)}>
                    {t("template_page.back")}
                </Button>
            )}

            {/* Show Next button for all steps except the last one */}
            {currentStep < finalStep && (
                <Button type="primary" onClick={handleSaveWorkOrder}>
                    {t("template_page.next")}
                </Button>
            )}

            {/* Show Save button only on the final step */}
            {currentStep === finalStep && (
                <Button type="primary" onClick={handleSaveWorkOrder}>
                    {t("template_page.save")}
                </Button>
            )}
        </div>
    );
};

export default StepsFooter;
