// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_fieldAlert__Hfvq- {
    color: red;
    margin-top: 10px;
}

.style_selectAlert__2NPsk {
    color: red;
}

.style_column__M2-tT {
    display: flex;
    gap: 10px;
    flex-direction: column;
    max-height: 650px;
    overflow-y: auto
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/style.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,iBAAiB;IACjB;AACJ","sourcesContent":[".fieldAlert {\n    color: red;\n    margin-top: 10px;\n}\n\n.selectAlert {\n    color: red;\n}\n\n.column {\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n    max-height: 650px;\n    overflow-y: auto\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldAlert": `style_fieldAlert__Hfvq-`,
	"selectAlert": `style_selectAlert__2NPsk`,
	"column": `style_column__M2-tT`
};
export default ___CSS_LOADER_EXPORT___;
