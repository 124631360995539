import React from "react";

export interface ITaskStatus {
    inProgress: React.ReactElement;
    beingDelivered: React.ReactElement;
    done: React.ReactElement;
}

export interface ITaskType {
    installation: React.ReactElement;
    uninstallation: React.ReactElement;
    maintenance: React.ReactElement;
}

export interface ITaskPriority {
    low: React.ReactElement;
    medium: React.ReactElement;
    high: React.ReactElement;
}


export type Task = {
    id: string
    parentTaskId: string
    priority: keyof ITaskPriority
    status: keyof ITaskStatus
    taskKind: keyof ITaskType
    text: string
    title: string
    children: Task[] | []
    updatedDate: string
    createdDate: string
    comment: Comment[]
    isGrandchild: boolean;
    users: User[]
    groups: Group[]
}

export type TaskLog = {
    id: string,
    createdDate: string,
    typeAction: string,
    actionDetails: Object,
    whoDid: string
}

export type Group = {
    id: string,
    groupName: string,
    groupId?:string
}

export type TaskDetails = {
    title: string,
    text: string,
    priority: keyof ITaskPriority
    status: keyof ITaskStatus
    taskKind: keyof ITaskType
    id: string,
    createdDate: string,
    updatedDate: string,
    logAction: [
        {
            title: string,
            id: string,
            createdDate: string,
            updatedDate: string,
            typeAction: string,
            actionDetails: string [],
            whoDid: string,
            user: [
                {
                    id: string,
                    createdDate: string,
                    updatedDate: string,
                    userName: string,
                    blocked: true,
                    adminLevel: false
                }
            ]
        }
    ],
    groups: Group[]
    users: User[]
    grandParentTask: null | {
        name: string,
        id: string
    }
    parentTask: null | {
        name: string,
        id: string
    }
    companyName: string;
    companyId:string;
    invoiceCompanyId: string;
    vehicleCompanyId: string;
    filesAttached: File[]
}


export type UserDataType = {
    id: string
    roles: string[],
    person: {
        id: string
        name: string
    },
    groups: [
        {
            id: string
            roleName: string
        }
    ],
    userName: string
    adminLevel: boolean
    iat: number
    exp: number
}

export type Person = {
    id: string,
    name: string
    phone: string
    email: string
    address: string
}

export type Company = {
    id: string,
    name: string
    parentCompanyId: string
}
export type WorkUnit = {
    id: string,
    name: string
    externalId: string
}

export type DocumentNumbering = {
    id: string
    createdDate: string,
    updatedDate: string,
    name: string,
    useWorkUnit: boolean,
    useUser: boolean,
    dateSegmet: string,
    display: string
}

export type User = {
    id: string,
    userName: string
    personName: string
    userId?: string
}


export enum taskTypes {
    status,
    priority,
    kind
}

export type TaskComment = {
    taskId: string;
    text: string;
}

export type Comment = {
    text: string
    id: string
    whoCommented: string
    createdDate: string
    updatedDate: string
}

export type WorkOrder =  {
    id: string
    createdDate: string,
    updatedDate: string,
    title: string,
    notes: string,
    statusId: string,
    statusName: string,
    statusColor: string,
    roles: Role[]
    users: User[]
    groups: Group[]
    customData: any
}


export type Role = {
    claimId?: string,
    roleId?: string,
    roleName:string
    limited?: boolean
    id?: string
}


export type TemplateRole = {
    id: string,
    roleName?: string
    limited: boolean
}

export type WorkOrderDetails =  {
    id: string
    createdDate: string,
    updatedDate: string,
    title: string,
    notes: string,
    filesAttached: File[]
    order_template: any
    statusId: string,
    statusName: string,
    statusColor: string,
    statusDateTime: string
    roles: Role[]
    users: User[]
    groups: Group[],
    status_list_id: string
    customData: any
}

export type File = {
    url: string
    name: string
}


export type TemplateSchema = {
    schemaContent: any[],
    title: string,
    statusListId: string
    roles: Role[],
    status_fields: any
}

export type Product = {
    id: string;
    name: string,
    serialNumber: boolean,
    primary: boolean,
    createdDate: string,
    updatedDate: string
    units?: string
}

export type Addon = {
    id: string;
    name: string,
    serialNumber: boolean,
    primary: boolean,
    createdDate: string,
    updatedDate: string
    units?: string;
    itemNumber: string;
    productName?:string
}

export type StatusList = {
    name: string,
    status: Status[]
}

export type StatusListTemplate = {
    name: string,
    id: string
}

export type Status = {
    id: string,
    name: string,
    color: string,
    createdDate: string,
    updatedDate: string
}

export enum SectionEnum {
    Heading= "heading",
    Materials = "materials",
    Expenses= "expenses",
}

