import React, {useState} from 'react';
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {unAssignTask} from "../../../../services/tasks";
import classes from "../../style.module.css";
import {Tag} from "antd";
import WarningModal from "../../../../components/modals/WarningModal/WarningModal";
import {useTranslation} from "react-i18next";


interface IListTaskItemProps {
    data: any;
    type: string;
    taskId?: string
    setError: (error: string) => void
    setIsModalOpen: (open: boolean) => void
}


const ListTaskItem: React.FC<IListTaskItemProps> = ({data, type, taskId, setError, setIsModalOpen}) => {
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const {t} = useTranslation();

    const queryClient = useQueryClient();
    const params = useParams<{ id: string }>();

    const mutation = useMutation({
        mutationFn: ({id, userIds, groupIds}: { id: string | undefined; userIds: string[]; groupIds: string[] }) =>
            unAssignTask(id, userIds, groupIds),
        onSuccess: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({
                queryKey: ['taskDetails', params.id],
            });
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    const noDataMessage = type === 'user'
        ? <>{t("task_details.no_users")}</>
        : <>{t("task_details.no_groups")}</>;

    const handleUnassignUserGroup = (id: string) => {
        mutation.mutate({
            id: taskId,
            userIds: type === 'user' ? [id] : [],
            groupIds: type === 'group' ? [id] : [],
        });
        setIsWarningModalOpen(false);
    };

    if (data.length === 0) {
        return <>{noDataMessage}</>;
    }

    return (
        <div className={classes.tagItems}>
            {data.map((item: any) => {
                const itemName = type === 'user' ? item.userName : item.groupName;
                return (
                    <div key={item.id} className="tag-items">
                        <Tag
                            closeIcon
                            onClose={(e) => {
                                e.preventDefault();
                                setSelectedItem(itemName || '');
                                setIsWarningModalOpen(true);
                            }}
                        >
                            {itemName}
                        </Tag>
                        {selectedItem === itemName && (
                            <WarningModal
                                deleteText={"Unassign"}
                                data={selectedItem}
                                title="Are you sure you want to unassign this item?"
                                handler={() => handleUnassignUserGroup(item.id)}
                                isModalOpen={isWarningModalOpen}
                                setIsModalOpen={setIsWarningModalOpen}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ListTaskItem;