// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_row__pegu8 {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
}

.style_modalContainer__0FEGz {
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-bottom: 10px;
}

.style_tag__Nsn-\\+ {
    margin-bottom: 5px;
}

.style_icon__iye6T{
    margin-right: 5px;
}

.style_alert__-NPS4 {
    color:red;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tasks/TaskDetailsPage/components/modals/AssignModal/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    max-width: 100%;\n}\n\n.modalContainer {\n    display: flex;\n    flex-direction: column;\n    gap:10px;\n    margin-bottom: 10px;\n}\n\n.tag {\n    margin-bottom: 5px;\n}\n\n.icon{\n    margin-right: 5px;\n}\n\n.alert {\n    color:red;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `style_row__pegu8`,
	"modalContainer": `style_modalContainer__0FEGz`,
	"tag": `style_tag__Nsn-+`,
	"icon": `style_icon__iye6T`,
	"alert": `style_alert__-NPS4`
};
export default ___CSS_LOADER_EXPORT___;
