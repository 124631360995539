import React, {useState} from 'react';
import {Alert, Card, Modal, Spin} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getTemplates} from "../../../services/template";
import classes from "../styles.module.css"
import {PlusOutlined} from "@ant-design/icons";
import TemplateItem from "../components/TemplateItem";
import {useNavigate} from "react-router-dom";

interface TemplateModalProps {
    isModalOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void
}

const TemplateModal: React.FC<TemplateModalProps> = ({isModalOpen, setIsModalOpen}) => {

    const [error, setError] = useState("");
    const navigate = useNavigate()

    const {data: templates, isLoading} = useQuery({
        queryFn: () => getTemplates(),
        queryKey: ["templates"]
    })

    const handleOk = () => {
        setIsModalOpen(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    if (isLoading) {
        return <Modal width={"50vw"} title="Choose template" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Spin/>
        </Modal>
    }

    if(templates) {
        return (
            <Modal width={window.innerWidth <= 768 ? "90vw" : "60vw"} title="Choose template" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className={classes.templateCardContainer}>
                    {templates.map((item: any) => {
                        return <TemplateItem setError={setError} setIsModalOpen={setIsModalOpen} item={item} key={item.id} />
                    })}

                    <Card className={classes.createCard} onClick={()=> navigate("/work_orders/template")}><PlusOutlined/><h3>create new template</h3></Card>
                </div>
                {error && <Alert type="error" message={error} className={classes.alert}/>}
            </Modal>
        );
    }
};

export default TemplateModal;