import React, {useState} from 'react';
import {Alert, Button, Popover, Space, Table, TableProps, Tag} from "antd";
import {Role, WorkOrder} from "../../../types/types";
import {
    ArrowRightOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteWorkOrder} from "../../../services/workOrders";
import classes from "../styles.module.css";
import useUtils from "../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";


interface DataType extends WorkOrder {
}


const getColumns = (formatDate: any, t: any) => {
    return [
        {
            title: <>{t("table.title")}</>,
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => <a style={{color: "black", fontWeight: "bold"}}
                                         href={`/work_orders/${record.id}`}>{text}</a>,
        },
        {
            title: <>{t("table.notes")}</>,
            dataIndex: 'notes',
            key: 'notes',
            render: (notes) => <div style={{maxWidth: "700px", whiteSpace: "pre-wrap"}}>{notes}</div>,
        },
        {
            title: <>{t("table.roles")}</>,
            dataIndex: 'roles',
            key: 'roles',
            render: (roles) => (
                <div>
                    {roles.map((role: Role, index: number) => {
                        if (index < 3) {
                            return (
                                <Tag key={index} color={role.claimId ? "green" : "default"}>
                                    {role.roleName}
                                </Tag>
                            );
                        } else if (index === 3) {
                            return (
                                <Tag key="more" color="blue">
                                    + {Math.abs(roles.length - 3)} more
                                </Tag>
                            );
                        }
                        return null;
                    })}
                </div>
            ),
        },
        {
            title: <>{t("table.status")}</>,
            dataIndex: 'statusColor',
            key: 'status',
            render: (statusColor, record) => (
                <Tag key={record.statusId} color={statusColor} style={{maxWidth: "700px", whiteSpace: "pre-wrap"}}>
                    {record.statusName}
                </Tag>
            ),
        },
        {
            title: <>{t("section.extra")}</>,
            dataIndex: 'customData',
            key: 'customData',
            render: (customData) => {
                if (!customData || customData.length === 0) return null;

                // Check if any input item has an empty or falsy value
                const hasEmptyValue = customData.some((item: any) => !item.value);

                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Popover
                            content={
                                <>
                                    {customData.map((item: any) => (
                                        <DynamicInputRenderer disabled={true} item={item} key={item.id} />
                                    ))}
                                </>
                            }
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Button type="text">{t("work_order_details.input_extra")} ({customData.length})</Button>
                                {hasEmptyValue && (
                                    <span style={{alignItems:"center", display:"flex"}}><ExclamationCircleOutlined style={{ fontSize: '20px', color:"red" }} size={30}/></span>
                                )}
                            </div>
                        </Popover>
                    </div>
                );
            },
        },
        {
            title: <>{t("section.extra")}</>,
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (date) => <div>{formatDate(date)}</div>,
        },
        {
            title: <>{t("table.actions")}</>,
            key: 'action',
            render: (_, record) => <ButtonActions {...record}/>

        },
    ] as TableProps<DataType>['columns'];
}


interface TableViewProps {
    data: WorkOrder[] | undefined
}

const TableView: React.FC<TableViewProps> = ({data}) => {

    const {formatDate} = useUtils()
    const {t} = useTranslation()

    const columns = getColumns(formatDate, t)

    return (
        <Table<DataType> columns={columns} dataSource={data} scroll={{x: 'max-content'}}/>
    );
};

export default TableView;

const ButtonActions: React.FC<DataType> = ({id, title}) => {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const queryClient = useQueryClient()
    const [error, setError] = useState("");
    const {t} = useTranslation()

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteWorkOrder(id),
        onSuccess: async () => {
            setIsModalOpen(false);
            setError("");
            await queryClient.invalidateQueries({queryKey: ['workOrders']}).catch(console.error)
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    const handleDeleteWorkOrder = (id: string) => {
        mutation.mutate({id});
    }

    return <Space size="middle">
        <Button type={"text"} onClick={() => navigate(`/work_orders/${id}/edit`)}
                icon={<EditOutlined/>}>{t("work_orders_page.edit")}</Button>
        <Button type={"text"} onClick={() => {
            navigate(`/work_orders/${id}`)
        }}
                style={{color: "#4A9BFF"}} icon={<ArrowRightOutlined/>}>{t("work_orders_page.details")}</Button>
        <Button type={"text"} style={{color: "red"}}
                icon={<DeleteOutlined/>}
                onClick={setIsModalOpen.bind(null, true)}>{t("work_orders_page.delete")}</Button>
        <WarningModal deleteText={"Delete"} title={t("warning_modal.title")}
                      handler={handleDeleteWorkOrder.bind(this, id)} isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen} data={title}/>
        {error && <Alert message={error} type={"error"} className={classes.alert}/>}
    </Space>
}
