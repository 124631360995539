import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import classes from "./style.module.css"

import {jwtDecode} from "jwt-decode";
import {useAuth} from "../context/AuthProvider";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

export const ProtectedRoute = () => {

    const {state} = useAuth()
    const token = state.token

    const {pathname} = useLocation()

    let isAuthenticated = true;

    try {
        const decodedToken: any = jwtDecode(token ?? "");
        if (Date.now() >= decodedToken?.exp * 1000) {
            isAuthenticated = false
        }

    } catch (err) {
        isAuthenticated = false
        console.log(err)
    }

    if (!isAuthenticated) {
        // If not authenticated, redirect to the login page
        localStorage.removeItem("token")

        return <Navigate to="/login"/>;
    }

    if (pathname === "/") {
        return <Navigate to="/dashboard"/>;
    }

    // If authenticated, render the child routes
    return <div className={classes.pageContainer}>
        <Header/>
        <div className={classes.container}>
            <Outlet/>
        </div>
        <Footer/>
    </div>;
};