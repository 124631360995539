import React from 'react';
import {Select} from "antd";
import {useQuery} from "@tanstack/react-query";
import {FormProductList} from "../../../../types/inputs";
import {getProductList} from "../../../../services/products";

interface ProductListCreatorProps {
    productList: FormProductList;
    onInputChange: (id: string, field: string, value: string) => void
    value?: any;
}

const ProductListCreator:React.FC<ProductListCreatorProps> = ({productList, onInputChange = () => {}, value}) => {

    const onChange = (value: string) => {
        onInputChange(productList.id, 'type', value)
    };

    const {data: allProducts} = useQuery({
        queryFn: () => getProductList(),
        queryKey: ["productList"]
    })

    const options = allProducts?.map(p => {
        return {value:p.id, label: p.name}
    })

    return (
        <div>
            <Select
                defaultValue={value}
                style={{ minWidth: "200px", width:"20%" }}
                onChange={onChange}
                placeholder={"Select product type"}
                options={options}
            />
        </div>
    );
};

export default ProductListCreator;