import React, {useState} from 'react';
import classes from "./styles.module.css"
import {Button, Card, Divider, Dropdown, Input} from "antd";
import {FormOutlined, PlusOutlined} from "@ant-design/icons";
import {v4 as uuidv4} from 'uuid';
import {FormDate, FormInput, FormOption, FormProductList, FormSelect, FormTextarea} from "../../types/inputs";
import PreviewModal from "./modals/PreviewModal";
import {useNavigate} from "react-router-dom";
import ProductListCreator from "./components/inputCreators/ProductListCreator";
import DateCreator from "./components/inputCreators/DateCreator";
import SelectCreator from "./components/inputCreators/SelectCreator";
import TextareaCreator from "./components/inputCreators/TextareaCreator";
import InputCreator from "./components/inputCreators/InputCreator";
import {ReactSortable} from "react-sortablejs";
import StatusListDropdown from "./components/StatusListDropdown";
import RoleChooser from "./components/RoleChooser";
import {Role} from "../../types/types";

type SectionType = {
    heading: any[];
    materials: any[];
    expenses: any[];
}

const CreateWorkOrderTemplatePage = () => {

    const [productList, setProductList] = useState({
        id: uuidv4(),
        inputType: "productList",
        name: "productList",
        label: "Choose a product type",
        isRequired: false,
        type: "",
    })

    const [inputCreators, setInputCreators] = useState<SectionType>({
        heading: [],
        materials: [productList],
        expenses: []
    });

    const [title, setTitle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate()
    const [statusListId, setStatusListId] = useState<string | undefined>(undefined)
    const [roles, setRoles] = useState<Role[]>([])

    const handleRemoveInput = (id: string, section: string) => {
        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: inputCreators[section].filter((input: any) => input.id !== id)
        });
    };

    const handleCreateNewInput = (type: string, section: string) => {
        let newInput;
        switch (type) {
            case "input":
                newInput = {
                    id: uuidv4(),
                    inputType: "input",
                    type: "text",
                    name: "",
                    label: "",
                    isRequired: false,
                };
                break;
            case "textarea":
                newInput = {
                    id: uuidv4(),
                    inputType: "textarea",
                    name: "",
                    label: "",
                    isRequired: false
                };
                break;
            case "select":
                newInput = {
                    id: uuidv4(),
                    inputType: "select",
                    name: "",
                    label: "",
                    options: [],
                    isRequired: false
                };
                break;
            case "date":
                newInput = {
                    id: uuidv4(),
                    inputType: "date",
                    name: "",
                    label: "",
                    isRequired: false
                };
                break;
            default:
                return;
        }
        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: [...inputCreators[section], newInput]
        });
    };

    const handleInputChange = (id: string, section: string, field: string, value: string) => {
        //@ts-ignore
        const updatedSection = inputCreators[section].map((input: any) =>
            input.id === id ? {...input, [field]: value} : input
        );
        setInputCreators({
            ...inputCreators,
            [section]: updatedSection
        });
    };

    const handleCheckboxChange = (id: string, section: string, checked: boolean) => {
        //@ts-ignore
        const updatedSection = inputCreators[section].map((input: any) =>
            input.id === id ? {...input, isRequired: checked} : input
        );
        setInputCreators({
            ...inputCreators,
            [section]: updatedSection
        });
    };

    const handleAddOption = (inputValue: string, id: string, section: string) => {
        if (inputValue === "") return;
        const newValue = {value: inputValue, label: inputValue, id: uuidv4()};
        //@ts-ignore
        const updatedSection = inputCreators[section].map((input: any) =>
            input.id === id ? {...input, options: [...input.options, newValue]} : input
        );

        setInputCreators({
            ...inputCreators,
            [section]: updatedSection
        });
    };

    const handleRemoveOption = (e: React.MouseEvent, optionId: string, selectId: string, section: string) => {
        e.preventDefault();
        //@ts-ignore
        const updatedSection = inputCreators[section].map((input: any) =>
            input.id === selectId ? {
                ...input,
                options: input.options.filter((option: FormOption) => option.id !== optionId)
            } : input
        );

        setInputCreators({
            ...inputCreators,
            [section]: updatedSection
        });
    };

    const handleCreateNewTemplate = () => {
        setIsModalOpen(true)
    }

    const getDropdownItems = (section: string) => ([
        {
            key: '1',
            label: (
                <div
                    onClick={() => handleCreateNewInput("input", section)}
                    className={classes.inputLink}
                >
                    Input
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div
                    onClick={() => handleCreateNewInput("textarea", section)}
                    className={classes.inputLink}
                >
                    Textarea
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div
                    onClick={() => handleCreateNewInput("select", section)}
                    className={classes.inputLink}
                >
                    Select
                </div>
            ),
        },
        {
            key: '4',
            label: (
                <div
                    onClick={() => handleCreateNewInput("date", section)}
                    className={classes.inputLink}
                >
                    Date
                </div>
            ),
        },
    ])

    const componentMap: Record<any, any> = {
        input: (input: FormInput, section: string) => (
            <InputCreator
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                key={input.id}
                handleRemove={(id) => handleRemoveInput(id, section)}
                input={input}
            />
        ),
        textarea: (input: FormTextarea, section: string) => (
            <TextareaCreator
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                key={input.id}
                handleRemove={(id) => handleRemoveInput(id, section)}
                textarea={input}
            />
        ),
        select: (input: FormSelect, section: string) => (
            <SelectCreator
                handleAddOption={(inputValue, id) => handleAddOption(inputValue, id, section)}
                handleRemoveOption={(e, optionId, id) => handleRemoveOption(e, optionId, id, section)}
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                key={input.id}
                handleRemove={(id) => handleRemoveInput(id, section)}
                select={input}
            />
        ),
        date: (input: FormDate, section: string) => (
            <DateCreator
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                key={input.id}
                date={input}
                handleRemove={(id) => handleRemoveInput(id, section)}
            />
        ),
        productList: (input: FormProductList, section: string) => (
            <ProductListCreator onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                                productList={input} key={productList.id}/>
        )
    }

    function handleSetList(section: string, newItems: any[]) {
        setInputCreators((prev: any) => ({...prev, [section]: newItems}))
    }

    return (
        <div className={classes.templateContainer}>
            <div className={classes.createTemplateRow}>
                <h2>Create a new template <FormOutlined/></h2>
                <Button danger onClick={() => navigate("/work_orders")}>Cancel</Button>
            </div>
            <Card title={<h3 style={{color: "#1677FF"}}>Meta info</h3>}>
                <h3>New title:</h3>
                <Input style={{minWidth: "200px"}} type={"text"} onChange={(e) => setTitle(e.target.value)}
                       className={classes.templateTitle} placeholder={"New template title..."}/>
                <h3>Status list:</h3>
                <StatusListDropdown statusListId={statusListId} setStatusListId={setStatusListId}/>
                <h3>Roles:</h3>
                <RoleChooser roles={roles} setRoles={setRoles}/>
            </Card>
            <br/>
            <Card title={<h3 style={{color: "#1677FF"}}>Template</h3>}>
                {["heading", "materials", "expenses"].map((section: string) => (
                    <div key={section}>
                        <div className={classes.createTemplateRow}>
                            <h2>{section.charAt(0).toUpperCase() + section.slice(1)}</h2>
                            <Dropdown menu={{items: getDropdownItems(section)}} placement="bottom">
                                <Button icon={<PlusOutlined/>}>Add new field</Button>
                            </Dropdown>
                        </div>
                        <div className={classes.templateColumn}>
                            {/*//@ts-ignore*/}
                            <ReactSortable className={classes.templateColumn} list={inputCreators[section]}
                                           setList={handleSetList.bind(null, section)}>
                                {/*//@ts-ignore*/}
                                {inputCreators[section].map((input: any) => (
                                    componentMap[input.inputType] ? componentMap[input.inputType](input, section) : null
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                ))}
                <Divider/>
                <div className={classes.end}>
                    {Object.values(inputCreators).some(section => section.length > 0) &&
                        <Button className={classes.end} type={"primary"} onClick={handleCreateNewTemplate}>Create new
                            template</Button>}
                </div>
            </Card>
            <PreviewModal statusListId={statusListId} roles={roles} title={title} isModalOpen={isModalOpen}
                          setIsModalOpen={setIsModalOpen}
                          template={inputCreators}/>
        </div>
    )
};

export default CreateWorkOrderTemplatePage;
