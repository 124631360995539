import React from 'react';
import eu from "../../assets/eu.png";
import classes from "./style.module.css"

const Footer = () => {
    return (
        <div className={classes.footer}>
            <img alt="eu" src={eu} style={{width: 200}}/>
        </div>
    );
};

export default Footer;