import {createContext, Dispatch, ReactNode, useContext, useMemo, useReducer} from "react";
import {
    CustomSectionInputActionType,
    customSectionInputReducer,
    CustomSectionInputStateType
} from "./customSectionInputReducer";

interface CustomSectionInputProviderProps {
    children: ReactNode;
}

interface CustomSectionInputContextType {
    state: CustomSectionInputStateType,
    dispatch: Dispatch<CustomSectionInputActionType>
}


const CustomSectionInputContext = createContext<CustomSectionInputContextType | undefined>(undefined)


export const customInitSectionContextState: CustomSectionInputStateType  = {}

export const CustomSectionInputProvider = ({children}: CustomSectionInputProviderProps) => {

    const [state, dispatch] = useReducer(customSectionInputReducer, customInitSectionContextState)
    const contextValue = useMemo(()=>({state,dispatch}), [state, dispatch])


    return (
        <CustomSectionInputContext.Provider value={contextValue}>
            {children}
        </CustomSectionInputContext.Provider>
    )
}

export const useCustomSectionInputContext = () => {
    const context = useContext(CustomSectionInputContext)

    if (!context) {
        throw new Error('useSectionInputContext must be used within a SectionInputProvider')
    }
    return context
}