import React from 'react';
import {Button, Dropdown} from "antd";
import {PlusOutlined} from "@ant-design/icons";

interface ChooseProductInputProps {
    dropdownItems:  { key: string, label: React.ReactNode }[] | undefined
}

const ChooseProductInput:React.FC<ChooseProductInputProps> = ({dropdownItems}) => {

    return (
        <>
            <h3 style={{margin:"0"}}>Choose a product</h3>
            <Dropdown menu={{items: dropdownItems}} placement="bottom">
                <Button icon={<PlusOutlined/>}>Choose a product</Button>
            </Dropdown>

        </>
    );
};

export default ChooseProductInput;