import React from 'react';
import classes from "../style.module.css";
import AboutTaskSection from "./AboutTaskSection";
import {TaskDetails} from "../../../types/types";
import TaskHistorySection from "./TaskHistorySection";
import {Breadcrumb} from "antd";


interface ITaskSectionMainProps {
    task: TaskDetails
}

const TaskSectionMain: React.FC<ITaskSectionMainProps> = ({task}) => {

    const grandParentLink = `/tasks/${task.grandParentTask?.id}`
    const parentLink = `/tasks/${task.parentTask?.id}`

    return (
        <div className={classes.taskDashboard}>
            <Breadcrumb
                className={classes.breadCrumb}
                items={[
                    {
                        title: <a href="/tasks">Back to tasks</a>,
                    },
                    ...(task.grandParentTask ? [{
                        title: <a href={grandParentLink}>{task.grandParentTask?.name}</a>,
                    }] : []),
                    ...(task.parentTask ? [{
                        title: <a href={parentLink}>{task.parentTask?.name}</a>,
                    }] : []),
                    {
                        title: task.title
                    }
                ]}
            />
            <AboutTaskSection task={task}/>
            <TaskHistorySection/>
        </div>
    );
};

export default TaskSectionMain;