import React, { useEffect, useState } from 'react';
import classes from "./styles.module.css";
import {Button, Card, Divider, Dropdown, Input, Spin} from "antd";
import { EditOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid';
import {FormDate, FormInput, FormOption, FormProductList, FormSelect, FormTextarea} from "../../types/inputs";
import PreviewModal from "./modals/PreviewModal";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getTemplate } from "../../services/template";
import { formatDate } from "../../utils/globalFunctions";
import InputCreator from "./components/inputCreators/InputCreator";
import TextareaCreator from "./components/inputCreators/TextareaCreator";
import SelectCreator from "./components/inputCreators/SelectCreator";
import DateCreator from "./components/inputCreators/DateCreator";
import ProductListCreator from "./components/inputCreators/ProductListCreator";
import {ReactSortable} from "react-sortablejs";
import StatusListDropdown from "./components/StatusListDropdown";
import {Role} from "../../types/types";
import RoleChooser from "./components/RoleChooser";

const EditWorkOrderTemplatePage = () => {
    const params = useParams();
    const { data: template } = useQuery({
        queryFn: () => getTemplate(params.id ? params.id : ""),
        queryKey: ["template"]
    });

    const [inputCreators, setInputCreators] = useState<any[]>({
        //@ts-ignore
        heading: [],
        materials: [],
        expenses: []
    });
    const [title, setTitle] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusListId, setStatusListId] = useState(template?.statusList?.id ? template?.statusList?.id : undefined)
    const [roles, setRoles] = useState<Role[]>(template?.roles ?? [])



    useEffect(() => {
        if (template) {
            // Ensure template.schemaContent is an object
            const schemaContent = typeof template.schemaContent === 'object' && template.schemaContent !== null
                ? template.schemaContent
                : {};

            setTitle(template.title || "");

            setInputCreators({
                //@ts-ignore
                heading: schemaContent.heading || [],
                materials: schemaContent.materials || [],
                expenses: schemaContent.expenses || [],
            });
            setRoles(template?.roles ?? [])
            setStatusListId(template?.statusList?.id ?? undefined)
        }
    }, [template]);

    const navigate = useNavigate();

    const handleRemoveInput = (id: string, section: string) => {
        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: inputCreators[section].filter(input => input.id !== id)
        });
    };

    const handleCreateNewInput = (type: string, section: string) => {
        const newInput = {
            id: uuidv4(),
            inputType: type,
            name: "",
            label: "",
            isRequired: false,
            ...(type === "input" && { type: "text" }),
            ...(type === "select" && { options: [] })
        };

        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: [...inputCreators[section], newInput]
        });
    };

    const handleCreateNewTemplate = () => {
        setIsModalOpen(true);
    };

    const handleInputChange = (id: string, section: string, field: string, value: string) => {
        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: inputCreators[section].map(input =>
                input.id === id ? { ...input, [field]: value } : input
            )
        });
    };

    const handleCheckboxChange = (id: string, section: string, checked: boolean) => {
        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: inputCreators[section].map(input =>
                input.id === id ? { ...input, isRequired: checked } : input
            )
        });
    };

    const handleAddOption = (inputValue: string, id: string, section: string) => {
        if (inputValue === "") return;

        const newValue = { value: inputValue, label: inputValue, id: uuidv4() };

        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: inputCreators[section].map(input =>
                input.id === id ? { ...input, options: [...input.options, newValue] } : input
            )
        });
    };

    const handleRemoveOption = (e: React.MouseEvent, optionId: string, selectId: string, section: string) => {
        e.preventDefault();

        setInputCreators({
            ...inputCreators,
            //@ts-ignore
            [section]: inputCreators[section].map(input =>
                input.id === selectId ? {
                    ...input,
                    options: input.options.filter((option: FormOption) => option.id !== optionId)
                } : input
            )
        });
    };

    const getDropdownItems = (section: string) => ([
        {
            key: '1',
            label: <a onClick={() => handleCreateNewInput("input", section)} style={{ textDecoration: 'none' }}>Input</a>,
        },
        {
            key: '2',
            label: <a onClick={() => handleCreateNewInput("textarea", section)} style={{ textDecoration: 'none' }}>Textarea</a>,
        },
        {
            key: '3',
            label: <a onClick={() => handleCreateNewInput("select", section)} style={{ textDecoration: 'none' }}>Select</a>,
        },
        {
            key: '4',
            label: <a onClick={() => handleCreateNewInput("date", section)} style={{ textDecoration: 'none' }}>Date</a>,
        },
    ]);

    function handleSetList(section: string, newItems: any[]) {
        setInputCreators((prev: any) => ({...prev, [section]: newItems}))
    }

    const componentMap: Record<any, any> = {
        input: (input: FormInput, section: string) => (
            <InputCreator
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                key={input.id}
                handleRemove={(id) => handleRemoveInput(id, section)}
                input={input}
            />
        ),
        textarea: (input: FormTextarea, section: string) => (
            <TextareaCreator
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                key={input.id}
                handleRemove={(id) => handleRemoveInput(id, section)}
                textarea={input}
            />
        ),
        select: (input: FormSelect, section: string) => (
            <SelectCreator
                handleAddOption={(inputValue, id) => handleAddOption(inputValue, id, section)}
                handleRemoveOption={(e, optionId, id) => handleRemoveOption(e, optionId, id, section)}
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                key={input.id}
                handleRemove={(id) => handleRemoveInput(id, section)}
                select={input}
            />
        ),
        date: (input: FormDate, section: string) => (
            <DateCreator
                onInputChange={(id, field, value) => handleInputChange(id, section, field, value)}
                handleCheckboxChange={(id, checked) => handleCheckboxChange(id, section, checked)}
                key={input.id}
                date={input}
                handleRemove={(id) => handleRemoveInput(id, section)}
            />
        ),
        productList: (input: FormProductList, section: string) => (
            <ProductListCreator value={input.type} onInputChange={(id, field, value) => handleInputChange(id,section,field,value)} productList={input} key={input.id}/>
        ),
    };

    if(!template) return <Spin/>

    return (
        <div className={classes.templateContainer}>
            <div className={classes.createTemplateRow}>
                <h2>Edit template <FormOutlined/></h2>
                <Button danger onClick={() => navigate("/work_orders")}>Cancel</Button>
            </div>
            <Card title={<h3 style={{color:"#1677FF"}}>Meta info</h3>}>
            <span><EditOutlined/> Created at {formatDate(template.createdDate)}</span> | <span>Last updated at {formatDate(template.updatedDate)}</span>
            <h3>Title:</h3>
            <Input
                value={title}
                type={"text"}
                onChange={(e) => setTitle(e.target.value)}
                className={classes.templateTitle}
                placeholder={"New template title..."}
            />
            <h3>Status list:</h3>
            <StatusListDropdown statusListId={statusListId} setStatusListId={setStatusListId}/>
            <h3>Roles:</h3>
            <RoleChooser roles={roles} setRoles={setRoles}/>
            </Card>
            <br/>
            <Card title={<h3 style={{color: "#1677FF"}}>Template</h3>}>
                {["heading", "materials", "expenses"].map((section) => (
                    <div key={section} className={classes.sectionItem}>
                        <div className={classes.createTemplateRow}>
                            <h2>{section.charAt(0).toUpperCase() + section.slice(1)}</h2>
                            <Dropdown menu={{items: getDropdownItems(section)}} placement="bottom">
                                <Button icon={<PlusOutlined/>}>Add new field</Button>
                            </Dropdown>
                        </div>
                        <div className={classes.column}>
                            {/*//@ts-ignore*/}
                            <ReactSortable className={classes.templateColumn} list={inputCreators[section]}
                                           setList={handleSetList.bind(null, section)}>
                                {/*//@ts-ignore*/}
                                {inputCreators[section].map((input: any) => (
                                    componentMap[input.inputType] ? componentMap[input.inputType](input, section) : null
                                ))}
                            </ReactSortable>
                        </div>
                    </div>
                ))}
                <Divider/>
                <div className={classes.end}>
                    {Object.values(inputCreators).some(section => section.length > 0) &&
                        <Button className={classes.end} type={"primary"} onClick={handleCreateNewTemplate}>Update
                            template</Button>}
                </div>
            </Card>
            <PreviewModal
                roles={roles}
                isEdit={true}
                title={title}
                isModalOpen={isModalOpen}
                statusListId={statusListId}
                setIsModalOpen={setIsModalOpen}
                template={inputCreators}
            />
        </div>
    );
};

export default EditWorkOrderTemplatePage;
