import React, {useState} from 'react';
import {AutoComplete} from "antd";
import {find} from 'lodash'
import {SearchOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
import {getAutoComplete} from "../../../../services/autocomplete";
import {useQuery} from "@tanstack/react-query";
import {Group, User} from "../../../../types/types";
import classes from "../../style.module.css"


interface Props {
    setAssignedUsers: (users: any) => void
    setAssignedGroups: (groups: any) => void
    assignedUsers: any[]
    assignedGroups: any[]
    users: User[]
    groups: Group[]
}

const UsersGroupsAutocomplete: React.FC<Props> = (props) => {
    const {
        setAssignedUsers,
        setAssignedGroups,
        assignedGroups,
        assignedUsers,
        users,
        groups
    } = props


    const [term, setTerm] = useState('');

    const handleListQuery = ({queryKey}: { queryKey: string[] }) => {
        const [, newTerm] = queryKey;
        if (newTerm === "") return []
        return getAutoComplete(newTerm);
    }

    const autoCompleteQuery = useQuery({queryKey: ['autoCompleteList', term], queryFn: handleListQuery})

    const autoCompleteList = autoCompleteQuery.data?.map((option: any) => {

        return option.type === 'user' ?
            {
                value: option.id,
                userName: option.userName,
                type: 'user',
                label: <div className={classes.row}> {option.userName} <UserOutlined/></div>
            } :
            {
                value: option.id,
                groupName: option.groupName,
                type: 'group',
                label: <div className={classes.row}> {option.groupName} <TeamOutlined/></div>
            }
    }).filter(
        (option: any) => !(!!find(assignedUsers.concat(assignedGroups).concat(users).concat(groups), ['id', option.value]))
    )


    const handleSelect = (value: string, option: any) => {

        if (option.type === 'user') {
            setAssignedUsers((prev: any) => [...prev, {id: value, userName: option.userName}])
        } else if (option.type === 'group') {
            setAssignedGroups((prev: any) => [...prev, {id: value, groupName: option.groupName}])
        }
        setTerm('')
    }


    return (
        <div className={classes.assignContainer}>
            <AutoComplete
                suffixIcon={<SearchOutlined/>}
                options={autoCompleteList ?? []}
                onChange={(newValue: string) => {
                    setTerm(newValue)
                }}
                style={{width: "100%"}}
                value={term}
                onSelect={handleSelect}
                aria-errormessage={autoCompleteQuery.error?.message}
                placeholder="assign user or group"
            />
        </div>
    );
};

export default UsersGroupsAutocomplete;