import React, {useState} from 'react';
import {Button, message, Upload, UploadFile, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {uploadFiles} from "../../../services/files";
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";

interface FileUploadProps {
    itemId: string
    fileFolderName: string
}

const FileUpload: React.FC<FileUploadProps> = ({itemId, fileFolderName}) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    const handleUpload = async () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('files', file as any);
        });

        setUploading(true);

        try {
            await uploadFiles(itemId, fileFolderName, formData)

            setTimeout(() => {
                //@ts-ignore
                queryClient.invalidateQueries(['workOrderDetails', itemId])
                //@ts-ignore
                queryClient.invalidateQueries(['taskDetails', itemId])
                // Set uploading to false after queries are invalidated
                setUploading(false);
                setFileList([]);
                message.success('Uploaded successfully.');
            }, 2500); // 3-second delay
        } catch (e) {
            message.error('Upload failed.');
        }
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    return (
        <div>
            <Upload {...props}>
                <Button icon={<UploadOutlined/>}>{t("work_order_details.upload_files")}</Button>
            </Upload>
            {fileList.length > 0 && (
                <Button
                    type="primary"
                    onClick={handleUpload}
                    loading={uploading}
                    style={{marginTop: 16}}
                >
                    {uploading ? 'Uploading' : 'Start Upload'}
                </Button>
            )}
        </div>
    );
};

export default FileUpload;