import React from 'react';
import classes from "./style.module.css"
import {useAuth} from "../../context/AuthProvider";


export default function DashboardPage() {

    const {state} = useAuth()
    const userData = state.userData

    return (
            <div className={classes.dashboard}>
                <h2>Hello {userData?.person.name}</h2>
            </div>
    )
}

