import React from 'react';
import {Select} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getRoles} from "../../../services/roles";
import {useTranslation} from "react-i18next";

interface AddInputRolesProps {
    onInputChange: (value: string[]) => void
    defaultValue?: string[]
}

const AddInputRoles:React.FC<AddInputRolesProps> = ({onInputChange, defaultValue}) => {

    const {data: rolesList} = useQuery({
        queryFn: () => getRoles(),
        queryKey: ["rolesList"]
    })

    const {t} = useTranslation()

    const handleChange = (value: string[]) => {
        onInputChange(value)
    };

    const options = Array.isArray(rolesList)
        ? rolesList.map((r: any) => ({value: r.roleId, label: r.roleName}))
        : [];

    return (
        <Select
            mode="multiple"
            allowClear
            defaultValue={defaultValue}
            style={{ width: '250px' }}
            placeholder={t("template_page.add_roles")}
            onChange={handleChange}
            options={options}
        />
    );
};

export default AddInputRoles;