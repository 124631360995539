import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Result} from "antd";
import {useTranslation} from "react-i18next";

const PageNotFound = () => {

    const navigate = useNavigate()
    const {t} = useTranslation()

    const handleGoHome = () => {
        navigate(-1); // go to previous page
    }

    return (
            <Result
                status="404"
                title={t("not_found.title")}
                subTitle={t("not_found.message")}
                extra={<Button type="primary" onClick={handleGoHome}>{t("not_found.back")}</Button>}
            />
    );
};


export default PageNotFound;