import React from 'react';
import {Select, Spin} from "antd";
import {useQuery} from "@tanstack/react-query";
import {FormProductList} from "../../../../types/inputs";
import {getProductList} from "../../../../services/products";
import {isEmpty} from "lodash";
import {useTranslation} from "react-i18next";
import classes from "../../styles.module.css";

interface ProductListCreatorProps {
    productList: FormProductList;
    onInputChange: (id: string, field: string, value: string) => void;
}

const ProductListCreator: React.FC<ProductListCreatorProps> = ({
                                                                   productList, onInputChange = () => {
    }
                                                               }) => {

    const onChange = (selectedValue: string) => {
        onInputChange(productList.id, 'type', selectedValue);
    };

    const {data: allProducts, isLoading} = useQuery({
        queryFn: getProductList,
        queryKey: ["productList"]
    });

    const options = allProducts?.map((p: any) => ({value: p.id, label: p.name})) || [];
    const {t} = useTranslation();

    return (
        <div>
            {isLoading ? (
                <Spin/>
            ) : (
                <>

                    <Select
                        value={isEmpty(productList.type.trim()) ? undefined : productList.type} // Use controlled value instead of defaultValue
                        style={{minWidth: "200px", width: "20%"}}
                        onChange={onChange}
                        placeholder={t("template_page.select_product_type")}
                        options={options}
                        loading={isLoading} // Show loading inside Select if data is fetching
                    />
                    <span className={classes.red}> *</span>
                </>
            )
            }
        </div>
    )
        ;
};

export default ProductListCreator;
