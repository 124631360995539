// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__FOoIB {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

.style_directoryContainer__ypZP5 {
    width: 15%;
    /*border-right: 2px solid #E6EFFC;*/
}

.style_markDownContainer__4sczG {
    width: 75%;
    margin-left: 15px;
}

.style_sourceDirectory__Bju9f {
    margin: 0 15px 15px;
}

.style_title__q9FdJ {
    margin-left: 15px;
}

.style_buttonContainer__dYn8a {
    display: flex;
    flex-direction: row;
    gap:10px;
    justify-content: flex-end;
}

.style_markdown__Uxt0w .wmde-markdown-var .w-md-editor .w-md-editor-show-live {
    height: auto;
}

/* Media query for mobile devices with maximum width of 600px */
@media only screen and (max-width: 600px) {
    .style_container__FOoIB {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .style_directoryContainer__ypZP5 {
        width: 100%;
    }

    .style_markDownContainer__4sczG {
        width: 80%;
        margin: 0 auto;
    }
;
}
`, "",{"version":3,"sources":["webpack://./src/pages/wiki/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,mCAAmC;AACvC;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA,+DAA+D;AAC/D;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,WAAW;IACf;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,UAAU;QACV,cAAc;IAClB;AAAA;AAEJ","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    margin: 15px 0;\n}\n\n.directoryContainer {\n    width: 15%;\n    /*border-right: 2px solid #E6EFFC;*/\n}\n\n.markDownContainer {\n    width: 75%;\n    margin-left: 15px;\n}\n\n.sourceDirectory {\n    margin: 0 15px 15px;\n}\n\n.title {\n    margin-left: 15px;\n}\n\n.buttonContainer {\n    display: flex;\n    flex-direction: row;\n    gap:10px;\n    justify-content: flex-end;\n}\n\n.markdown :global(.wmde-markdown-var .w-md-editor .w-md-editor-show-live) {\n    height: auto;\n}\n\n/* Media query for mobile devices with maximum width of 600px */\n@media only screen and (max-width: 600px) {\n    .container {\n        display: flex;\n        flex-direction: column;\n        width: 100%;\n    }\n\n    .directoryContainer {\n        width: 100%;\n    }\n\n    .markDownContainer {\n        width: 80%;\n        margin: 0 auto;\n    }\n;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__FOoIB`,
	"directoryContainer": `style_directoryContainer__ypZP5`,
	"markDownContainer": `style_markDownContainer__4sczG`,
	"sourceDirectory": `style_sourceDirectory__Bju9f`,
	"title": `style_title__q9FdJ`,
	"buttonContainer": `style_buttonContainer__dYn8a`,
	"markdown": `style_markdown__Uxt0w`
};
export default ___CSS_LOADER_EXPORT___;
