// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_footer__vxcNY {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".footer {\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n    padding: 1rem;\n    text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `style_footer__vxcNY`
};
export default ___CSS_LOADER_EXPORT___;
