import React from 'react';
import classes from "./TaskItem/style.module.css";
import {formatDate, getPriority, getStatus} from "../../../utils/globalFunctions";
import {Button, Card} from "antd";
import {CommentOutlined, PlusOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {Task} from "../../../types/types";
import TaskComments from "./TaskItem/TaskComments";

interface ITaskCardProps {
    task: Task
    setIsModalOpen: (open: boolean) => void
    setIsCommentModalOpen: (open: boolean) => void
}

const TaskCard:React.FC<ITaskCardProps> = ({task, setIsCommentModalOpen,setIsModalOpen}) => {

    const navigate = useNavigate();

    return (
        <Card className={classes.taskBody}>
            <div className={classes.row}>
                <p>{getStatus(task.status)}</p>
                <p>Priority: {getPriority(task.priority)}</p>
            </div>
            <div className={classes.taskContent}>
                <p className={classes.text}>{task.text}</p>
                <div className={classes.taskFooter}>
                    <div className={classes.taskActions}>
                        <Button type={"primary"} onClick={() => navigate(`/tasks/${task.id}`)}>
                            Go to details
                        </Button>
                        {!task.isGrandchild && (
                            <Button icon={<PlusOutlined/>} onClick={() => setIsModalOpen(true)}>
                                Create new subtask
                            </Button>
                        )}
                        <Button icon={<CommentOutlined/>} onClick={() => setIsCommentModalOpen(true)}>
                            Add new comment
                        </Button>
                    </div>
                </div>
            </div>
            <TaskComments taskId={task.id} comments={task.comment} />
            <span>Created at {formatDate(task.createdDate)}</span> |
            <span> Updated at {formatDate(task.updatedDate)}</span>
        </Card>
    );
};

export default TaskCard;