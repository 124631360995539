import genericService from "./genericService";

import getAxiosInstance from "./axiosInstance";
import {Role, TemplateSchema} from "../types/types";
const client = getAxiosInstance();

export const createTemplate = async (title:string, schemaContent: any, statusListId: string, roles: Role[]) => {

    return await genericService.genericPost<any, any>(
        `/templates`,
        {
            title:title,
            schemaContent: schemaContent,
            statusListId: statusListId,
            roles: roles
        }
    )
};

// export const getTemplates = async () => {
//     return await genericService.genericGet<any>("/templates")
// };

export const getTemplatesByUser = async () => {
    return await genericService.genericGet<any>("/templates/roles/by-user")
};

export const updateTemplate = async (id:string, title:string, schema:any, statusListId: string, roles: Role[]) => {
    return await genericService.genericPut<TemplateSchema,TemplateSchema>(
        `/templates/${id}`,
        {
            title:title,
            schemaContent: schema,
            statusListId: statusListId,
            roles: roles
        }
    )
};

export const getTemplate = async (id:string) => {
    return await genericService.genericGet<any>(`/templates/${id}`)
};

export const deleteTemplate = async (id:string) => {

    try {
        const res = await client.delete(`/templates/${id}`);
        return res.data

    } catch (error: string | any) {
        throw new Error(error.response.data.message);
    }
};