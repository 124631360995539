import React from 'react';
import classes from "../styles.module.css";
import {PaperClipOutlined} from "@ant-design/icons";
import {File} from "../../../types/types";
import {Image} from "antd";
import useUtils from "../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";

interface FilesListProps {
    files: File[];
}

const FilesList: React.FC<FilesListProps> = ({files}) => {

    const {isImageFile} = useUtils()
    const {t} = useTranslation()

    // Function to render a single file
    const renderFile = (item: File) => {
        if (isImageFile(item.name)) {
            // Render image file
            return (
                <Image
                    src={item.url}
                    alt={item.name}
                    style={{width:"250px", height:"250px", backgroundSize:"cover", borderRadius:"5%"}}
                />
            );
        } else {
            // Render non-image file as a link
            return (
                <a href={item.url} rel="noreferrer" target="_blank">
                    {item.name}
                </a>
            );
        }
    };

    // Separate image and non-image files
    const imageFiles = files.filter(file => isImageFile(file.name));
    const nonImageFiles = files.filter(file => !isImageFile(file.name));

    return (
        <>
            <h2>{t("work_order_details.files")}:</h2>
            {files.length > 0 ? (
                <>
                    {/* Images */}
                    <div className={classes.imagesContainer}>
                        <Image.PreviewGroup>
                        {imageFiles.map((item, index) => (
                            <span key={item.url + index}>{renderFile(item)}</span>
                        ))}
                        </Image.PreviewGroup>
                    </div>
                    <br/>
                    {/* Non-Image Files */}
                    <div className={classes.filesContainer}>
                        {nonImageFiles.map((item, index) => (
                            <span key={item.url + index}>
                                <PaperClipOutlined/>
                                {renderFile(item)}
                            </span>
                        ))}
                    </div>
                </>
            ) : (
                <p>{t("work_order_details.no_files")}</p>
            )}
            <br/>
        </>
    );
};

export default FilesList;
