import React from 'react';
import {Card} from "antd";
import {TaskLog} from "../../../types/types";
import classes from "../style.module.css";
import {formatDate} from "../../../utils/globalFunctions";


interface ITaskHistoryItemProps {
    log: TaskLog
}

const TaskHistoryItem:React.FC<ITaskHistoryItemProps> = ({log}) => {


    return (
        <Card className={classes.taskHistoryCard} key={log.id}>
            <div>Action: <b>{log.typeAction}</b></div>
            <div>Author: <span className={classes.authorText}>{log.whoDid}</span> created at {formatDate(log.createdDate)}</div>
            <div></div>
        </Card>
    );
};

export default TaskHistoryItem;