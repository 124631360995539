import React from 'react';
import {Breadcrumb, Skeleton} from "antd";
import classes from "./styles.module.css";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getWorkOrderDetails} from "../../services/workOrders";
import DetailsCard from "./components/DetailsCard";
import LogsCard from "./components/LogsCard";
import PageNotFound from "../not_found/PageNotFound";


const WorkOrderDetailsPage = () => {

    const params = useParams();

    const {data: workOrderDetails , isLoading} = useQuery({
        queryFn: () => getWorkOrderDetails(params.id),
        queryKey: ["workOrderDetails"]
    })

    if (isLoading) return <Skeleton active={true}/>

    if(!workOrderDetails) return <PageNotFound/>

    return (
        <div className={classes.outerContainer}>
            <Breadcrumb
                className={classes.breadCrumb}
                items={[
                    {
                        title: <a href="/work_orders">Back to work orders</a>,
                    },
                    {
                        title: workOrderDetails?.title
                    }
                ]}
            />
            <DetailsCard item={workOrderDetails}/>
            <LogsCard/>
        </div>
    )
};

export default WorkOrderDetailsPage;