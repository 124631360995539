import React, {useState} from 'react';
import MDEditor from "@uiw/react-md-editor";
import {Button, Divider, message, Upload, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import classes from "../style.module.css"
import {useTranslation} from "react-i18next";

const Markdown = () => {

    const [toggleEdit, setToggleEdit] = useState(false);
    const [value, setValue] = React.useState("# Iveco Daily\n" +
        "U privitku su slike spajanja CAN-a na novi Iveco Daily 2020. godište. Na OBD-u gdje smo se do sad spajali više nema korisnih podataka. Dolazi samo jedan podatak na brzini od 500kbps i potpuno nam je beskoristan.\n" +
        "\n" +
        "Spajamo se na jedan konektor s plavom kopčom koji stoji okomito (postoji  i jedan koji je vodoravan). Slike su u privitku, tu smo od podataka dobili sve što nam treba. Standardni je FMS na brzini od 500 kbps.\n" +
        "\n" +
        "![image](https://cloud.internal.artronic.net/index.php/apps/files_sharing/publicpreview/Qb8nWL7tyBanXXf?file=/&fileId=5021835&x=2560&y=1440&a=true&etag=abe883776c5dbe4c36c748b5f3def8ec)\n" +
        "\n" +
        "![image](https://cloud.internal.artronic.net/index.php/apps/files_sharing/publicpreview/7gXexkNp3j5Zr9k?file=/&fileId=5021834&x=2560&y=1440&a=true&etag=8f594fe52fa8801e0fdd777b748c8b88)");

    const props: UploadProps = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const {t} = useTranslation()

    return (
        <>
            <Button onClick={() => {
                setToggleEdit(!toggleEdit)
            }}>{t("wiki_page.toggle")}</Button>
            <br/><br/>
            {toggleEdit && <MDEditor
                className={classes.markdown}
                value={value}
                visibleDragbar={true}
                //@ts-ignore
                onChange={setValue}
            />}
            <br/>
            <MDEditor.Markdown source={value} style={{whiteSpace: 'pre-wrap', backgroundColor: 'white', color: 'black'}}/>
            <Divider/>
            <div>
                <Upload {...props}>
                    <Button icon={<UploadOutlined/>}>{t("wiki_page.click_to_upload")}</Button>
                </Upload>
            </div>
            <Divider/>
            <div className={classes.buttonContainer}>
                <Button danger>{t("wiki_page.cancel")}</Button>
                <Button>{t("wiki_page.save")}</Button>
            </div>
        </>
    )
};

export default Markdown;