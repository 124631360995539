import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Input, MenuProps, Select} from "antd";
import {
    ClockCircleOutlined,
    EditOutlined, FilterOutlined,
    SearchOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import classes from "./style.module.css"
import {getTypeData} from "../../../../utils/globalFunctions";
import {Task} from "../../../../types/types";
import {flattenTasks, useFilters} from "./useFilters";


interface IFIltersProps {
    tasks: Task[]
    setFilteredTasks: (tasks: Task[]) => void
}

function findAllUsersAndGroups(tasks: Task[]): { users: Set<string>, groups: Set<string> } {
    const flattenedTasks = flattenTasks(tasks);

    const users = new Set<string>();
    const groups = new Set<string>();

    flattenedTasks.forEach(task => {
        task.users.forEach(user => users.add(user.userName));
        task.groups.forEach(group => groups.add(group.groupName));
    });
    return {users, groups};
}


const Filters: React.FC<IFIltersProps> = ({tasks, setFilteredTasks}) => {

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [priority, setPriority] = useState<string | undefined>(undefined);
    const [type, setType] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<string | undefined>(undefined);
    const [group, setGroup] = useState<string | undefined>(undefined);

    const [sortValue, setSortValue] = useState<number>(0);

    const {filteredTasks} = useFilters(tasks, searchTerm, status, priority, type, user, group, sortValue)

    const {users, groups} = findAllUsersAndGroups(tasks);

    const userOptions = Array.from(users).map(user => {
        return {value: user, label: user}
    })

    const groupOptions = Array.from(groups).map(group => {
        return {value: group, label: group}
    })

    useEffect(() => {
        setFilteredTasks(filteredTasks)
    }, [searchTerm, status, priority, type, sortValue, user, group]);

    const handleChangeStatus = (value: string) => {
        setStatus(value)
    };

    const handleChangePriority = (value: string) => {
        setPriority(value)
    };

    const handleChangeType = (value: string) => {
        setType(value)
    };

    const handleChangeUser = (value: string) => {
        setUser(value)
    };

    const handleChangeGroup = (value: string) => {
        setGroup(value)
    };

    const handleClearFilters = () => {
        setSearchTerm("")
        setStatus(undefined)
        setPriority(undefined)
        setType(undefined)
        setUser(undefined)
        setGroup(undefined)
        setSortValue(0)
    }

    const handleChange = (value: number) => {
        setSortValue(value)
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <span className={classes.item}>Ascending <SortAscendingOutlined/></span>,
            onClick: () => handleChange(1),
        },
        {
            key: '2',
            label: <span className={classes.item}>Descending <SortDescendingOutlined/></span>,
            onClick: () => handleChange(2),
        },
        {
            key: '3',
            label: 'Cancel',
            onClick: () => handleChange(0),
        },
    ];

    return (
        <div className={classes.filterContainer}>
            <Input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className={classes.searchInput}
                   suffix={<SearchOutlined/>} placeholder="Search tasks"/>
            <div className={classes.selectContainer}>
                <Select
                    onChange={handleChangeStatus}
                    placeholder="status"
                    style={{width: 130}}
                    value={status}
                    allowClear
                    options={getTypeData(0)}
                />
                <Select
                    onChange={handleChangePriority}
                    placeholder="priority"
                    style={{width: 130}}
                    value={priority}
                    allowClear
                    options={getTypeData(1)}
                />
                <Select
                    onChange={handleChangeType}
                    placeholder="type"
                    style={{width: 130}}
                    value={type}
                    allowClear
                    options={getTypeData(2)}
                />
                <Select
                    onChange={handleChangeUser}
                    placeholder="user"
                    style={{width: 130}}
                    value={user}
                    allowClear
                    options={userOptions}
                />
                <Select
                    onChange={handleChangeGroup}
                    placeholder="group"
                    style={{width: 130}}
                    value={group}
                    allowClear
                    options={groupOptions}
                />
            </div>
            <div className={classes.buttonsContainer}>
                <div className={classes.buttons}>
                    <Dropdown menu={{items}} placement="bottom" arrow>
                        <Button><EditOutlined/>Created time</Button>
                    </Dropdown>
                    <Dropdown menu={{items}} placement="bottom" arrow>
                        <Button><ClockCircleOutlined/>Updated time</Button>
                    </Dropdown>
                </div>
            </div>
            <Button className={classes.clearButton} icon={<FilterOutlined/>} onClick={handleClearFilters}>Clear filters</Button>
        </div>
    );
};

export default Filters;