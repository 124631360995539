import React, {useState} from 'react';
import MDEditor from "@uiw/react-md-editor";
import {Button, Divider, message, Upload, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import classes from "../style.module.css"

const Markdown = () => {

    const [toggleEdit, setToggleEdit] = useState(false);
    const [value, setValue] = React.useState("# Simple heading\n" +
        "\n" +
        "- List item\n" +
        "- Another list item\n" +
        "\n" +
        "> Comment types\n" +
        "\n" +
        "[link to webpage](https://fieldhubdev.v1.artronic.net/dashboard)\n" +
        "\n" +
        "![image](https://cdn.pixabay.com/photo/2022/01/28/18/32/leaves-6975462_1280.png)");

    const props: UploadProps = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <>
            <Button onClick={() => {
                setToggleEdit(!toggleEdit)
            }}>Toggle edit</Button>
            <br/><br/>
            {toggleEdit && <MDEditor
                className={classes.markdown}
                value={value}
                visibleDragbar={true}
                //@ts-ignore
                onChange={setValue}
            />}
            <br/>
            <MDEditor.Markdown source={value} style={{whiteSpace: 'pre-wrap'}}/>
            <Divider/>
            <div>
                <Upload {...props}>
                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                </Upload>
            </div>
            <Divider/>
            <div className={classes.buttonContainer}>
                <Button danger>Cancel</Button>
                <Button>Save changes</Button>
            </div>
        </>
    )
};

export default Markdown;