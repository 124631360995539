import React, {useState} from 'react';
import {Button, Card} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import classes from "../styles.module.css";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteTemplate} from "../../../services/template";
import WarningModal from "../../../components/modals/WarningModal/WarningModal";

interface TemplateItemProps {
    item: any
    setIsModalOpen: (open:boolean) => void
    setError: (err:string) => void
    templateId: string
    setTemplateId: (templateId:string) => void

}

const TemplateItem:React.FC<TemplateItemProps> = ({item, setError,templateId,setTemplateId}) => {

    const navigate = useNavigate()
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const queryClient = useQueryClient()


    const handleDeleteTemplate = (id:string) => {
        mutation.mutate({id});
    }

    const mutation = useMutation({
        mutationFn: ({id}: { id: string }) =>
            deleteTemplate(id),
        onSuccess: async () => {
            setError("");
            await queryClient.invalidateQueries({queryKey: ['templates']}).catch(console.error)
            setIsWarningModalOpen(false);
        },
        onError: (error: Error) => {
            setError(error.message);
        },
    });

    return (
        <>
        <Card
            onClick={setTemplateId.bind(null,item.id)}
            style={templateId === item.id? { border: "2px solid #1890ff" } : {}}
            key={item.id}
            actions={[
                <Button type="text" onClick={() => navigate(`/work_orders/template/${item.id}`)} icon={<EditOutlined />}>
                    Edit
                </Button>,
                <Button type="text" style={{ color: "red" }} onClick={() => setIsWarningModalOpen(true)} icon={<DeleteOutlined />}>
                    Delete
                </Button>
            ]}
            className={classes.templateCard}
        >
            <h3>{item.title}</h3>
        </Card>
         <WarningModal title={"Are you sure you want to delete this template?"}
                  handler={handleDeleteTemplate.bind(this, item.id)} isModalOpen={isWarningModalOpen}
                  setIsModalOpen={setIsWarningModalOpen} data={item.title} deleteText={"Delete"}/>
        </>
    );
};

export default TemplateItem;
