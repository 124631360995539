import React, { useState } from 'react';
import classes from "../styles.module.css";
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";
import ChooseProductInput from "../../../components/inputs/ChooseProductInput";
import ProductInputList from '../../../components/inputs/ProductInputList';
import { FormProduct } from "../../../types/inputs";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteProductsInstalled} from "../../../services/workOrders";
import {Alert} from "antd";
import {useTranslation} from "react-i18next";

interface StepProps {
    workOrderDetails: any;
    dropdownItems: any;
    productInputs: FormProduct[]
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void

}

const Step1: React.FC<StepProps> = ({ workOrderDetails, dropdownItems, productInputs, setProductInputs }) => {

    const [error, setError] = useState("");
    const {t} = useTranslation()

    const queryClient = useQueryClient();


    const deleteMutation = useMutation<any, Error, { workOrderId: string, itemNumber: string }>({
        mutationFn: ({workOrderId, itemNumber}) => deleteProductsInstalled(workOrderId, itemNumber),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['productsInstalled']}).catch(console.error);
            setError("");
        },
        onError: (error) => {
            setError(error.message);
        }
    });


    const handleRemoveProductInput = (product: FormProduct) => {
        setProductInputs((prevInputs) =>
            prevInputs.filter((input) => input.itemNumber !== product.itemNumber)
        );

        deleteMutation.mutate({
            workOrderId: workOrderDetails?.id,
            itemNumber: product.itemNumber
        })
    };
    
    return (
        <div className={classes.column}>
            {workOrderDetails.order_template.schemaContent["materials"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `materials-${index}`}/>
            ))}
            <ChooseProductInput placeholder={t("work_order_details.product_placeholder")} dropdownItems={dropdownItems} setProductInputs={setProductInputs}/>
            <ProductInputList
                setProductInputs={setProductInputs}
                productInputs={productInputs}
                handleRemoveProductInput={handleRemoveProductInput}
            />
            {error && <Alert message={error} type={"error"} className={classes.errorText}/>}
        </div>
    );
};

export default Step1;
