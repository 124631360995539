import React from 'react';
import classes from "./style.module.css";
import {CommentOutlined} from "@ant-design/icons";
import CommentItem from "../../TaskDetailsPage/components/CommentItem";
import {Comment} from "../../../../types/types";
import {useTranslation} from "react-i18next";

interface TaskCommentsProps {
    comments: Comment[]
    taskId: string;
}

const TaskComments:React.FC<TaskCommentsProps> = ({comments, taskId}) => {

    const {t} = useTranslation()

    return (
        <div>
            {comments && comments.length === 0 ? (
                <h4 className={classes.mTop}>
                    <CommentOutlined/> {t("tasks_page.no_comments")}
                </h4>
            ) : (
                <>
                    <h3>
                        <CommentOutlined/> {t("tasks_page.last_comments")}
                    </h3>
                    {comments?.slice(0, 2).map((comment) => (
                        <CommentItem key={comment.id} comment={comment} taskId={taskId}/>
                    ))}
                </>
            )}
        </div>
    );
};

export default TaskComments;
