import React from 'react';
import classes from "../../styles.module.css";
import {Button, Card, Input} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import {FormTextarea} from "../../../../types/inputs";
import {useTranslation} from "react-i18next";
import AddInputRoles from "../../../templates/components/AddInputRoles";

interface TextareaCreatorProps {
    handleRemove: (remove: string) => void
    textarea: FormTextarea
    handleCheckboxChange: (id: string, checked: boolean) => void
    onInputChange: (id: string, field: string, value: string | string[]) => void
}

const TextareaCreator: React.FC<TextareaCreatorProps> = ({
                                                             textarea,
                                                             handleRemove,
                                                             handleCheckboxChange,
                                                             onInputChange
                                                         }) => {

    const {t} = useTranslation()

    return (
        <Card className={classes.inputCreatorCard}>
            <div className={classes.inputRow}>
                <h3>TextArea:</h3>
                <div className={classes.inputMiddleRow}>
                    <Input value={textarea.label} style={{width: "auto"}} placeholder={t("template_page.label")}
                           suffix={<span className={classes.red}>*</span>}
                           onChange={(e) => onInputChange(textarea.id, 'label', e.target.value)}/>
                    <Input value={textarea.name} style={{width: "auto"}} placeholder={  t("template_page.name")}
                           suffix={<span className={classes.red}>*</span>}
                           onChange={(e) => onInputChange(textarea.id, 'name', e.target.value)}/>

                </div>
                <div className={classes.row}>
                    <Checkbox checked={textarea.isRequired} onChange={(e) => {
                        handleCheckboxChange(textarea.id, e.target.checked)
                    }}>  {t("template_page.required")}</Checkbox>
                    <AddInputRoles defaultValue={textarea.roles} onInputChange={onInputChange.bind(null, textarea.id, 'roles')}/>
                    <Button  danger onClick={handleRemove.bind(this, textarea.id)}> {t("work_order_details.remove")}</Button>
                </div>
            </div>
        </Card>
    );
};

export default TextareaCreator;