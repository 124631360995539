import genericService from "./genericService";
import {Product, TemplateSchema} from "../types/types";
import {FormProduct} from "../types/inputs";
import {log} from "node:util";


export const getProductList = async () => {
    return await genericService.genericGet<Product[]>("/product-list")
};

export const getProductListById = async (id: string) => {
    return await genericService.genericGet<Product[]>(`/product-list/${id}`)
};

export const getProductAddons = async (id:string) => {
    return await genericService.genericGet<Product[]>(`/product/${id}/addons`)
};

