import React from 'react';
import classes from "../styles.module.css";
import DynamicInputRenderer from "../components/inputCreators/DynamicInputRenderer";
import ChooseProductInput from "../../../components/inputs/ChooseProductInput";
import ProductInputList from "../../../components/inputs/ProductInputList";
import {FormProduct} from "../../../types/inputs";
import {useTranslation} from "react-i18next";

interface StepProps {
    workOrderDetails: any
    dropdownItems: any
    productInputs: FormProduct[]
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void

}

const Step3: React.FC<StepProps> = ({workOrderDetails, dropdownItems, productInputs, setProductInputs}) => {

    const handleRemoveProductInput = (product: FormProduct) => {
        setProductInputs((prevInputs) =>
            prevInputs.filter((input) => input.itemNumber !== product.itemNumber)
        );
    };

    const {t} = useTranslation()

    return (
        <div className={classes.column}>
            <h1 style={{margin: "0 auto"}}>{t("work_order_details.preview")}</h1>
            {workOrderDetails.order_template.schemaContent["heading"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `heading-preview-${index}`}/>
            ))}
            {workOrderDetails.order_template.schemaContent["materials"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `materials-preview-${index}`}/>
            ))}
            <ChooseProductInput placeholder={t("work_order_details.product_placeholder")} dropdownItems={dropdownItems}
                                setProductInputs={setProductInputs}/>
            <ProductInputList
                setProductInputs={setProductInputs}
                productInputs={productInputs}
                handleRemoveProductInput={handleRemoveProductInput}
            />
            {workOrderDetails.order_template.schemaContent["expenses"]?.map((item: any, index: number) => (
                <DynamicInputRenderer item={item} key={item.id || `expenses-preview-${index}`}/>
            ))}
            {workOrderDetails.customData && workOrderDetails.customData.map((item: any) => (
                <DynamicInputRenderer item={item} key={item.id}/>
            ))}
        </div>
    );
};

export default Step3;
