import React, {useState} from 'react';
import {Modal, Alert, Steps, Button} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import classes from '../styles.module.css';
import {useMutation} from "@tanstack/react-query";
import {createTemplate, updateTemplate} from "../../../services/template";
import {SectionEnum} from "../../../types/types";
import {useSectionInputContext} from "../../templates/contex/sectionInputContext";
import {useTranslation} from "react-i18next";
import SectionSteps from "../components/SectionSteps";
import {useCustomSectionInputContext} from "../../templates/contex/customSectionInputContext"; // Adjust import path

interface PreviewModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    statusListId?: string
    roles: any
}

interface FooterProps {
    currentStep: number,
    setCurrentStep: (currentStep: number) => void
    handleOk: () => void
}

const Footer: React.FC<FooterProps> = ({currentStep, setCurrentStep, handleOk}) => {
    const {t} = useTranslation()
    const params = useParams();
    return <div className={classes.stepButtonContainer}>
        {currentStep !== 0 && (
            <Button onClick={() => setCurrentStep(currentStep - 1)}>
                {t("template_page.back")}
            </Button>
        )}
        {currentStep < 3 && (
            <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>
                {t("template_page.next")}
            </Button>
        )}
        {currentStep === 3 && (
            <Button htmlType="submit" type="primary" onClick={handleOk}>
                {params.id
                    ? t("template_page.update_template")
                    : t("template_page.create_new_template")}
            </Button>
        )}
    </div>
}

const PreviewModal: React.FC<PreviewModalProps> = ({isModalOpen, setIsModalOpen, title, statusListId, roles}) => {
    const [error, setError] = useState<string[]>([]);
    const navigate = useNavigate();
    const params = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const {t} = useTranslation()

    const {state} = useSectionInputContext()
    const {state: state2} = useCustomSectionInputContext()

    const heading = state[SectionEnum.Heading].data.map(item => item.data)
    const materials = state[SectionEnum.Materials].data.map(item => item.data)
    const expenses = state[SectionEnum.Expenses].data.map(item => item.data)

    const allInputsBySection = Object.values(state2).flatMap(
        section => section.data.map(item => item.data)
    );

    const allInputsBySectionWithKey = Object.fromEntries(
        Object.entries(state2).map(([key, section]) => [
            key,
            section.data.map(item => item.data)
        ])
    );


    const template = {
        heading,
        materials,
        expenses,
    }

    const mutation = useMutation<any, Error, { title: string, schemaContent: any, statusListId: string,status_fields: any }>({
        mutationFn: () => createTemplate(title, template, statusListId!, roles, allInputsBySectionWithKey),
        onSettled: () => {
            setError([]);
            setIsModalOpen(false);
            navigate("/work_orders");
        },
        onError: (error: any) => {
            setError(error.message);
        }
    });

    const updateMutation = useMutation<any, Error, {
        id: string,
        title: string,
        schemaContent: any,
        statusListId: string,
        status_fields: any
    }>({
        mutationFn: ({id, title, schemaContent, statusListId}) => updateTemplate(id, title, schemaContent, statusListId!, roles, allInputsBySectionWithKey),
        onSettled: () => {
            setError([]);
            setIsModalOpen(false);
            navigate("/work_orders");
        },
        onError: (error: any) => {
            setError(error.message);
        }
    });

    const handleValidateTemplate = () => {
        let isValidated = true;
        const names = new Set();
        const errors = new Set(); // Use a Set to avoid duplicate errors

        if (title.trim() === "") {
            errors.add(t("errors.template_title_required"));
            isValidated = false;
        }

        if (statusListId === undefined) {
            errors.add(t("errors.status_list_required"));
            isValidated = false;
        }

        if (roles.length === 0) {
            errors.add(t("errors.roles_required"));
            isValidated = false;
        }


        const allItems = [...heading, ...materials, ...expenses, ...allInputsBySection];


        for (const item of allItems) {
            if (item.name.trim() === "") {
                errors.add(t("errors.name_required"));
                isValidated = false;
            }
            if (hasWhiteSpace(item.name)) {
                errors.add(t("errors.name_word"));
                isValidated = false;
            }
            if (item.label.trim() === "") {
                errors.add(t("errors.label_required"));
                isValidated = false;
            }
            if (item.inputType === "select" && item.options.length === 0) {
                errors.add(t("errors.select_required"));
                isValidated = false;
            }
            if (item.inputType === "productList" && item.type === "") {
                errors.add(t("errors.product_required"));
                isValidated = false;
            }
            if (names.has(item.name)) {
                errors.add(t("errors.name_unique"));
                isValidated = false;
            }
            names.add(item.name);
        }

        if (errors.size > 0) {
            //@ts-ignore
            setError(Array.from(errors)); // Convert Set to array before setting it as the error state
        }

        return isValidated;
    };


    const handleOk = () => {
        if (handleValidateTemplate()) {
            setIsModalOpen(false);
            if (params.id) {
                updateMutation.mutate({
                    id: params.id ? params.id : "",
                    title: title,
                    schemaContent: template,
                    statusListId: statusListId!,
                    status_fields: allInputsBySectionWithKey
                });
            } else {
                mutation.mutate({
                    title: title,
                    schemaContent: template,
                    statusListId: statusListId!,
                    status_fields: allInputsBySectionWithKey
                });
            }
        } else {
            setIsModalOpen(true);
        }
    };

    function hasWhiteSpace(s: string) {
        return (/\s/).test(s);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setError([]);
    };


    return (

        <Modal
            width={window.innerWidth <= 768 ? "90vw" : "50vw"}
            title={t("preview_modal.preview_template")}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={<Footer currentStep={currentStep} setCurrentStep={setCurrentStep} handleOk={handleOk}/>}
        >
            <h3>{title ? title : "No title"} </h3>
            <Steps
                size="small"
                current={currentStep}
                className={classes.steps}
                items={[
                    {title: <>{t("section.heading")}</>},
                    {title: <>{t("section.materials")}</>},
                    {title: <>{t("section.expenses")}</>},
                    {title: <>{t("section.extra")}</>}
                ]}
            />
            <div className={classes.previewContainer}>
              <SectionSteps currentStep={currentStep}/>
            </div>
            {error && error.map(e => <Alert message={e} type={"error"} className={classes.alert}/>)}
        </Modal>
    );
};

export default PreviewModal;


