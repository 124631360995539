import React from 'react';
import { Select, Spin } from "antd";
import { useQuery } from "@tanstack/react-query";
import { FormProductList } from "../../../../types/inputs";
import { getProductList } from "../../../../services/products";

interface ProductListCreatorProps {
    productList: FormProductList;
    onInputChange: (id: string, field: string, value: string) => void;
    value?: string;
}

const ProductListCreator: React.FC<ProductListCreatorProps> = ({ productList, onInputChange = () => {}, value }) => {

    const onChange = (selectedValue: string) => {
        onInputChange(productList.id, 'type', selectedValue);
    };

    const { data: allProducts, isLoading } = useQuery({
        queryFn: getProductList,
        queryKey: ["productList"]
    });

    const options = allProducts?.map((p: any) => ({ value: p.id, label: p.name })) || [];

    return (
        <div>
            {isLoading ? (
                <Spin />
            ) : (
                <Select
                    defaultValue={value} // Use controlled value instead of defaultValue
                    style={{ minWidth: "200px", width: "20%" }}
                    onChange={onChange}
                    placeholder="Select product type"
                    options={options}
                    loading={isLoading} // Show loading inside Select if data is fetching
                />
            )}
        </div>
    );
};

export default ProductListCreator;
