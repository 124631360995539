import React, {useState} from 'react';
import logo from "../../assets/logo.png";
import {Button, Menu, MenuProps} from "antd";
import {useAuth} from "../../context/AuthProvider";
import {NavLink, useNavigate} from "react-router-dom";
import classes from "./style.module.css"
import {
    CopyOutlined,
    FormOutlined,
    HighlightOutlined,
    HomeOutlined,
    InfoCircleOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined,
} from "@ant-design/icons";


const Header = () => {

    const {dispatch, state} = useAuth()
    const navigate = useNavigate();

    const userData = state.userData

    type MenuItem = Required<MenuProps>['items'][number];

    const items: MenuItem[] = [
        {
            key: '1', icon: <HomeOutlined/>, label: 'Home', onClick: () => {
                navigate('/dashboard')
                setCollapsed(false)
            }
        },
        {
            key: '2', icon: <HighlightOutlined/>, label: 'Tasks', onClick: () => {
                navigate('/tasks')
                setCollapsed(false)
            }
        },
        {key: '3', icon: <CopyOutlined/>, label: 'Wiki',onClick: () => {
                navigate('/wiki')
                setCollapsed(false)
            }},
        {key: '4', icon: <FormOutlined/>, label: 'Work orders',onClick: () => {
                navigate('/work_orders')
                setCollapsed(false)
            }},
        {key: '5', icon: <InfoCircleOutlined/>, label: 'Info', onClick: () => {
                navigate('/info')
                setCollapsed(false)
            }},
        {key: '6', icon: <LogoutOutlined/>, label: 'Logout',onClick: () => {
                handleLogout()
                setCollapsed(false)
            }},
    ];

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleLogout = async () => {
        localStorage.removeItem("token")
        dispatch({type: 'SET_TOKEN_ACTION', payload: undefined})
        dispatch({type: 'SET_USER_DATA', payload: undefined})
        navigate("/login", {replace: true})
    }

    if (window.innerWidth <= 450) {
        return <div className={classes.mobileHeader}>
            <img alt="logo" src={logo} className={classes.logo} onClick={() => navigate("/dashboard")}/>
            <div style={{display: "flex", justifyContent: "flex-end", width: "auto"}}>
                <Button type="primary" onClick={toggleCollapsed}>
                    {collapsed ? <MenuFoldOutlined/> : <MenuUnfoldOutlined/>}
                </Button>
                <div className={classes.mobileMenu}>
                    {collapsed ? <Menu
                        mode="inline"
                        theme="light"
                        items={items}
                    /> : null}
                </div>

            </div>
        </div>
    }

    return (
        <div className={classes.header}>
            <div className={classes.navigation}>
                <img alt="logo" src={logo} className={classes.logo} onClick={() => navigate("/dashboard")}/>
                <NavLink className={({isActive, isPending}) =>
                    isPending ? classes.link : isActive ? classes.activeLink : classes.link
                } to={"/dashboard"}><HomeOutlined className={classes.icon}/>Home</NavLink>/
                <NavLink className={({isActive, isPending}) =>
                    isPending ? "pending" : isActive ? classes.activeLink : classes.link
                } to={"/tasks"}><HighlightOutlined className={classes.icon}/>Tasks</NavLink>/
                <NavLink className={({isActive, isPending}) =>
                    isPending ? "pending" : isActive ? classes.activeLink : classes.link
                } to={"/wiki"}><CopyOutlined className={classes.icon}/>Wiki</NavLink>/
                <NavLink className={({isActive, isPending}) =>
                    isPending ? "pending" : isActive ? classes.activeLink : classes.link
                } to={"/work_orders"}><FormOutlined className={classes.icon}/>Work orders</NavLink>
            </div>
            <div className={classes.buttonNavigation}>
                {userData?.adminLevel ? <NavLink className={({isActive, isPending}) =>
                    isPending ? "pending" : isActive ? classes.activeLink : classes.link
                } to={"/info"}><InfoCircleOutlined className={classes.icon}/>Info</NavLink> : <></>}
                <Button onClick={handleLogout}>Logout</Button>
            </div>
        </div>
    );
};

export default Header;