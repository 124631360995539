import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    CheckboxOptionType, Divider,
    Input,
    Radio,
    RadioChangeEvent,
    RadioGroupProps
} from "antd";
import classes from "./style.module.css"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getProductAddons} from "../../../../services/products";
import {Addon, Product} from "../../../../types/types";
import {FormProduct} from "../../../../types/inputs";
import {v4 as uuidv4} from "uuid";
import TextArea from "antd/es/input/TextArea";
import ProductAddonList from "./productAddons/ProductAddonList";
import {deleteProductsInstalled} from "../../../../services/workOrders";
import ChooseProductInput from "../../../../components/inputs/ChooseProductInput";
import useUtils from "../../../../utils/globalFunctions";
import {useTranslation} from "react-i18next";
import BarcodeScanner from "../../modals/BarcodeScanner";
import {BarcodeOutlined} from "@ant-design/icons";

interface ProductInputItemProps extends RadioGroupProps {
    productInput: FormProduct
    options: (string | number | CheckboxOptionType)[]
    handleUpdateInput: (id: string, field: string, value: any) => void
}

const ProductInputChooser: React.FC<ProductInputItemProps> = (props) => {

    const {
        productInput,
        options,
        handleUpdateInput,
        id,
        ...radioGroupProps
    } = props

    const {t} = useTranslation()
    const isMobile = window.innerWidth <= 768
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOnBarcode = (barcode: string) =>{
        handleUpdateInput(productInput.itemNumber, "serialNumber", barcode)
    }

    if (productInput.serialNumber != null) {

        return (
            <>
                <div className={classes.clipboardContainer}>
                    <Input
                        style={{minWidth: "200px"}}
                        type={"text"}
                        value={productInput.serialNumber}
                        onChange={(e) => handleUpdateInput(productInput.itemNumber, "serialNumber", e.target.value)}
                        placeholder={t("work_order_details.serial_number")}
                    />
                    {isMobile && <Button onClick={() => setIsModalOpen(true)}><BarcodeOutlined/></Button>}
                    <BarcodeScanner isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleBarcode={handleOnBarcode}/>
                </div>
                <Radio.Group
                    options={options}
                    {...radioGroupProps}
                    value={productInput.quantity.toString() ?? "1"}
                    optionType="button"
                    buttonStyle="solid"
                    className={classes.radioRow}
                />
            </>
        );
    }

    return (
        <Input
            style={{minWidth: "200px"}}
            defaultValue={productInput.quantity}
            onChange={(e) => handleUpdateInput(productInput.itemNumber, "quantity", e.target.value)}
            type={"number"}
            placeholder={t("work_order_details.quantity")}
            suffix={productInput.units}
            required
        />
    );
}

interface IProductItemProps {
    initProductAddons: FormProduct[];
    productInput: FormProduct
    handleRemoveProductInput: (product: FormProduct) => void
    setProductInputs: (productInputs: (prev: FormProduct[]) => FormProduct[]) => void
    workOrderId: string;
}

const ProductInputItem: React.FC<IProductItemProps> = (props) => {

    const {
        handleRemoveProductInput,
        productInput,
        initProductAddons,
        setProductInputs,
        workOrderId
    } = props

    const [value, setValue] = useState(productInput.quantity ? productInput.quantity.toString() : "1");
    const [productInputAddons, setProductInputAddons] = useState<FormProduct[]>(initProductAddons);
    const [dropdownAddonsList, setDropdownAddonsList] = useState<{
        value: string; key: string, label: React.ReactNode, product: Addon | Product
    }[]>([]);
    const [error, setError] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
    const queryClient = useQueryClient();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {data: productAddons, isLoading} = useQuery({
        queryFn: () => getProductAddons(productInput.productId),
        queryKey: ["productAddons", productInput.productId],

        //@ts-ignore
        onSuccess: (data: any) => {
            const newProductAddons = getDropdownItems(data)
            setDropdownAddonsList(newProductAddons)
        }
    });

    const deleteMutation = useMutation<any, Error, { workOrderId: string, itemNumber: string }>({
        mutationFn: ({workOrderId, itemNumber}) => deleteProductsInstalled(workOrderId, itemNumber),
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['productsInstalled']}).catch(console.error);
            setError("");
        },
        onError: (error) => {
            setError(error.message);
        }
    });


    const handleUpdateInput = (id: string, field: string, value: string | number) => {

        setProductInputs((prevInputs) =>
            prevInputs.map((input: FormProduct) =>
                input.itemNumber === id ? {...input, [field]: value} : input
            )
        );

        setProductInputAddons((prevInputs) =>
            prevInputs.map((input: FormProduct) =>
                input.itemNumber === id ? {...input, [field]: value} : input
            ))

    };


    const onChange = ({target: {value}}: RadioChangeEvent) => {
        setValue(value);
        handleUpdateInput(productInput.itemNumber, "quantity", value)
    };


    const handleSelectProductAddon = (product: Product) => {

        const itemNumber = uuidv4()

        const newInput: FormProduct = {
            productId: product.id,
            units: product.units,
            ...(product.serialNumber ? {serialNumber: ''} : {}),
            quantity: product.serialNumber ? "1" : '1',
            itemNumber,
            parentItemNumber: productInput.itemNumber,
            productName: product.name,
            description: ""
        };

        setProductInputs(prev => [...prev, {...newInput}]);

        setProductInputAddons(prev => {
            if (prev) {
                return [...prev, {...newInput}]
            } else {
                return [{...newInput}]
            }
        })
    };

    const getDropdownItems = (addons: Omit<Addon, "itemNumber">[] | undefined) => {
        return (Array.isArray(addons) ? addons : [])
            ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort by 'name' property alphabetically
            .map((addon: Omit<Addon, "itemNumber">, index) => ({
                key: String(index + 1),
                value: addon.name,
                product: addon,
                label: (
                    <div
                        className={isMobile ? classes.mobileDropdownItem : classes.dropdownItem}
                        onClick={isMobile ? () => handleSelectProductAddon(addon) : undefined}  // Only bind the function for mobile
                    >
                        {addon.name}
                        {isMobile ? <Divider/> : <></>}
                    </div>
    ),
    }))
        ;
    };

    useEffect(() => {
        if (productAddons) {
            setDropdownAddonsList(getDropdownItems(productAddons))
        }
    }, [productAddons]);

    const dropdownItems = isLoading ? [] : dropdownAddonsList;

    const handleRemoveAddon = (addon: FormProduct) => {

        setProductInputs(prev => prev.filter(i => i.itemNumber !== addon.itemNumber))
        setProductInputAddons((productInputAddons || []).filter(i => i.itemNumber !== addon.itemNumber))

        deleteMutation.mutate({
            workOrderId: workOrderId,
            itemNumber: addon.itemNumber
        })
    }

    const {optionsWithDisabled} = useUtils()
    const {t} = useTranslation()

    return (
        <div>
            <Card className={classes.cardBorder}>
                <h3 style={{width: "200px"}}>{productInput.productName}</h3>
                <div className={classes.container}>
                    <ProductInputChooser handleUpdateInput={handleUpdateInput}
                                         productInput={productInput} value={value}
                                         options={optionsWithDisabled} onChange={onChange}/>
                    <Button onClick={handleRemoveProductInput.bind(this, productInput)} danger>{t("work_order_details.remove")}</Button>
                </div>
                <br/>
                <div className={classes.container}>
                    <ChooseProductInput placeholder={t("work_order_details.addon_placeholder")} productInput={productInput} dropdownItems={dropdownItems} setProductInputs={setProductInputs} setProductInputAddons={setProductInputAddons} isAddon={true}/>
                </div>
                <br/>
                <div className={classes.container}>
                        <TextArea
                            defaultValue={productInput.description}
                            onChange={(e: any) => handleUpdateInput(productInput.itemNumber, "description", e.target.value)}
                            placeholder={t("work_order_details.description")} autoSize={true}/>
                    </div>
                    <ProductAddonList addons={productInputAddons} handleUpdateInput={handleUpdateInput}
                                      handleRemoveAddon={handleRemoveAddon}/>
                    <br/>
                    {error && <Alert message={error} type={"error"} className={classes.errorText}/>}
            </Card>
        </div>
    );
};

export default ProductInputItem;

