import {
    FormDate,
    FormInput,
    FormLocation,
    FormProductList,
    FormSelect,
    FormTextarea,
    InputTypesEnum
} from "../../../types/inputs";
import {v4 as uuidv4} from "uuid";
import {SectionEnum} from "../../../types/types";
import {SectionInputStateType} from "./SectionInputReducer";
import {CustomSectionInputStateType} from "./customSectionInputReducer";

export const createInput = (type: InputTypesEnum) => {
    const base = {
        id: uuidv4(),
        name: "",
        label: "",
        isRequired: false,
        roles: []
    }
    if (type === InputTypesEnum.Input) {
        return {...base, inputType: InputTypesEnum.Input, type: "text"} as unknown as FormInput
    }
    if (type === InputTypesEnum.Select) {
        return {...base, inputType: InputTypesEnum.Select, options: []} as unknown as FormSelect
    }
    if (type === InputTypesEnum.Date) {
        return {...base, inputType: InputTypesEnum.Date} as unknown as FormDate
    }
    if (type === InputTypesEnum.ProductList) {
        return {...base, inputType: InputTypesEnum.ProductList, products: [], type: "", name:"productList", label:"Please choose a product type",  } as unknown as FormProductList
    }
    if (type === InputTypesEnum.Location) {
        return {...base, inputType: InputTypesEnum.Location, lat: 0, long: 0} as unknown as FormLocation
    }

    return {...base, inputType: InputTypesEnum.Textarea} as unknown as FormTextarea

}

export const getInputFromTemplate = ( data: any) => {
    const type = data.inputType

    const base = {
        id: data.id,
        name: data.name,
        label: data.label,
        isRequired: data.isRequired,
        roles: data.roles
    }
    if (type === InputTypesEnum.Input) {
        return {...base, inputType: InputTypesEnum.Input, type: "text", ...data} as unknown as FormInput
    }
    if (type === InputTypesEnum.Select) {
        return {...base, inputType: InputTypesEnum.Select, options: data.options,...data} as unknown as FormSelect
    }
    if (type === InputTypesEnum.Date) {
        return {...base, inputType: InputTypesEnum.Date} as unknown as FormDate
    }
    if (type === InputTypesEnum.ProductList) {
        return {...base, inputType: InputTypesEnum.ProductList, products: data.products, type: data.products,...data} as unknown as FormProductList
    }
    if (type === InputTypesEnum.Location) {
        return {...base, inputType: InputTypesEnum.Location, lat: data.lat, long: data.long,...data} as unknown as FormLocation
    }

    return {...base, inputType: InputTypesEnum.Textarea} as unknown as FormTextarea

}



export const transformTemplateToWorkOrder = (template: any): SectionInputStateType => {
    return {
        [SectionEnum.Heading]: {
            data: template[SectionEnum.Heading].map((el: any) => ({
                type: el.inputType,
                data: getInputFromTemplate(el),
                id: uuidv4()
            }))
        },
        [SectionEnum.Materials]: {
            data: template[SectionEnum.Materials].map((el: any) => ({
                type: el.inputType,
                data: getInputFromTemplate(el),
                id: uuidv4()
            }))
        },
        [SectionEnum.Expenses]: {
            data: template[SectionEnum.Expenses].map((el: any) => ({
                type: el.inputType,
                data: getInputFromTemplate(el),
                id: uuidv4()
            }))
        },
    }

}


export const transformTemplateStatusesToWorkOrder = (template: any): CustomSectionInputStateType => {
    return Object.keys(template).reduce((acc, sectionId) => {
        const sectionData = template[sectionId] || []; // Ensure sectionData is always an array
        acc[sectionId] = {
            data: sectionData
                .flat() // Flatten the nested arrays
                .filter((el: any) => el && typeof el === "object") // Ensure only valid objects are processed
                .map((el: any) => ({
                    type: el.inputType,
                    data: getInputFromTemplate(el),
                    id: uuidv4()
                }))
        };
        return acc;
    }, {} as CustomSectionInputStateType);
};

