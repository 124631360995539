import React, {useState} from 'react';
import {Select, Space, Switch, Tag} from "antd";
import {useQuery} from "@tanstack/react-query";
import {getRoles} from "../../../services/roles";
import {Role} from "../../../types/types";
import classes from "../styles.module.css"
import {useTranslation} from "react-i18next";

interface RoleChooserProps {
    roles: Role[]
    setRoles: (roles: Role[]) => void
}

const RoleChooser: React.FC<RoleChooserProps> = ({roles, setRoles}) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const {t} = useTranslation()

    const {data: rolesList} = useQuery({
        queryFn: () => getRoles(),
        queryKey: ["rolesList"]
    })

    const onChange = (value: string) => {

        const roleValue = rolesList?.find(r => r.roleId === value)

        if (roleValue && !roles.map(r => r.roleId).includes(roleValue?.roleId)) {
            setRoles([...roles, {...roleValue, limited: false}])
        }

        setSelectedValue(null); // Reset selection
    };


    const options = Array.isArray(rolesList)
        ? rolesList.map((r: any) => ({value: r.roleId, label: r.roleName}))
        : [];


    const handleRemoveRole = (role: Role, e: any) => {
        e.preventDefault()
        setRoles(roles.filter(r => r.roleId !== role.roleId))
    }

    const onSwitchChange = (checked: boolean, roleId?: string) => {
        setRoles(roles.map(role =>
            role.roleId === roleId ? {...role, limited: checked} : role
        ));
    };

    const filteredOptions = options.filter(option =>
        !roles.some(role => role.roleId === option.value))

    return (
        <>
            <Select
                value={selectedValue}
                style={{width: "20%", minWidth: "10%"}}
                onChange={onChange}
                placeholder={t("template_page.select_roles")}
                options={filteredOptions}
            />
            <div style={{margin: "16px 0"}}/>
            <Space size={[8, 16]} wrap>
                {roles.map(role => (
                    <RoleItem key={role.roleId} role={role} handleRemoveRole={handleRemoveRole}
                              onSwitchChange={onSwitchChange}/>
                ))}
            </Space>
        </>
    );
};

export default RoleChooser;

interface RoleItemProps {
    role: Role,
    handleRemoveRole: (role: Role, e: any) => void,
    onSwitchChange: (checked: boolean, roleId?: string) => void
}

const RoleItem: React.FC<RoleItemProps> = ({role, handleRemoveRole, onSwitchChange}) => {
    const handleCheckboxChange = (checked: boolean) => {
        onSwitchChange(checked, role.roleId);
    };

    return (
        <Tag
            closable
            onClose={e => handleRemoveRole(role, e)}
            className={classes.tag}
        >
            {role.roleName} &nbsp;
            <Switch
                checked={role.limited}
                onChange={handleCheckboxChange}
                checkedChildren="Limited"
                unCheckedChildren="Unlimited"
                size="small"
            />
        </Tag>
    );
};