// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_tableContainer__WufSk {
    width: 80%;
    margin: 10px auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/info/style.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;AACrB","sourcesContent":[".tableContainer {\n    width: 80%;\n    margin: 10px auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `style_tableContainer__WufSk`
};
export default ___CSS_LOADER_EXPORT___;
