import React from 'react';
import {Control, Controller, FieldErrors} from "react-hook-form";
import {Alert, Select, SelectProps} from "antd";
import {isEmpty} from "lodash";
import classes from "./style.module.css";
import {taskTypes} from "../../types/types";
import useUtils from "../../utils/globalFunctions";



interface ICustomFormSelectProps {
    control: Control | any;
    label: string;
    name: string
    errors: FieldErrors | any
    type: taskTypes
    selectProps?: SelectProps
    labelClassName? : string
    className?: string;
}


const CustomFormSelect: React.FC<ICustomFormSelectProps> = ({control, labelClassName, className, selectProps, label, name, errors, type}) => {
    const {getTypeData} = useUtils()


    return (
        <>
            <label className={labelClassName? labelClassName: ""}>{label}: </label>
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <Select
                        {...selectProps}
                        {...field}
                        options={getTypeData(type)}
                        className={className? className: ""}
                    />
                )}
            />
            {!isEmpty(errors) && <Alert className={classes.selectAlert} type={"error"} message={errors?.message}/>}
        </>
    );
};

export default CustomFormSelect;