import React from 'react';
import classes from "./style.module.css"
import {useAuth} from "../../context/AuthProvider";
import {useTranslation} from "react-i18next";


export default function DashboardPage() {

    const {state} = useAuth()
    const userData = state.userData
    const {t} = useTranslation()

    return (
            <div className={classes.dashboard}>
                <h2>{t("dashboard.welcome", {name: userData?.person.name})}</h2>
            </div>
    )
}

