// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formContainer__7v6-v {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.style_modalFooter__w0Pz6 {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 10px;
}

.style_alert__OmX4y {
    color: red;
    margin-top: 10px;
}

.style_fieldAlert__hNRYf {
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/tasks/TaskDetailsPage/components/modals/EditTaskModal/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,SAAS;IACT,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".formContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.modalFooter {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    gap: 10px;\n    justify-content: flex-end;\n    margin-top: 10px;\n}\n\n.alert {\n    color: red;\n    margin-top: 10px;\n}\n\n.fieldAlert {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `style_formContainer__7v6-v`,
	"modalFooter": `style_modalFooter__w0Pz6`,
	"alert": `style_alert__OmX4y`,
	"fieldAlert": `style_fieldAlert__hNRYf`
};
export default ___CSS_LOADER_EXPORT___;
