import React from 'react';
import {Button, Modal, Tag} from "antd";
import {WarningOutlined} from "@ant-design/icons";
import classes from "./style.module.css"


interface IWarningModalProps {
    title: string;
    handler: () => void
    isModalOpen: boolean;
    setIsModalOpen: (open: boolean) => void
    data: any
    deleteText: string
}

interface ITitleProps {
    title: string
}

interface IFooterProps {
    handleCancel: () => void
    handler: () => void
    deleteText: string
}

const ModalTitle: React.FC<ITitleProps> = ({title}) => {
    return <div className={classes.titleContainer}><WarningOutlined className={classes.icon}/>{title}</div>
}

const ModalFooter: React.FC<IFooterProps> = ({handler, handleCancel,deleteText}) => {
    return <>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" danger onClick={handler}>{deleteText}</Button>
    </>
}

const WarningModal: React.FC<IWarningModalProps> = ({title, isModalOpen, setIsModalOpen, deleteText, handler, data}) => {

    const handleCancel = () => {
        setIsModalOpen(false)
    }


    return (
        <Modal maskClosable={false} footer={<ModalFooter deleteText={deleteText} handler={handler} handleCancel={handleCancel}/>}
               title={<ModalTitle title={title}/>}
               open={isModalOpen} onOk={handler} onCancel={handleCancel}>
            <Tag>{data}</Tag>
        </Modal>
    );
};

export default WarningModal;