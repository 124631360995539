import React, {useEffect, useState} from 'react';
import {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {Alert, Button, Card, Collapse} from "antd";
import classes from "../styles.module.css";
import {formatDate} from "../../../utils/globalFunctions";
import {useNavigate, useParams} from "react-router-dom";
import DetailsSection from "./DetailsSection";
import TextArea from "antd/es/input/TextArea";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {updateWorkOrderNotes} from "../../../services/workOrders";


interface IDetailsCardProps {
    item: any
}

const DetailsCard: React.FC<IDetailsCardProps> = ({item}) => {

    const navigate = useNavigate();
    const params = useParams();
    const queryClient = useQueryClient()
    const [error, setError] = useState("");
    const [notes, setNotes] = useState(item.notes ? item.notes : "");


    useEffect(() => {
        setNotes(item.notes ? item.notes : "")
    }, [item]);


    const mutation = useMutation<any, any, any>({
        mutationFn: () => updateWorkOrderNotes(item.id, notes),
        onSettled: () => {
            //@ts-ignore
            queryClient.invalidateQueries(['workOrderDetails', item.id]).catch(console.error);
            setError("");
        },
        onError: (error) => {
            setError(error.message);
        }
    });

    const handleSaveNotes = () => {
        mutation.mutate({
            id: item.id,
            notes: notes
        });
    }

    return (
        <Collapse bordered={false} className={classes.historyCollapse} defaultActiveKey={"1"} accordion>
            <Collapse.Panel header={<h2>Work order details <InfoCircleOutlined/></h2>} key="1">
                <Card
                    className={classes.titleCard}
                    title={
                        <h2>
                            {item.title}
                        </h2>
                    }
                    extra={
                        <Button
                            className={classes.editButton}
                            type="primary"
                            onClick={() => navigate(`/work_orders/${params.id}/edit`)}
                            icon={<EditOutlined/>}
                        >
                            Edit
                        </Button>
                    }
                >
                    <h2>Notes:</h2>
                    <div className={classes.notesContainer}>
                        <TextArea onChange={(e) => setNotes(e.target.value)} autoSize={true} value={notes}/>
                        <Button type={"primary"} onClick={handleSaveNotes}>Save notes</Button>
                    </div>
                    <br/>
                    {error && <Alert type="error" message={error} className={classes.errorText}/>}
                    <div className={classes.workOrderContainer}>
                        <div className={classes.formContainer}>
                            {Object.entries(item?.order_template?.schemaContent || {}).map(([key, value]) => (
                                <DetailsSection key={key} data={value}
                                                section={key.charAt(0).toUpperCase() + key.slice(1)}/>
                            ))}
                        </div>
                    </div>
                    <div className={classes.date}>Created at: {formatDate(item?.createdDate)}</div>
                    <div className={classes.date}>Last updated at: {formatDate(item?.updatedDate)}</div>
                </Card>
            </Collapse.Panel>
        </Collapse>
    );
};

export default DetailsCard;